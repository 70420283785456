import { Button, styled, Theme } from '@material-ui/core';

export const Container = styled('div')(({ theme }) => ({
  height: '70vh',
  display: ' flex',
  flexDirection: 'column',
  justifyContent: 'center',
  color: theme.colors.black,
  paddingLeft: '5rem',
  paddingRight: '5rem',
  '& .react-pdf__Document': {
    alignItems: 'center',
  },
  '& .react-pdf__Page': {
    width: '100%',
    overflowY: 'scroll',
    height: 715,
  },
  '& canvas': {
    height: '100%',
    margin: '0 auto',
  },
}));

export const Controls = styled('div')({
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  marginTop: '2rem',
  '& button:disabled': {
    color: '#dedede',
  },
});

export const ButtonBorder = styled(Button)(({ theme }) => ({
  width: 250,
  height: '3.5rem',
  border: `1px solid ${theme.colors.primary}`,
  borderRadius: 35,
  fontSize: '1rem',
  fontWeight: 600,
  color: theme.colors.primary,
  padding: '0px 2rem',
  cursor: 'pointer',
  textTransform: 'uppercase',
  outline: 0,
}));

export const WrapperCheckbox = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '2rem',
  color: theme.palette.text.primary,
}));

type TInputCheckProp = {
  theme: Theme;
  selected: boolean;
};

export const InputCheck = styled('div')(
  ({ theme, selected }: TInputCheckProp) => ({
    height: 30,
    width: 30,
    background: selected ? theme.colors.primary : 'transparent',
    border: `2px solid ${theme.colors.primary}`,
    borderRadius: '50%',
    marginRight: 10,
    transition: '',
  }),
);

export const ModalWrapper = styled('div')({
  height: 700,
  width: 900,
  overflowY: 'scroll',
});
