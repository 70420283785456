import { makeHTTPProvider } from 'shared/infra/providers';

type TActionName =
  | 'agendamentoIniciou'
  | 'agendamentoConseguiuAgendar'
  | 'agendamentoOptouPorDataSugerida'
  | 'agendamentoErroAoRegistrar'
  | 'cliqueBotaoCompareCompreTotem';

export default class Tracker {
  public static async trackAction(
    idClient: number,
    actionName: TActionName,
  ): Promise<void> {
    if (process.env.NODE_ENV === 'development') {
      console.log(' ----- TRACKER -----');
      console.log(` - CLIENT ID: ${idClient}`);
      console.log(` - ACTION NAME: ${actionName}`);
      console.log(' -------------------');
    } else {
      try {
        await makeHTTPProvider().post('microeventoscliente/registrar', {
          idCliente: idClient,
          microEvento: actionName,
          canal: 'TOTEM',
        });
      } catch (err) {
        console.error('Could not register micro-event!');
      }
    }
  }
}
