import styled, { css } from 'styled-components';

export const Container = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${theme.layout.rowGap(4)}
    padding: ${theme.layout.spacing(8)};

    border-radius: 44px;

    background: ${theme.palette.neutral[50]};

    color: ${theme.palette.text.main};

    > svg {
      color: ${theme.palette.error.main};
      font-size: 124px;
    }

    > div {
      display: flex;
      flex-direction: column;
      text-align: center;
      ${theme.layout.rowGap(2)}

      width: ${theme.layout.spacing(40)};
      font-size: ${theme.typography.sizes.body1};
      font-weight: ${theme.typography.weight.regular};

      h2 {
        color: ${theme.palette.error.main};
        font-size: ${theme.typography.sizes.title2};
        font-weight: ${theme.typography.weight.bold};
      }
    }

    button {
      width: 100%;
    }
  `}
`;
