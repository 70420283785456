export const lightPalette = {
  primary: {
    light: '#5EE46B',
    main: '#30D841',
    dark: '#28AF35',
  },
  secondary: {
    light: '#9374D2',
    main: '#7B51D2',
    dark: '#5F39AC',
  },
  warning: {
    light: '#F0D670',
    main: '#FAD541',
    dark: '#EDC112',
  },
  error: {
    light: '#DD5555',
    main: '#E43232',
    dark: '#C42121',
  },
  neutral: {
    0: '#FFFFFF',
    50: '#F8FBFC',
    100: '#F1F8F9',
    200: '#E2EDF0',
    300: '#CBDCE1',
    400: '#94B0B8',
    500: '#647E88',
    600: '#476169',
    700: '#334D55',
    800: '#1E3238',
    900: '#0F212A',
  },
  text: {
    main: '#181E21',
    light: '#454D4F',
    dark: '#181E21',
    white: '#F9FBFB',
  },
};
