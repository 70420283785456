import { styled } from '@material-ui/core/styles';

export const ContainerCard = styled('div')(() => ({
  width: '94%',
}));

export const ContainerButtons = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  paddingTop: '50px',
}));

export const NoAreasText = styled('h1')(({ theme }) => ({
  fontSize: theme.font.sizes.medium,
  color: theme.colors.primary,
}));
