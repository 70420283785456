import { useEffect } from 'react';

import MomentFnUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment, { Moment as MomentType } from 'moment';

import { useLocale } from 'shared/presentation/contexts';

import ScheduleDay from './ScheduleDay';
import { Container } from './styles';
import { useScheduling } from '../../../context/SchedulingContext';
import Logger from '../../../utils/Logger';

const ScheduleCalendar: React.FC = () => {
  const {
    minDate,
    selectedDate,
    selectedDayPeriods,
    setSelectedDate,
    hourAvailabilityPerDay,
    setSelectedMonth,
  } = useScheduling();

  const { language } = useLocale();

  useEffect(() => {
    setSelectedMonth(moment());
  }, [setSelectedMonth]);

  const handleMonthChanged = (x: MaterialUiPickersDate) => {
    if (!x) {
      return;
    }
    setSelectedMonth(x);
  };
  const handleDateChanged = (x: MaterialUiPickersDate) => {
    if (!x) {
      return;
    }

    const atStartOfDay = x?.startOf('day');

    if (atStartOfDay.isSame(selectedDate)) {
      return;
    }

    setSelectedDate(atStartOfDay);
  };

  const shouldDisableDate = (day: MaterialUiPickersDate) => {
    const momentDay = day as MomentType;
    const availability =
      (hourAvailabilityPerDay[momentDay.format('YYYY-MM-DD')] &&
        hourAvailabilityPerDay[momentDay.format('YYYY-MM-DD')].total) ||
      0;

    return availability === 0;
  };

  const dayRenderer = (
    day: MaterialUiPickersDate,
    ignored: MaterialUiPickersDate,
    isInCurrentMonth: boolean,
    dayComponent: JSX.Element,
  ) => {
    const momentDay = day as MomentType;

    const availability = hourAvailabilityPerDay[
      momentDay.format('YYYY-MM-DD')
    ] || { total: 0, byPeriods: {} };

    const { selected, disabled, hidden } = dayComponent.props;

    return !isInCurrentMonth ? (
      <></>
    ) : (
      <ScheduleDay
        selectedDayPeriods={selectedDayPeriods}
        day={day}
        selected={selected}
        disabled={disabled || hidden || availability.total === 0}
        availableTime={availability}
      />
    );
  };

  return (
    <Container>
      <MuiPickersUtilsProvider utils={MomentFnUtils} locale={language}>
        <DatePicker
          autoOk
          disableToolbar
          fullWidth
          variant="static"
          openTo={'date'}
          minDate={minDate}
          shouldDisableDate={shouldDisableDate}
          onMonthChange={handleMonthChanged}
          onChange={handleDateChanged}
          value={selectedDate}
          renderDay={dayRenderer}
          onAnimationStart={evt => {
            Logger.info('AnimationStart', evt);
          }}
          defaultChecked={false}
          defaultValue={undefined}
        />
      </MuiPickersUtilsProvider>
    </Container>
  );
};

export default ScheduleCalendar;
