import { styled } from '@material-ui/core/styles';

export const Areas = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  padding: '12px 0',
  overflowY: 'auto',
  height: 150,
  marginBottom: 12,
});

export const Area = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '8px 0',
  '& img': {
    width: '8rem',
  },
});

export const AreaSession = styled('div')(({ theme }) => ({
  color: theme.colors.gray,
  '& h2': {
    color: theme.colors.pink,
    fontWeight: 700,
    fontSize: 24,
  },
  '& strong': {
    fontSize: 18,
    fontWeight: 700,
  },
}));
