export const components = {
  alert: { close: 'Fechar' },
  documentTypingPad: {
    placeholder: 'Digite seu CPF abaixo',
    confirm: 'Identificar',
    cancel: 'Cancelar',
    validation_error: 'Por favor, informe um CPF válido',
  },
  timer: {
    title: 'Tempo para encerrar a sessão',
  },
  user_identification: {
    title: {
      text: 'Identificação',
    },
    content: {
      explanation:
        'Coloque seu dedo no <1>leitor biométrico</1> <3>abaixo do totem</3> e sua identificação será realizada em alguns instantes.',
      backButtonText: 'Voltar para o início',
    },
    alerts: {
      biometryFailure: {
        title: 'Alerta!',
        text: 'Não foi possível identificá-lo através da biometria. Por favor tente novamente',
      },
    },
    documentButtonText: 'Identificação com CPF',
    cpf_identification_failed:
      'Não foi possível encontrar um usuário com este CPF',
  },
  virtual_keyboard: {
    backspace: 'Apagar',
    clear: 'Limpar',
  },
};
