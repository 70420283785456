import React, { useEffect } from 'react';

import { IconType } from 'react-icons';

import { useButton } from '../../context';
import LoadingSpinner from '../LoadingSpinner';

interface IIconProps {
  icon: IconType;
  children?: never;
}

const Icon: React.FC<IIconProps> = ({ icon: ProvidedIcon }) => {
  const { loading, registerIcon, unregisterIcon } = useButton();

  useEffect(() => {
    registerIcon(ProvidedIcon.name);

    return () => {
      unregisterIcon(ProvidedIcon.name);
    };
  }, [registerIcon, unregisterIcon, ProvidedIcon.name]);

  if (loading) return <LoadingSpinner />;

  return <ProvidedIcon />;
};

export default Icon;
