import { createMuiTheme } from '@material-ui/core';

import { theme as styledTheme } from 'shared/presentation/contexts/ThemeContext/constants/theme';

const palette = styledTheme.palette;

const theme = createMuiTheme({
  font: {
    family: 'Ubuntu',
    light: 300,
    normal: 400,
    bold: 600,
    sizes: {
      xxxxsmall: '0.6rem',
      xxxsmall: '0.8rem',
      xxsmall: '1.0rem',
      xsmall: '1.4rem',
      small: '2rem',
      medium: '2.9167rem',
      large: '3.5rem',
    },
  },
  colors: {
    primary: palette.primary.main,
    darkPrimary: palette.primary.dark,
    secondary: palette.secondary.main,
    greenLight: palette.primary.light,
    lightPurple: palette.secondary.light,
    error: palette.error.main,
    success: palette.primary.main,
    white: palette.neutral[50],
    black: palette.text.dark,
    background: palette.neutral[100],
    lightGray: palette.neutral[300],
    gray: palette.neutral[700],
    red: palette.error.light,
    pink: '#F596AA',
  },
  palette: {
    primary: palette.primary,
    secondary: palette.secondary,
    error: palette.error,
    success: { main: palette.primary.main },
    text: {
      primary: palette.text.main,
      secondary: palette.primary.main,
      hint: palette.text.white,
    },
  },
  typography: {
    h1: {
      fontSize: '2.4375rem',
    },
    h2: {
      fontSize: '2.1875rem',
    },
    h3: {
      fontSize: '2.109375rem',
    },
    h4: {
      fontSize: '1.5625rem',
    },
    h5: {
      fontSize: '1.2125rem',
    },
    body1: {
      fontSize: '1.2rem',
    },
  },
  spacings: {
    xxsmall: '0.8rem',
    xsmall: '1.6rem',
    small: '2.4rem',
    medium: '3.2rem',
    large: '4.0rem',
    xlarge: '4.8rem',
    xxlarge: '5.6rem',
  },
  overrides: {
    MuiPickersCalendar: {
      // width: '100%',
    },
    MuiPickersDatePickerRoot: {
      // width: '100%',
    },
  },
});

export default theme;
