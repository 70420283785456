import { styled } from '@material-ui/core';

import theme from '../../styles/theme';
import { Button as ButtonStyle } from '../button/styles';

type TCardProps = {
  selected: boolean;
};

type TLabelProps = {
  color: string;
};

type TIconProps = {
  backgroundcolor: string;
};

export const Card = styled(ButtonStyle)(({ selected }: TCardProps) => ({
  width: '14rem',
  height: '11rem',
  display: 'grid',
  gridTemplateColumns: '100%',
  gridTemplateRows: '3rem 3rem 3rem',
  alignItems: 'center',
  padding: '1rem',
  background: selected ? theme.colors.lightPurple : theme.colors.white,
  borderRadius: '2rem',
  marginBottom: '1rem',
  '& button': {
    height: '2.5rem',
  },
  transition: 'all .5s ease',
  transform: selected ? 'scale(1.05)' : '',
}));

export const Label = styled('span')(({ color }: TLabelProps) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color,
}));

export const Icon = styled('div')(
  ({ backgroundcolor, color }: TIconProps & TLabelProps) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 15,
    '& svg': {
      width: '3rem',
      height: '3rem',
      padding: '0.5rem',
      fill: color || theme.colors.white,
      backgroundColor: backgroundcolor,
      borderRadius: '2rem',
    },
  }),
);
