import IWebSocketProvider from 'shared/domain/providers/IWebSocketProvider';
import ITotemInfoPubsub from 'shared/domain/pubsubs/ITotemInfoPubsub';
import { TLocale } from 'shared/domain/valueObjects';

const EVENTS = {
  INFO: 'info',
};

export default class LegacyTotemInfoPubsub implements ITotemInfoPubsub {
  constructor(private webSocketProvider: IWebSocketProvider) {}

  public subscribeForTotemSpecifications: ITotemInfoPubsub['subscribeForTotemSpecifications'] =
    subscription => {
      this.webSocketProvider.addEventListener<{
        idioma?: TLocale;
        language?: TLocale;
        idUnidade: number;
      }>(EVENTS.INFO, payload => {
        subscription({
          language: payload.idioma || payload.language || 'pt',
          unitId: payload.idUnidade,
        });
      });

      return () => {
        this.webSocketProvider.removeEventListener(EVENTS.INFO);
      };
    };
}
