import React, {
  ComponentRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';

import { Modal } from 'shared/presentation/components/atoms';
import { Button } from 'shared/presentation/components/molecules';
import { useLocale } from 'shared/presentation/contexts';
import { useTimeout } from 'shared/presentation/hooks';

import * as S from './styles';

const TIMEOUT = 30;

interface INoBiometryAlertRef {
  open(): void;
}

const NoBiometryAlert: React.ForwardRefRenderFunction<
  INoBiometryAlertRef,
  any
> = (_, ref) => {
  const { t } = useLocale('translations');

  const modalRef = useRef<ComponentRef<typeof Modal>>(null);

  const timer = useTimeout({
    timeout: TIMEOUT,
    onTimeUp: useCallback(() => modalRef.current?.close(), []),
  });

  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        modalRef.current?.open();
        timer.reset();
      },
    }),
    [timer],
  );

  return (
    <Modal ref={modalRef}>
      <S.Container>
        <header>
          <h1>{t('pages.checkin.no_biometry.title')}</h1>
        </header>

        <p>{t('pages.checkin.no_biometry.description')}</p>

        <footer>
          <Button
            type="button"
            onClick={() => {
              modalRef.current?.close();
            }}
            variant="error"
            outline
          >
            {t('pages.checkin.no_biometry.close')}
          </Button>
        </footer>
      </S.Container>
    </Modal>
  );
};

export default forwardRef(NoBiometryAlert);
