import { useEffect } from 'react';

import { ModalProps, useTheme } from '@material-ui/core';

import { useLocale } from 'shared/presentation/contexts';

import { ModalBody, ModalContent, ModalFooter, ModalWrapper } from './styles';
import Button from '../button';

const TIMEOUT = 40 /* seconds */ * 1000;

const Modal = ({ children, open, onClose }: ModalProps) => {
  const theme = useTheme();
  const { t } = useLocale('translations');

  useEffect(() => {
    if (open) {
      const timer = setTimeout(() => {
        if (onClose) onClose({}, 'backdropClick');
      }, TIMEOUT);

      return () => clearTimeout(timer);
    }
  }, [open, onClose]);

  return (
    <ModalWrapper open={open} onClose={onClose}>
      <ModalBody>
        <ModalContent>{children}</ModalContent>
        <ModalFooter>
          <Button
            dataCy="backButton"
            bordercolor={theme.colors.primary}
            onClick={e => onClose && onClose(e, 'backdropClick')}
          >
            {t('components.modal.actions.cancel')}
          </Button>
        </ModalFooter>
      </ModalBody>
    </ModalWrapper>
  );
};

export default Modal;
