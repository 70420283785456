import { useMutation } from 'react-query';

import { makeHTTPProvider } from 'shared/infra/providers';

type TSubmitValues = Record<
  string,
  { selected: boolean; incidences: number[]; id: number; name: string }
>;

export default function usePersistAnswer() {
  const mutation = useMutation(async (params: { areas: TSubmitValues }) => {
    return makeHTTPProvider().post(
      'ocorrencias/informaPeleIntegraCliente',
      buildSubmitBodyFromAreas(params.areas),
    );
  });

  return {
    loading: mutation.isLoading,
    send: mutation.mutateAsync,
    error: mutation.error instanceof Error ? mutation.error.message : undefined,
  };
}

function buildSubmitBodyFromAreas(areas: TSubmitValues) {
  const body = new Map<
    string,
    {
      idOcorrencia: number;
      origem: 'TOTEM';
      respostas: Array<{
        peleIntegra: boolean;
        idProduto: string;
        descricaoProduto: string;
      }>;
    }
  >();

  Object.values(areas).forEach(area => {
    area.incidences.forEach(incidenceId => {
      const key = String(incidenceId);
      const incidence = body.get(key);

      if (!incidence) {
        return body.set(key, {
          idOcorrencia: incidenceId,
          origem: 'TOTEM',
          respostas: [
            {
              peleIntegra: area.selected,
              idProduto: String(area.id),
              descricaoProduto: area.name,
            },
          ],
        });
      }

      incidence.respostas.push({
        peleIntegra: area.selected,
        idProduto: String(area.id),
        descricaoProduto: area.name,
      });

      body.set(key, incidence);
    });
  });

  return [...body.values()];
}
