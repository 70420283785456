import Contrato from 'domain/Contrato';

import { Container } from '@material-ui/core';
import { TWizardStepProps } from 'components/Wizard';

import { useActiveCustomer } from 'shared/presentation/contexts';

import Thanks from '../Thanks';

interface ISignedProps {
  contract?: Contrato;
}

const Signed: React.FC<TWizardStepProps & ISignedProps> = ({ contract }) => {
  const { customer } = useActiveCustomer();

  return (
    <Container>
      <Thanks name={customer?.fullName} contract={contract} />
    </Container>
  );
};

export default Signed;
