import { InputBase } from '@material-ui/core';
import { styled, Theme } from '@material-ui/core/styles';

import { Button as ButtonStyle } from '../button/styles';

export const Container = styled('div')({
  display: 'grid',
  gap: '32px',
  padding: '12px 0 0 0',
});

export const Title = styled('h1')(({ theme }) => ({
  textAlign: 'center',
  fontFamily: 'Ubuntu',
  fontWeight: 'bold',
  color: theme.colors.primary,
}));

export const PadDisplay = styled(InputBase)(({ theme }) => ({
  border: `1px solid ${theme.colors.primary}`,
  color: theme.colors.primary,
  borderRadius: 25,
  padding: '18px 12px',
  fontSize: 24,
  fontWeight: 600,
  '& .MuiInputBase-input': {
    padding: '6px 0 2px',
    textAlign: 'center',
    letterSpacing: '.05rem',
  },
}));

export const PadButtonGrid = styled('div')({
  display: 'grid',
  gap: '12px',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridTemplateRows: 'repeat(3, 1fr)',
});

export const Error = styled('span')(({ theme }) => ({
  color: theme.colors.error,
  textAlign: 'center',
  fontFamily: 'Ubuntu',
  fontWeight: 500,
  fontSize: 20,
}));

type TPadButtonProps = {
  theme: Theme;
  extended?: number;
};

export const PadButton = styled(ButtonStyle)(
  ({ theme, extended }: TPadButtonProps) => ({
    backgroundColor: 'transparent !important',
    border: `1px solid ${theme.colors.primary}`,
    color: theme.colors.primary,
    gridColumn: extended ? '1 / span 2' : 'initial',
    margin: 0,
    fontSize: 18,
  }),
);
