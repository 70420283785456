import { createGlobalStyle, css } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
${({ theme }) => css`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    scrollbar-width: thin;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: block;
      height: 0.5rem;
      width: 0.5rem;
      overflow: auto;
      background-color: ${theme.palette.neutral[200]};

      &-thumb {
        transition: 0.5s;
        background-color: ${theme.palette.neutral[400]};
        &:hover {
          background-color: ${theme.palette.neutral[500]};
        }
      }
    }
  }

  // Remove when MUI/Notistack is gone
  .notistack-index {
    z-index: 8000 !important;
  }

  body,
  html {
    height: 100%;
  }

  #root {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  button {
    cursor: pointer;
  }

  a {
    text-decoration: none;
    color: ${theme.palette.text.light};

    &:active,
    &:hover {
      color: ${theme.palette.primary.main};
    }
  }

  ul {
    list-style: none;
  }

  body,
  input,
  textarea,
  button {
    font-family: ${theme.typography.family};
  }

  :root {
    --secondary-main: ${theme.palette.secondary.main};
    --primary-main: ${theme.palette.primary.main};
    --neutral-50: ${theme.palette.neutral[50]};
    --neutral-100: ${theme.palette.neutral[100]};
    --neutral-700: ${theme.palette.neutral[700]};
    --text-white: ${theme.palette.text.white};
  }
`}
`;
