import styled, { css } from 'styled-components';

import { CSSANIMATIONS } from 'shared/presentation/constants';

export const Container = styled.div`
  ${({ theme }) => css`
    position: relative;
    background: ${theme.palette.neutral[400]};

    padding: ${theme.layout.spacing(1)};

    border-radius: 50%;
    box-shadow: 2px 2px 40px #00000044;

    animation: ${CSSANIMATIONS.HOVER} 1.5s infinite;

    > div {
      position: relative;
      width: ${theme.layout.spacing(9)};
      height: ${theme.layout.spacing(9)};
      background: ${theme.palette.neutral[50]};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      svg {
        font-size: 44px;
        color: ${theme.palette.primary.main};
      }

      z-index: 51;
    }

    &::after {
      position: absolute;
      content: '';
      border: solid ${theme.palette.neutral[400]};
      border-width: 0 30px 30px 0;
      display: inline-block;
      padding: 8px;

      bottom: -9px;
      left: 21px;
      transform: rotate(45deg);

      z-index: 50;
    }
  `}
`;
