import moment from 'moment';

import { useLocale } from 'shared/presentation/contexts';

import * as S from './styles';
import { TScheduleSuggestion } from '../../types/api';

type TScheduleSuggestionModalType = {
  scheduleSuggestions: Array<TScheduleSuggestion>;
  onSelectSchedule(index: number): void;
  onFinish(): void;
  onClose(): void;
};

const ScheduleSuggestionModal: React.FC<TScheduleSuggestionModalType> = ({
  onClose,
  onFinish,
  onSelectSchedule,
  scheduleSuggestions,
}) => {
  const { t, language } = useLocale('translations');

  const monthNames = [
    'JAN',
    'FEV',
    'MAR',
    'ABR',
    'MAI',
    'JUN',
    'JUL',
    'AGO',
    'SET',
    'OUT',
    'NOV',
    'DEZ',
  ];

  const renderDates = (schedule: string) => {
    const month = new Date(schedule);
    const date = schedule.split('-');
    return (
      <S.Schedule>
        <S.Date>{date[2]}</S.Date>
        <S.Date>{monthNames[month.getMonth()]}</S.Date>
      </S.Schedule>
    );
  };

  return (
    <S.Modal>
      <S.Box>
        <S.Center>
          <S.Header>
            {t('components.modal.schedule_suggestions.title')}
          </S.Header>
        </S.Center>

        <S.Center>
          <S.SubTitle>
            {t('components.modal.schedule_suggestions.description')}
          </S.SubTitle>
        </S.Center>

        <S.Content>
          {scheduleSuggestions.map((schedule, index) => (
            <S.RowSchedules key={index.toString()}>
              <S.ItemList
                onClick={() => onSelectSchedule(index)}
                selected={schedule.selecionado}
              >
                <S.Center>{renderDates(schedule.data)}</S.Center>

                <S.Center>
                  <span className="weekday">
                    {moment(schedule.data).locale(language).format('dddd')}
                  </span>
                </S.Center>

                <S.Center>
                  <S.HDivider selected={schedule.selecionado} />
                </S.Center>

                <S.Center>
                  <S.Hour>{schedule.horario}</S.Hour>
                </S.Center>

                <S.Center>
                  <S.SelectText selected={schedule.selecionado}>
                    SELECIONAR
                  </S.SelectText>
                </S.Center>
              </S.ItemList>
            </S.RowSchedules>
          ))}
        </S.Content>

        <S.Footer>
          <S.Button type="outline" onClick={onClose}>
            {t('components.modal.schedule_suggestions.btn_secondary')}
          </S.Button>

          <S.Button
            type="default"
            disabled={
              scheduleSuggestions.filter(item => item.selecionado).length === 0
            }
            onClick={onFinish}
          >
            {t('components.modal.schedule_suggestions.btn_primary')}
          </S.Button>
        </S.Footer>
      </S.Box>
    </S.Modal>
  );
};

export default ScheduleSuggestionModal;
