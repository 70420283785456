import { styled } from '@material-ui/core/styles';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  height: '776px', // height fixado pra componente renderizar corretamente.
  overflow: 'hidden', // evita efeito de slide bugado na tela toda.
  '& .MuiPickersStaticWrapper-staticWrapperRoot': {
    backgroundColor: 'transparent',
    minWidth: '1000px',
    overflow: 'initial',
  },
  '& .MuiPickersBasePicker-container': {
    height: '100%',
  },
  '& .MuiPickersCalendarHeader-switchHeader': {
    justifyContent: 'center',
    '& .MuiPickersCalendarHeader-transitionContainer': {
      width: '30%',
      height: '2rem',
      color: theme.colors.primary,
      '& p': {
        fontSize: '1.5rem',
        fontWeight: 700,
        textTransform: 'capitalize',
      },
    },
  },
  '& .MuiPickersBasePicker-pickerView': {
    maxWidth: '100%',
    minWidth: '100%',
    minHeight: '100%',
    overflow: 'initial',
    justifyContent: 'flex-start',
    '& .MuiPickersCalendarHeader-daysHeader': {
      '& .MuiPickersCalendarHeader-dayLabel': {
        width: 'calc(100% / 7)',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        color: theme.colors.primary,
      },
    },
    '& .MuiPickersCalendar-week': {
      display: 'grid',
      gridTemplateColumns: 'repeat(7, 1fr)',
    },
  },
}));
