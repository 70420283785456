import IWebSocketProvider from 'shared/domain/providers/IWebSocketProvider';
import ICheckinPubSub from 'shared/domain/pubsubs/ICheckinPubsub';

const EVENTS = {
  IDENTIFICATION: 'identify',
  FAILURE: 'identifyFailure',
};

export default class V1TotemCheckinPubsub implements ICheckinPubSub {
  constructor(private webSocketProvider: IWebSocketProvider) {}

  public subscribeForIdentification: ICheckinPubSub['subscribeForIdentification'] =
    subscription => {
      this.webSocketProvider.addEventListener<{ idCliente: number }>(
        EVENTS.IDENTIFICATION,
        payload => {
          subscription({ customerId: payload.idCliente });
        },
      );

      return () => {
        this.webSocketProvider.removeEventListener(EVENTS.IDENTIFICATION);
      };
    };

  public subscribeForFailure: ICheckinPubSub['subscribeForFailure'] =
    subscription => {
      this.webSocketProvider.addEventListener(EVENTS.FAILURE, () => {
        subscription();
      });

      return () => {
        this.webSocketProvider.removeEventListener(EVENTS.FAILURE);
      };
    };
}
