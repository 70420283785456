import { makeCustomersRepository } from 'shared/infra/repositories';

import FetchCustomerByDocumentUseCase from './implementations/FetchCustomerByDocumentUseCase';

let instance: FetchCustomerByDocumentUseCase | null;

export default function makeFetchCustomerByDocumentUseCase() {
  if (!instance) {
    const repository = makeCustomersRepository();

    instance = new FetchCustomerByDocumentUseCase(repository);
  }

  return instance;
}
