import MySchedules from 'modules/schedules/presentation/pages/MySchedules';
import NewSchedule from 'modules/schedules/presentation/pages/NewSchedule';
import type { TRoute } from 'shared/presentation/modules/definitions';

const schedulesRoutes: TRoute[] = [
  {
    type: 'route',
    path: '/my-schedules',
    component: MySchedules,
  },
  {
    type: 'route',
    path: '/new-schedule',
    component: NewSchedule,
  },
];

export default schedulesRoutes;
