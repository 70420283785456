import { styled } from '@material-ui/core/styles';

export const Container = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  color: theme.colors.black,
  background: theme.colors.background,
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'start',
  '& main': {
    height: '100%',
  },
}));

export const ContainerMain = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  flexGrow: 1,
}));

export const Logo = styled('div')({
  width: '100%',
  textAlign: 'left',
  padding: '2rem 0rem 0rem 3rem',
});
