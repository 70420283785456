import { useCallback, useState } from 'react';

import { makeHTTPProvider } from 'shared/infra/providers';
import { useUnit } from 'shared/presentation/contexts';

type TVectisQuestion = {
  idAgenda: number;
  quantidadeDiasToleranciaTomouSol: number;
  solicitaAvaliacao: boolean;
  itensAgendados: Array<{
    idItemContrato: number;
    descricao: string;
    imagem: string;
  }>;
  idCliente: number;
};

interface IQuestion {
  scheduleId: number;
  sunToleranceInDays: number;
  items: Array<{ id: number; title: string; image: string }>;
  customerId: number;
}

export default function useCheckSunExposureQuestion() {
  const [questionData, setQuestionData] = useState<IQuestion | null>(null);
  const { unit } = useUnit();

  const shouldQuestionAboutSunExposure = useCallback(
    async (params: { id?: number; document?: string }) => {
      if (!unit) return false;

      const vectisQuestion = await makeHTTPProvider().get<TVectisQuestion>(
        '/avaliacaoPreSessao/checkin',
        {
          params: {
            unidade: unit.id,
            cliente: params.id,
            cpf: params.document,
          },
        },
      );

      if (vectisQuestion.solicitaAvaliacao) {
        setQuestionData({
          sunToleranceInDays: vectisQuestion.quantidadeDiasToleranciaTomouSol,
          scheduleId: vectisQuestion.idAgenda,
          items: vectisQuestion.itensAgendados.map(item => ({
            id: item.idItemContrato,
            title: item.descricao,
            image: item.imagem,
          })),
          customerId: vectisQuestion.idCliente,
        });
      }

      return vectisQuestion.solicitaAvaliacao;
    },
    [unit],
  );

  return {
    shouldQuestionAboutSunExposure,
    questionData,
    resetData: () => setQuestionData(null),
  };
}
