import {
  ComponentRef,
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { FormikProvider, useFormik } from 'formik';
import { UpdateDataSchema } from 'validations';

import { ClockTimer, Modal } from 'shared/presentation/components/atoms';
import { useLocale } from 'shared/presentation/contexts';

import * as S from './styles';
import { useConfirmCustomerData } from '../../hooks';
import ConfirmingBiometry from '../ConfirmingBiometry';
import UpdateDataForm from '../UpdateDataForm';
import WaitingBiometryPanel from '../WaitingBiometryPanel';

interface ICustomer {
  id: number;
  fullName: string;
  document: string;
  email: string;
  phone: string;
}

interface IUpdateDataModalRef {
  open(): void;
}

interface IUpdateDataModalProps {
  customer: ICustomer;
  onSuccess(customer: ICustomer): Promise<unknown>;
}

const UpdateDataModal: ForwardRefRenderFunction<
  IUpdateDataModalRef,
  IUpdateDataModalProps
> = ({ customer, onSuccess }, ref) => {
  const [state, setState] = useState<'form' | 'biometry' | 'waiting'>('form');

  const modalRef = useRef<ComponentRef<typeof Modal>>(null);
  const clockRef = useRef<ComponentRef<typeof ClockTimer>>(null);

  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        modalRef.current?.open();
        clockRef.current?.reset();
      },
    }),
    [],
  );

  const { t, sharedT, language } = useLocale('translations');

  const controller = useConfirmCustomerData(customer);

  const form = useFormik({
    initialValues: {
      email: '',
      phone: '',
    },
    validateOnMount: true,
    validationSchema: UpdateDataSchema({
      t,
      sharedT,
      customer,
      lng: language,
    }),
    onSubmit: () => {
      setState('biometry');
    },
  });

  const handleConfirmBiometry = async () => {
    try {
      setState('waiting');

      await controller.confirm(form.values);

      await onSuccess(customer);

      modalRef.current?.close();
      setState('form');
      form.resetForm();
    } catch (error) {
      if (error instanceof Error) {
        form.setFieldError('email', error.message);
      }
      setState('form');
    }
  };

  return (
    <Modal
      ref={modalRef}
      closeOnOverlayClick={false}
      // The clock must be reset like this because this "page" is a modal
      // After we refactor the checkin flow, we may be able to remove this and let the ClockTimer handle it itself
      onContentClick={() => clockRef.current?.reset()}
      onClose={() => form.resetForm()}
    >
      <FormikProvider value={form}>
        <S.Container>
          <ClockTimer
            ref={clockRef}
            onTimeUp={useCallback(() => {
              modalRef.current?.close();
              setState('form');
            }, [])}
          />

          {state === 'waiting' ? (
            <ConfirmingBiometry />
          ) : state === 'form' ? (
            <UpdateDataForm customer={customer} />
          ) : (
            <WaitingBiometryPanel
              customerId={customer.id}
              onGoBack={() => setState('form')}
              onConfirm={handleConfirmBiometry}
            />
          )}
        </S.Container>
      </FormikProvider>
    </Modal>
  );
};

export default forwardRef(UpdateDataModal);
