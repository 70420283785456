import { styled } from '@material-ui/core/styles';

export const ModalWrapper = styled('div')({
  display: 'grid',
  gap: '1rem',
  width: '50rem',
  textAlign: 'center',
  justifyContent: 'center',
  '& video': {
    width: '8rem',
    height: '10rem',
    margin: '0 auto',
  },
  '& button': {
    width: '100%',
    margin: '0 auto',
  },
});

export const TitleModal = styled('h1')(({ theme }) => ({
  fontSize: theme.font.sizes.small,
  color: theme.colors.gray,
}));
