import { useState } from 'react';

import { useMutation } from 'react-query';

import { makeFetchCustomerByDocumentUseCase } from 'modules/checkin/domain/useCases';
import { useActiveCustomer, useLocale } from 'shared/presentation/contexts';

export default function useFindCustomerByDocument() {
  const { setCustomer } = useActiveCustomer();
  const { t } = useLocale('shared');

  const [error, setError] = useState<string | undefined>(undefined);

  const mutation = useMutation(
    (document: string) =>
      makeFetchCustomerByDocumentUseCase().execute({ document }),
    {
      onSuccess: customer => {
        setCustomer(customer);

        if (!customer) {
          setError(
            t('components.user_identification.cpf_identification_failed'),
          );
        }
      },
      onError: error => {
        if (error instanceof Error) return setError(error.message);

        return setError(
          t('components.user_identification.cpf_identification_failed'),
        );
      },
    },
  );

  return {
    loading: mutation.isLoading,
    find: mutation.mutateAsync,
    error,
  };
}
