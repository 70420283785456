import { useMemo, useState } from 'react';

import { useTheme } from '@material-ui/core';

import { MASKS, MaskFormatter } from 'shared/presentation/constants';
import { useLocale } from 'shared/presentation/contexts';

import * as S from './styles';
import { Button } from '../button/styles';
import Modal from '../modal';

type TNumberPadProps = {
  open: boolean;
  error?: string;
  onSubmit: (value: string) => void;
  handleClose(): void;
};

const PAD_NUMBERS = [1, 2, 3, 4, 5, 6, 7, 8, 9];

const NumberPad: React.FC<TNumberPadProps> = ({
  open,
  error,
  onSubmit,
  handleClose,
}) => {
  const theme = useTheme();
  const { t, language } = useLocale('translations');

  const [inputValue, setInputValue] = useState<string>('');

  const formatter = useMemo(() => {
    const mask = MASKS.DOCUMENT[language];

    return new MaskFormatter(mask);
  }, [language]);
  const maskedValue = formatter.format(inputValue);

  const addDigit = (value: string) => {
    const newValue = inputValue + value;

    if (formatter.isInputMaskable(newValue)) {
      setInputValue(newValue);
    }
  };

  const handleSubmit = (value: string) => {
    onSubmit(value);
    setInputValue('');
  };

  const clearLast = () => {
    if (inputValue.length) {
      setInputValue(inputValue.slice(0, inputValue.length - 1));
    }
  };

  const isButtonDisabled = useMemo(() => {
    if (language === 'es-CR' && inputValue.length === 11) {
      return false;
    }
    if (language === 'es' && inputValue.length === 6) return false;

    return !formatter.isValid(inputValue);
  }, [inputValue, language, formatter]);

  return (
    <Modal open={open} onClose={handleClose}>
      <S.Container>
        <S.Title>{t('components.numberPad.title')}</S.Title>

        <S.PadDisplay readOnly={true} value={maskedValue} />

        <S.PadButtonGrid>
          {PAD_NUMBERS.map(number => (
            <S.PadButton
              key={number.toString()}
              data-cy={`padButton${number}`}
              onClick={() => addDigit(number.toString())}
            >
              {number.toString()}
            </S.PadButton>
          ))}

          <S.PadButton
            data-cy="padButtonClear"
            extended={1}
            onClick={clearLast}
          >
            {t('components.numberPad.actions.clear')}
          </S.PadButton>

          <S.PadButton data-cy="padButton0" onClick={() => addDigit('0')}>
            0
          </S.PadButton>
        </S.PadButtonGrid>

        {error && <S.Error>{t(error as any)}</S.Error>}

        <Button
          color={theme.colors.white}
          background={theme.colors.primary}
          disabled={isButtonDisabled}
          onClick={() => handleSubmit(inputValue)}
          data-cy="padButtonSend"
        >
          {t('components.numberPad.actions.send')}
        </Button>
      </S.Container>
    </Modal>
  );
};

export default NumberPad;
