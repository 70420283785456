import { useTheme } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { Duration, Moment } from 'moment';

import { useLocale } from 'shared/presentation/contexts';

import * as S from './styles';
import flagSuccess from '../../../../../assets/svgs/AGENDAR SESSÇO - 1.svg';
import scheduleSuccess from '../../../../../assets/svgs/AGENDAR SESSÇO - 2.svg';
import flagRed from '../../../../../assets/svgs/AGENDAR SESSÇO - 4.svg';
import scheduleRed from '../../../../../assets/svgs/AGENDAR SESSÇO - 5.svg';
import { useScheduling } from '../../../../../context/SchedulingContext';
import Button from '../../../../button';

export interface IRegionCardData {
  id: number;
  image: string;
  description: string;
  nextSession: string;
  sessionDuration: Duration;
  nextSchedule: Moment;
  blockingScheduleMessage?: string;
}

interface IRegionCardProps {
  schedule: IRegionCardData;
}

const RegionCard: React.FC<IRegionCardProps> = ({ schedule }) => {
  const theme = useTheme();

  const { t } = useLocale('translations');

  const { toggleArea, selectedAreas } = useScheduling();

  const isSelected = selectedAreas[schedule.id];
  const disabledSelection = !!schedule.blockingScheduleMessage;

  return (
    <S.Card
      data-cy={`regionCard-${schedule.id}`}
      selected={isSelected}
      onClick={() => {
        if (disabledSelection) return;
        toggleArea(schedule.id, !isSelected);
      }}
    >
      <S.CardContent key={schedule.id}>
        <S.CardItem selected={isSelected} disabled={disabledSelection}>
          <S.Img src={schedule.image} disabled={disabledSelection} alt="" />
          <h2>{schedule.description}</h2>
        </S.CardItem>

        <S.CardInfo>
          <S.CardSession>
            <img src={isSelected ? flagSuccess : flagRed} alt="" />

            <S.CardSessionDescription>
              <S.Strong disabled={disabledSelection}>
                {t('components.schedule_regions.next_session')}
              </S.Strong>
              <S.Span selected={isSelected}>{schedule.nextSession}</S.Span>
            </S.CardSessionDescription>
          </S.CardSession>

          <S.CardSchedule>
            <img src={isSelected ? scheduleSuccess : scheduleRed} alt="" />

            <S.CardScheduleDescription>
              <S.Strong disabled={disabledSelection}>
                {t('components.schedule_regions.schedule_from_day')}
              </S.Strong>
              <S.Span selected={isSelected}>
                {schedule.nextSchedule.format('DD/MM/YYYY')}
              </S.Span>
            </S.CardScheduleDescription>
          </S.CardSchedule>
        </S.CardInfo>

        <S.CardFooter>
          {disabledSelection ? (
            <p>{schedule.blockingScheduleMessage}</p>
          ) : (
            <Button
              onClick={() => null}
              bordercolor={
                isSelected ? theme.colors.greenLight : theme.colors.pink
              }
              color={isSelected ? theme.colors.darkPrimary : theme.colors.pink}
              icon={isSelected ? <CheckCircleOutlineIcon /> : false}
              background={theme.colors.white}
            >
              {isSelected
                ? t('components.schedule_regions.selected')
                : t('components.schedule_regions.select')}
            </Button>
          )}
        </S.CardFooter>
      </S.CardContent>
    </S.Card>
  );
};

export default RegionCard;
