import { IFetchUnitByIdDTO } from 'shared/domain/dtos';
import { IUnitsRepository } from 'shared/domain/repositories';

export default class FetchUnitByIdUseCase {
  constructor(private unitRepository: IUnitsRepository) {}

  public async execute({ unitId }: IFetchUnitByIdDTO) {
    return this.unitRepository.findById(unitId);
  }
}
