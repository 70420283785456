import { ICustomer } from 'shared/domain/entities';

export interface IVectisCustomer {
  id: number;
  nome: string;
  sobrenome?: string;
  nomeCompleto?: string;
  foneCelular?: string;
  sexo?: 'FEMININO' | 'MASCULINO';
  cpf?: string;
  cpfCnpj?: string;
  email?: string;
  rg?: string;
  dataNascimento?: string;
  numeroDocumentoFiscal?: string;
  hash?: string;
  responsavel?: {
    nome?: string;
    sobrenome?: string;
    email?: string;
    cpfCnpj?: string;
    cpf?: string;
    rg?: string;
    numeroRg?: string;
    dataNascimento?: string;
    numeroDocumentoFiscal?: string;
    foneCelular?: string;
  };
}

const API_GENDER_DICT: Record<
  NonNullable<IVectisCustomer['sexo']>,
  ICustomer['gender']
> = {
  FEMININO: 'FEMALE',
  MASCULINO: 'MALE',
};

type TBuildFullName = {
  firstName: string;
  lastName?: string;
  fullName?: string;
};

export class Customer implements ICustomer {
  public id: ICustomer['id'];
  public firstName: ICustomer['firstName'];
  public lastName: ICustomer['lastName'];
  public fullName: ICustomer['fullName'];
  public phone: ICustomer['phone'];
  public gender: ICustomer['gender'];
  public document: ICustomer['document'];
  public alternativeDocument: ICustomer['alternativeDocument'];
  public birthdate: ICustomer['birthdate'];
  public email: ICustomer['email'];
  public guardian: ICustomer['guardian'];
  public idHash: ICustomer['idHash'];

  constructor({
    id,
    nome,
    sobrenome,
    nomeCompleto,
    foneCelular,
    sexo,
    cpf,
    cpfCnpj,

    dataNascimento,

    numeroDocumentoFiscal,
    responsavel,
    email,
    rg,

    hash,
  }: IVectisCustomer) {
    this.id = id;
    this.firstName = nome;
    this.lastName = sobrenome || '';
    this.fullName = this.buildFullName({
      firstName: nome,
      lastName: sobrenome,
      fullName: nomeCompleto,
    });
    this.phone = foneCelular;
    this.gender = sexo && API_GENDER_DICT[sexo];
    this.document = cpf || cpfCnpj;
    this.alternativeDocument = rg || numeroDocumentoFiscal;
    this.birthdate = dataNascimento;
    this.email = email;
    this.guardian = this.buildGuardian(responsavel);
    this.idHash = hash;
  }

  private buildFullName({ firstName, fullName, lastName }: TBuildFullName) {
    if (fullName) return fullName;

    if (!lastName) return firstName;

    return `${firstName} ${lastName}`;
  }

  private buildGuardian(
    guardian: IVectisCustomer['responsavel'],
  ): ICustomer['guardian'] {
    if (!guardian) return;

    const {
      cpfCnpj,
      cpf,
      dataNascimento,
      email,

      nome,
      numeroDocumentoFiscal,
      rg,
      numeroRg,
      sobrenome,

      foneCelular,
    } = guardian;

    return {
      firstName: nome,
      lastName: sobrenome,
      document: cpfCnpj || cpf,
      alternativeDocument: rg || numeroRg || numeroDocumentoFiscal,
      birthdate: dataNascimento,
      email,
      phone: foneCelular,
    };
  }
}
