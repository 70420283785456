import { useCallback } from 'react';

import { makeHTTPProvider } from 'shared/infra/providers';
import { useUnit } from 'shared/presentation/contexts';

type TParams = {
  id: number;
};

export default function useConfirmCustomerData({ id }: TParams) {
  const { unit } = useUnit();

  const confirm = useCallback(
    async (params: { phone: string; email: string }) => {
      if (!unit) return;

      return makeHTTPProvider().post(`/clientes/${id}/confirmacaoContato`, {
        email: params.email,
        foneCelular: params.phone,
        idUnidade: unit.id,
      });
    },
    [id, unit],
  );

  return {
    confirm,
  };
}
