import { FiCalendar, FiStar } from 'react-icons/fi';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';

import { makeHTTPProvider } from 'shared/infra/providers';
import { useLocale } from 'shared/presentation/contexts';

import * as S from './styles';
import ClinicaSvg from '../../../assets/svgs/areas.svg';
import { useSnackBar } from '../../../context/SnackBarContext';
import theme from '../../../styles/theme';
import {
  TCheckinLastSessionResponse,
  TCheckinResponseAreaError,
} from '../../../types/api';
import Button from '../../button';
import Carousel from '../../carousel';

type TModalLastSessionValidationProps = {
  info: Partial<TCheckinLastSessionResponse>;
  closeModal: (openCheckin: boolean) => void;
};

const ModalLastSessionValidation = ({
  info,
  closeModal,
}: TModalLastSessionValidationProps) => {
  const { t } = useLocale('translations');
  const { showSnackBar } = useSnackBar();

  const renderAreasCarousel = (areas: TCheckinResponseAreaError[]) => {
    return (
      areas && (
        <Carousel
          emulateTouch={true}
          swipeable={true}
          showArrows={true}
          items={areas.map(area => {
            return {
              src: area.photo,
              alt: area.name,
              itemCaptionColor: theme.colors.pink,
            };
          })}
        />
      )
    );
  };

  const confirmSession = async () => {
    const body = {
      sessao: info.session,
      origem: 'TOTEM',
    };

    try {
      await makeHTTPProvider().post('/area-cliente/sessoes/confirmar', body);
      closeModal(true);
    } catch (responseError) {
      closeModal(true);
      showSnackBar(
        t('components.modal.last_session_validation.not_confirmed', {
          name: info.username || '',
        }),
        'error',
      );
    }
  };

  return (
    <S.Wrap>
      <h2>
        {t('components.modal.last_session_validation.not_confirmed_last', {
          name: info.username || '',
        })}
      </h2>

      <h3>{t('components.modal.last_session_validation.start_request')}</h3>
      <S.Content>
        {info.areas && renderAreasCarousel(info.areas)}
        <S.Infos>
          <S.Info>
            <S.Icon>
              <FiCalendar size={16} color={theme.colors.white} />
            </S.Icon>
            <S.InfoTitle>
              {t('components.modal.last_session_validation.date_time')}
            </S.InfoTitle>
            <S.InfoSubtitle>{info.data}</S.InfoSubtitle>
          </S.Info>
          <S.Info>
            <S.Icon>
              <img src={ClinicaSvg} alt="clínica" />
            </S.Icon>
            <S.InfoTitle>
              {t('components.modal.last_session_validation.unit')}
            </S.InfoTitle>
            <S.InfoSubtitle>{info.unit}</S.InfoSubtitle>
          </S.Info>
          <S.Info>
            <S.Icon>
              <FiStar size={16} color={theme.colors.white} />
            </S.Icon>
            <S.InfoTitle>
              {t('components.modal.last_session_validation.applicator')}
            </S.InfoTitle>

            <S.InfoSubtitle>{info.aplyier}</S.InfoSubtitle>
          </S.Info>
        </S.Infos>
        <Button
          color={theme.colors.white}
          background={theme.colors.primary}
          icon={
            <IoMdCheckmarkCircleOutline size={24} color={theme.colors.white} />
          }
          onClick={() => confirmSession()}
        >
          {t('components.modal.last_session_validation.confirm_session')}
        </Button>
      </S.Content>
    </S.Wrap>
  );
};

export default ModalLastSessionValidation;
