import { ICheckinRepository } from 'shared/domain/repositories';
import { makeHTTPProvider } from 'shared/infra/providers';

import VectisCheckinRepository from './implementations/VectisCheckinRepository';

let instance: ICheckinRepository | null = null;

export default function makeCheckinRepository() {
  if (!instance) {
    const httpProvider = makeHTTPProvider();

    instance = new VectisCheckinRepository(httpProvider);
  }

  return instance;
}
