import { createContext, useContext } from 'react';

import { isEmpty } from 'lodash';

import { TLocale, TTotemVersion } from 'shared/domain/valueObjects';

interface ITotemInfoContextData {
  unitId?: number;
  language?: TLocale;
  version?: TTotemVersion;
}

export const TotemInfoContext = createContext<ITotemInfoContextData>(
  {} as ITotemInfoContextData,
);

export const useTotemInfo = () => {
  const context = useContext(TotemInfoContext);

  if (isEmpty(context)) {
    throw new Error('useTotemInfo should be called within a TotemInfoContext');
  }

  return context;
};
