import { useEffect, useState } from 'react';

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { IHourAvailabilityPerDayItem } from 'context/SchedulingContext';
import moment from 'moment';

import { useLocale } from 'shared/presentation/contexts';

import {
  ClicableContainer,
  DayInfoNumber,
  DayInfoText,
  DayInfoWrapper,
  DayNumberWrapper,
} from './styles';

type TScheduleDayProps = {
  day: MaterialUiPickersDate | moment.Moment;
  selected: boolean;
  disabled: boolean;
  availableTime: IHourAvailabilityPerDayItem;
  selectedDayPeriods: { [key: string]: boolean };
};

const ScheduleDay: React.FC<TScheduleDayProps> = ({
  day,
  selected,
  disabled,
  availableTime,
  selectedDayPeriods,
}) => {
  const { t } = useLocale('translations');
  const [availableTimeNumber, setAvailableTimeNumber] = useState('0');

  useEffect(() => {
    const keys = Object.keys(selectedDayPeriods);
    const selectedDayPeriodsLenght = keys.length;
    const selectedDayPeriodsHasSelected =
      keys.length > 0 &&
      keys.reduce(
        (prev, key) => selectedDayPeriods[key] === true || prev,
        false,
      );

    let value = 0;
    if (selectedDayPeriodsLenght > 0 && selectedDayPeriodsHasSelected) {
      keys.forEach(key => {
        if (selectedDayPeriods[key] === true) {
          value = value + (availableTime.byPeriods[key] || 0);
        }
      });
    } else {
      value = availableTime.total;
    }

    setAvailableTimeNumber(value.toString().padStart(2, '0'));
  }, [availableTime.byPeriods, availableTime.total, selectedDayPeriods]);

  const dayLabel = day?.format('DD');

  return (
    <ClicableContainer
      data-cy={`selectableDay-${dayLabel}`}
      disabled={disabled}
      selected={selected}
    >
      <DayNumberWrapper>{dayLabel} </DayNumberWrapper>
      <DayInfoWrapper disabled={disabled} selected={selected}>
        <DayInfoNumber>{availableTimeNumber}</DayInfoNumber>
        <DayInfoText>
          {t('components.schedule_calendar.avaiable_hours')}
        </DayInfoText>
      </DayInfoWrapper>
    </ClicableContainer>
  );
};

export default ScheduleDay;
