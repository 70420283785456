export enum ModalTypes {
  BIOMETRIA = 'BIOMETRIA',
  IDENTIFY = 'IDENTIFY',

  REQUISICAO_INVALIDA = 'REQUISICAO_INVALIDA',
  NAO_AGENDADO_UNIDADE = 'NAO_AGENDADO_UNIDADE',
  NAO_AGENDADO_DIA = 'NAO_AGENDADO_DIA',
  CHECKIN_JA_REALIZADO = 'CHECKIN_JA_REALIZADO',
  SEM_AGENDAMENTO = 'SEM_AGENDAMENTO',
  SESSAO_ANTERIOR_NAO_CONFIRMADA = 'SESSAO_ANTERIOR_NAO_CONFIRMADA',
  PENDENCIA = 'PENDENCIA',
  CONTRATO_NAO_ASSINADO = 'CONTRATO_NAO_ASSINADO',
  SUCESSO = 'SUCESSO',
}
