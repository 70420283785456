import SunExposure from 'modules/checkin/presentation/pages/SunExposure';
import type { TRoute } from 'shared/presentation/modules/definitions';

const checkinRoutes: TRoute[] = [
  {
    type: 'route',
    path: '/sun-exposure',
    component: SunExposure,
  },
];

export default checkinRoutes;
