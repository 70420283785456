import IHTTPProvider from 'shared/domain/providers/IHTTPProvider';
import { IUnitsRepository } from 'shared/domain/repositories';
import { Unit, IVectisUnit } from 'shared/infra/adapters';

export default class VectisUnitsRepository implements IUnitsRepository {
  constructor(private httpProvider: IHTTPProvider) {}

  public findById: IUnitsRepository['findById'] = async id => {
    const unit = await this.httpProvider.get<IVectisUnit>(`unidades/${id}`);

    return new Unit(unit);
  };
}
