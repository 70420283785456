import { Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

export const Container = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
}));

export const HourPeriodWrapper = styled('div')(() => ({
  display: 'flex',
  flexFlow: 'column',
  alignItems: 'center',
  minWidth: '9.25rem',
}));

export const HoursWrapper = styled('div')(() => ({
  display: 'flex',
  flexFlow: 'column',
  alignItems: 'center',
  padding: '.5rem 1rem',
  maxHeight: '735px',
  overflowY: 'scroll',
}));

export const HourPeriodTitle = styled(Typography)(({ theme }) => ({
  color: theme.colors.primary,
  fontWeight: 'bold',
  marginBottom: '1rem',
  marginLeft: '1rem',
  marginRight: '1rem',
}));
