import styled, { css } from 'styled-components';

export const Container = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    text-align: center;

    background-color: ${theme.palette.neutral[50]};
    padding: ${theme.layout.spacing(4)};
    ${theme.layout.rowGap(4)}
    border-radius: ${theme.layout.spacing(4)};
    width: ${theme.layout.spacing(60)};

    h2 {
      font-size: ${theme.typography.sizes.title2};
      font-weight: ${theme.typography.weight.extraBold};
      color: ${theme.palette.primary.dark};
    }

    section {
      display: flex;
      flex-direction: column;
      ${theme.layout.rowGap(3)};

      > div {
        display: flex;
        justify-content: center;
        ${theme.layout.columnGap(2)};
        flex-wrap: wrap;

        button {
          background: none;
          border: none;

          display: flex;
          flex-direction: column;
          ${theme.layout.rowGap(1.5)};
          align-items: center;

          img {
            width: ${theme.layout.spacing(8)};
            height: ${theme.layout.spacing(8)};
            border-radius: 50%;
            border: 1px solid transparent;
          }

          span {
            font-size: ${theme.typography.sizes.body2};
            font-weight: ${theme.typography.weight.regular};
            color: ${theme.palette.text.main};
          }

          &[aria-selected='true'] {
            img {
              border-color: ${theme.palette.primary.main};
            }

            span {
              color: ${theme.palette.primary.dark};
            }
          }
        }
      }

      caption {
        font-size: ${theme.typography.sizes.caption};
        font-weight: ${theme.typography.weight.regular};
        color: ${theme.palette.text.light};
        display: flex;
        justify-content: center;

        svg {
          display: none;
        }

        &[data-error='true'] {
          color: ${theme.palette.error.main};
          font-weight: ${theme.typography.weight.bold};

          svg {
            display: block;
            font-size: 1.25rem;
          }
        }
      }
    }

    footer {
      display: flex;
      flex-direction: column;
      ${theme.layout.rowGap(1.5)}
    }
  `}
`;
