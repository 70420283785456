import { FC } from 'react';

import { useQuery } from 'react-query';

import { makeFetchUnitByIdUseCase } from 'shared/domain/useCases';
import { sharedQueries } from 'shared/presentation/constants';
import { useTotemInfo } from 'shared/presentation/contexts';

import { UnitContext } from '../../hooks/useUnit';

const UnitProvider: FC = ({ children }) => {
  const { unitId } = useTotemInfo();

  const query = useQuery(
    sharedQueries.unit(unitId),
    () => {
      if (!unitId) throw new Error();

      return makeFetchUnitByIdUseCase().execute({ unitId });
    },
    {
      enabled: !!unitId,
    },
  );

  return (
    <UnitContext.Provider value={{ unit: query.data || null }}>
      {children}
    </UnitContext.Provider>
  );
};

export default UnitProvider;
