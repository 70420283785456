import React from 'react';

import { useLocale } from 'shared/presentation/contexts';

import ManIcon from '../../../assets/svgs/icon-man-pc.svg';
import { ModalWrapper, SubTitleModal, TitleModal } from '../styles';

const ModalCpfValidation = () => {
  const { t } = useLocale('translations');

  const alt = t('components.modal.cpf_error.icon_alt');

  return (
    <ModalWrapper>
      <TitleModal>{t('components.modal.cpf_error.title')}</TitleModal>
      <SubTitleModal>{t('components.modal.cpf_error.subtitle')}</SubTitleModal>
      <img src={ManIcon} height="100" width="100" alt={alt} />
    </ModalWrapper>
  );
};

export default ModalCpfValidation;
