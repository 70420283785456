import React from 'react';

import Spinner from 'shared/presentation/components/atoms/Spinner';
import { ICONS } from 'shared/presentation/constants';
import { useTheme } from 'shared/presentation/contexts';

import * as S from './styles';

const Icon = ICONS.FINGERPRINT;

interface IHoveringFingerprintProps {
  loading?: boolean;
}

const HoveringFingerprint: React.FC<IHoveringFingerprintProps> = ({
  loading,
}) => {
  const { theme } = useTheme();

  return (
    <S.Container>
      <div>
        {loading ? (
          <Spinner color={theme.palette.primary.main} size={3} />
        ) : (
          <Icon />
        )}
      </div>
    </S.Container>
  );
};

export default HoveringFingerprint;
