import React from 'react';

import { Spinner } from 'shared/presentation/components/atoms';
import { ITheme, useTheme } from 'shared/presentation/contexts';

import { IButtonContext, useButton } from '../../context';

const SPINNER_COLORS: Record<
  NonNullable<IButtonContext['variant']>,
  (theme: ITheme) => string
> = {
  default: theme => theme.palette.text.main,
  error: theme => theme.palette.error.main,
  warning: theme => theme.palette.warning.main,
  primary: theme => theme.palette.primary.main,
  secondary: theme => theme.palette.secondary.main,
};

const getSpinnerColor = (
  variant: IButtonContext['variant'],
  outline: IButtonContext['outline'],
  theme: ITheme,
) => {
  if (!variant) return theme.palette.text.main;
  if (outline) return SPINNER_COLORS[variant](theme);

  return theme.palette.text.white;
};

const LoadingSpinner: React.FC = () => {
  const { outline, variant } = useButton();
  const { theme } = useTheme();

  return (
    <Spinner
      key="spinner"
      color={getSpinnerColor(variant, outline, theme)}
      size={1}
    />
  );
};

export default LoadingSpinner;
