import { createContext, useContext, useState } from 'react';

import { Snackbar } from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';

type TSnackBarContextActions = {
  showSnackBar: (text: string, typeColor: Color) => void;
};

const SnackBarContext = createContext({} as TSnackBarContextActions);

interface ISnackBarContextProviderProps {
  children: React.ReactNode;
}

const SnackBarProvider: React.FC<ISnackBarContextProviderProps> = ({
  children,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [typeColor, setTypeColor] = useState<Color>('info');

  const showSnackBar = (text: string, color: Color) => {
    setMessage(text);
    setTypeColor(color);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTypeColor('info');
  };

  return (
    <SnackBarContext.Provider value={{ showSnackBar }}>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={typeColor}>
          {message}
        </Alert>
      </Snackbar>
      {children}
    </SnackBarContext.Provider>
  );
};

const useSnackBar = (): TSnackBarContextActions => {
  const context = useContext(SnackBarContext);

  if (!context) {
    throw new Error('useSnackBar must be used within an SnackBarProvider');
  }

  return context;
};

export { SnackBarProvider, useSnackBar };
