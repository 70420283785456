import { Stepper } from '@material-ui/core';
import { styled as Mstyled } from '@material-ui/core/styles';
import styled from 'styled-components';

import theme from '../../styles/theme';

export const WizardContainer = Mstyled('div')({
  display: 'grid',
  height: '100vh',
  gridTemplateRows: '6rem 3rem calc(100vh - 16rem) 7rem',
  background: theme.colors.background,
});

export const WizardHeader = Mstyled(Stepper)({
  background: theme.colors.background,
  '& .MuiStepLabel-label.MuiStepLabel-active': {
    color: theme.colors.primary,
  },
  '& .MuiSvgIcon-root': {
    fontSize: '2.5rem',
    fontWeight: 600,
  },
  '& .MuiStepLabel-label': {
    color: theme.colors.gray,
    fontSize: '2rem',
  },
});

export const TopHeader = styled.header`
  display: flex;
  justify-content: space-between;
`;

export const WizardContent = Mstyled('div')({
  height: 'calc(100vh - 256px)',
  overflow: 'auto',
  color: '#000',
  background: theme.colors.background,
  paddingTop: '3rem',
});

export const WizardFooter = Mstyled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.colors.background,
  padding: '24px',
  position: 'relative',
  zIndex: 'auto',
  '& Button': {
    width: 400,
  },
  '& > Button + Button': {
    marginLeft: '16px',
  },
});

export const TooltipText = Mstyled('p')({
  fontSize: 18,
  fontWeight: 600,
  color: '#fff',
  padding: '8px 12px',
  lineHeight: 1.2,
});
