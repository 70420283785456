import styled, { css, keyframes } from 'styled-components';

const ripple = keyframes`
  0% {
    width: 0px;
    height: 0px;
    opacity: 0.5;
  }
  100% {
    width: 500px;
    height: 500px;
    opacity: 0;
  }
`;

export const Container = styled.div<{ disabled?: boolean }>`
  ${({ theme, disabled }) => css`
    .virtual-keyboard {
      background-color: ${theme.palette.neutral[50]};
      border-radius: 1rem;

      ${disabled &&
      css`
        opacity: 0.7;
        filter: grayscale(0.8);
      `}

      padding: ${theme.layout.spacing(3)};

      .hg-rows {
        display: flex;
        flex-direction: column;
        ${theme.layout.rowGap(2)}

        .hg-row {
          ${theme.layout.columnGap(3)}

          .hg-button {
            font-family: ${theme.typography.family};
            font-size: ${theme.typography.sizes.body1};
            font-weight: ${theme.typography.weight.bold};
            background-color: ${theme.palette.neutral[200]};
            color: ${theme.palette.text.main};

            position: relative;
            overflow: hidden;

            border: none;
            box-shadow: none;

            border-radius: 1rem;

            height: ${theme.layout.spacing(6)};

            &.hg-button-space {
              flex-grow: 3;
            }

            &.primary {
              color: ${theme.palette.text.white};
              background-color: ${theme.palette.primary.main};
            }

            &.pressed {
              color: ${theme.palette.text.white};
              background-color: ${theme.palette.secondary.main};
            }

            &.capitalize {
              text-transform: capitalize;
            }

            .ripple {
              position: absolute;
              background-color: ${theme.palette.neutral[300]};
              transform: translate(-50%, -50%);
              pointer-events: none;
              border-radius: 50%;
              animation: ${ripple} 1s linear infinite;
            }
          }
        }
      }
    }
  `}
`;
