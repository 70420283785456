import { styled, Typography } from '@material-ui/core';

export const Title = styled('h1')(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.text.primary,
  fontWeight: theme.font.normal,
}));

export const Description = styled(Typography)(({ theme }) => ({
  margin: '.5rem',
  color: theme.palette.text.primary,
}));
