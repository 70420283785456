import { IoMdCheckmarkCircleOutline } from 'react-icons/io';

import { Container, GreenDot, HourText } from './styles';

type THourItemProps = {
  selected?: boolean;
  hour: string;
  onClick: (hour: string) => void;
};

const HourItem: React.FC<THourItemProps> = ({
  selected = false,
  hour,
  onClick,
}) => {
  return (
    <Container selected={selected} onClick={() => onClick(hour)}>
      {selected ? <IoMdCheckmarkCircleOutline size={32} /> : <GreenDot />}
      <HourText>{hour}</HourText>
    </Container>
  );
};

export default HourItem;
