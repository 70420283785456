import { useLocale } from 'shared/presentation/contexts';

import { Description, Title } from './styles';
import termsPt from './termsPt';
import termsPy from './termsPy';

const TERMS_BY_LANGUAGE = {
  pt: termsPt,
  es: termsPy,
  'es-CR': termsPy,
};

const ServiceTerm: React.FC = () => {
  const { t, language } = useLocale('translations');
  const terms = TERMS_BY_LANGUAGE[language];

  return (
    <div>
      <div className="text-center" style={{ marginBottom: '1rem' }}>
        <Title>{t('pages.contracts.detail.actions.terms_services')}</Title>
      </div>
      <div className="text-justify">
        <Description>{terms.termInformation}</Description>
        <Description style={{ margin: '.5rem' }}>
          {terms.termParagraph1}
        </Description>
        <Description style={{ margin: '.5rem' }}>
          {terms.termParagraph2}
        </Description>
        <Description style={{ margin: '.5rem' }}>
          {terms.termParagraph3}
        </Description>
        <Description style={{ margin: '.5rem' }}>
          {terms.termParagraph4}
        </Description>
        <Description style={{ margin: '.5rem' }}>
          {terms.termParagraph5}
        </Description>
        <Description style={{ margin: '.5rem' }}>
          {terms.termParagraph6}
        </Description>
        <Description style={{ margin: '.5rem' }}>
          {terms.termParagraph7}
        </Description>
        <Description style={{ margin: '.5rem' }}>
          {terms.termParagraph8}
        </Description>
        <Description style={{ margin: '.5rem' }}>
          {terms.termParagraph9}
        </Description>
        <Description style={{ margin: '.5rem' }}>
          {terms.termParagraph10}
        </Description>
        <Description style={{ margin: '.5rem' }}>
          {terms.termParagraph11}
        </Description>
        <Description style={{ margin: '.5rem' }}>
          {terms.termParagraph12}
        </Description>
        <Description style={{ margin: '.5rem' }}>
          {terms.termParagraph13}
        </Description>
        <Description style={{ margin: '.5rem' }}>
          {terms.termParagraph14}
        </Description>
        <Description style={{ margin: '.5rem' }}>
          {terms.termParagraph15}
        </Description>
        <Description style={{ margin: '.5rem' }}>
          {terms.termParagraph16}
        </Description>
        <Description style={{ margin: '.5rem' }}>
          {terms.termParagraph17}
        </Description>
        <Description style={{ margin: '.5rem' }}>
          {terms.termParagraph18}
        </Description>
      </div>
    </div>
  );
};

export default ServiceTerm;
