import React, {
  ComponentRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { Modal } from 'shared/presentation/components/atoms';
import { Button } from 'shared/presentation/components/molecules';
import { useLocale } from 'shared/presentation/contexts';
import { useInteractionTimeout } from 'shared/presentation/hooks';

import banner from './assets/banner.png';
import * as S from './styles';

const TIMEOUT = 45;

type TCustomer = { document?: string; id?: number };

interface IBannerModalRef {
  open(customer: TCustomer): void;
}

interface IBannerModalProps {
  onContinue(customer: TCustomer): void;
}

const BannerModal: React.ForwardRefRenderFunction<
  IBannerModalRef,
  IBannerModalProps
> = ({ onContinue }, ref) => {
  const [customer, setCustomer] = useState<TCustomer | null>(null);
  const { t } = useLocale('translations');

  const modalRef = useRef<ComponentRef<typeof Modal>>(null);

  const timer = useInteractionTimeout({
    timeout: TIMEOUT,
    onTimeUp: useCallback(() => modalRef.current?.close(), []),
  });

  useImperativeHandle(
    ref,
    () => ({
      open: customer => {
        setCustomer(customer);
        modalRef.current?.open();
        timer.reset();
      },
    }),
    [timer],
  );

  if (!customer) return null;

  return (
    <Modal ref={modalRef} initialOpen onClose={() => setCustomer(null)}>
      <S.Container>
        <img src={banner} alt="" />

        <footer>
          <Button
            variant="primary"
            onClick={() => {
              if (!customer) return;

              onContinue(customer);
              modalRef.current?.close();
            }}
          >
            <span>{t('pages.checkin.procced_with_checkin')}</span>
          </Button>
        </footer>
      </S.Container>
    </Modal>
  );
};

export default forwardRef(BannerModal);
