import { ICheckinCustomerDTO } from 'shared/domain/dtos';
import { ICheckinRepository } from 'shared/domain/repositories';

export default class CheckinCustomerUseCase {
  constructor(private checkinRepository: ICheckinRepository) {}

  public async execute({ unitId, customer }: ICheckinCustomerDTO) {
    return this.checkinRepository.create({ unitId, customer });
  }
}
