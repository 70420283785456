import { FC } from 'react';

import { useLocale } from 'shared/presentation/contexts';

import * as S from './styles';
import theme from '../../styles/theme';
import Button from '../button';

type TCheckCardProps = {
  icon?: React.ReactNode;
  label?: string;
  dataCy: string;
  selected: boolean;
  toggle: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const CheckCard: FC<TCheckCardProps> = ({
  icon,
  label,
  dataCy,
  selected,
  toggle,
}) => {
  const { t } = useLocale('translations');

  return (
    <S.Card
      selected={selected}
      onClick={toggle}
      data-cy={`periodCard-${dataCy}`}
    >
      {icon && (
        <S.Icon
          backgroundcolor={selected ? theme.colors.white : theme.colors.primary}
          color={selected ? theme.colors.secondary : theme.colors.white}
        >
          {icon}
        </S.Icon>
      )}
      <S.Label color={selected ? theme.colors.white : theme.colors.primary}>
        {label}
      </S.Label>
      <Button
        /* tslint:disable-next-line:no-empty */
        color={selected ? theme.colors.secondary : theme.colors.primary}
        background={theme.colors.white}
        bordercolor={selected ? theme.colors.secondary : theme.colors.primary}
      >
        <span>
          {selected ? t('common.states.selected') : t('common.actions.select')}
        </span>
      </Button>
    </S.Card>
  );
};

export default CheckCard;
