import { useState } from 'react';

import {
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import LocalizedCurrencyFormat from 'components/localizedCurrencyFormat';
import { useContracts } from 'context/ContractsContext';

import assinarContratosNormal from 'assets/svgs/assinar-contratos-normal.svg';
import assinarContratos from 'assets/svgs/assinar-contratos.svg';
import { useLocale } from 'shared/presentation/contexts';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '3rem',
    padding: '0 2rem',
  },
  title: {
    color: '#5e5e5e',
    fontWeight: 500,
    borderBottom: '1px solid #eae9eb',
    width: '100%',
    marginBottom: '2.6rem',
  },
  item: {
    background: `#fdfdfd`,
    borderRadius: '50px',
    color: '#626262',
    marginBottom: '1.6rem',
    boxShadow: '0px 2px 10px -2px rgba(0,0,0,0.18);',
  },
  itemSelected: {
    background: `#fdfdfd`,
    borderRadius: '50px',
    color: '#626262',
    marginBottom: '1.6rem',
    boxShadow: '0px 8px 10px -2px rgba(0,0,0,0.28);',
  },
  icon: {
    background: `url(${assinarContratos}) no-repeat`,
    marginRight: '0.6rem',
    borderRadius: '50%',
  },
  iconSelected: {
    background: `url(${assinarContratos}) no-repeat`,
    marginRight: '0.6rem',
    borderRadius: '50%',
  },
  values: {
    fontWeight: 600,
    color: '#000',
  },
  total: {
    background: theme.colors.primary,
    fontWeight: 600,
    borderRadius: '50px',
    color: '#fff',
    padding: '1rem',
  },
  helpText: {
    fontWeight: 600,
    marginTop: '1.4rem',
  },
}));

interface IContractSelectionListProps {
  clientId: number;
  onSelect: (contractId: number) => void;
}

const ContractSelectionList: React.FC<IContractSelectionListProps> = ({
  onSelect,
}) => {
  const { t } = useLocale('translations');
  const {
    userName,
    userContracts: contracts,
    setSelectedContract,
    loading,
  } = useContracts();

  const classes = useStyles();

  const [selectedC, setSelectedC] = useState(false);

  if (loading) {
    return (
      <Grid
        className={classes.root}
        container
        justify="center"
        alignItems="center"
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <>
      <Grid
        className={classes.root}
        style={{ overflow: 'auto' }}
        container
        justify="center"
        alignItems="center"
      >
        {contracts.length === 0 ? (
          <Grid
            className={classes.root}
            container
            justify="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <Typography className={classes.title} variant="h1" gutterBottom>
                {t('pages.contracts.toSign.noContracts', {
                  name: userName,
                })}
              </Typography>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <Typography className={classes.title} variant="h1" gutterBottom>
                {t('pages.contracts.toSign.header', { name: userName })}
              </Typography>
            </Grid>
            {contracts &&
              contracts.map((contract: any) => (
                <Grid
                  onClick={() => {
                    onSelect(contract.id);
                    setSelectedContract(contract.id);
                    setSelectedC(contract.id);
                  }}
                  className={
                    selectedC === contract.id
                      ? classes.itemSelected
                      : classes.item
                  }
                  container
                  spacing={2}
                  item
                  xs={11}
                  key={contract.id + ''}
                  aria-label="contract-item"
                >
                  <Grid item container alignItems="center" xs={2}>
                    <img
                      className={classes.iconSelected}
                      src={
                        selectedC === contract.id
                          ? assinarContratos
                          : assinarContratosNormal
                      }
                      alt="Icone assinar contrato"
                    />

                    <Typography variant="body1">
                      {t('pages.contracts.toSign.contract')}
                      <span className={classes.values}>{contract.codigo}</span>
                    </Typography>
                  </Grid>
                  <Grid item container alignItems="center" xs={2}>
                    <Typography variant="body1">
                      {t('pages.contracts.toSign.date')}
                      <span className={classes.values}>
                        {contract.dataCompra}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid item container alignItems="center" xs={6}>
                    <Typography variant="body1">
                      {t('pages.contracts.toSign.regions')}
                      <span className={classes.values}>
                        {contract.descricaoContrato}
                      </span>
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    alignItems="center"
                    justify="flex-end"
                    xs={2}
                  >
                    <Typography variant="body1">
                      {t('pages.contracts.toSign.value')}
                      <span className={classes.total}>
                        <LocalizedCurrencyFormat value={contract.valorTotal} />
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
              ))}
          </>
        )}

        {!contracts ? (
          ''
        ) : (
          <Grid item xs={11}>
            <Typography
              className={classes.helpText}
              variant="h4"
              color="primary"
            >
              {t('pages.contracts.toSign.helpText')}
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ContractSelectionList;
