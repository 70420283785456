import { styled } from '@material-ui/core';

export const Modal = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.25)',
  zIndex: 9999,
});

export const Box = styled('div')(({ theme }) => ({
  backgroundColor: theme.colors.white,
  borderRadius: 30,
  width: '520px',
  boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.25)',
}));

export const Center = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '& span.weekday': {
    marginBottom: 8,
    fontWeight: 500,
    textTransform: 'uppercase',
  },
});

export const RowSchedules = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

export const SelectText = styled('div')(({ theme, selected }: any) => ({
  fontSize: '12px',
  paddingBottom: 8,
  color: selected ? theme.colors.white : '#4f4f4f',
}));

export const Header = styled('div')(({ theme }) => ({
  padding: 5,
  fontSize: '24px',
  color: theme.palette.primary.main,
}));

export const SubTitle = styled('div')(() => ({
  padding: 5,
  fontSize: '18px',
  color: '#4F4F4F',
}));

export const Date = styled('div')(() => ({
  fontSize: '36px',
  fontWeight: 700,
  'p:first-letter': {
    textTransform: 'capitalize',
  },

  '&:first-child': {
    marginRight: 8,
  },
}));

export const Divider = styled('div')(() => ({
  borderLeft: '5px solid',
  height: '36px',
  marginLeft: 4,
  marginRight: 4,
  marginTop: 8,
  left: '50%',
  top: 0,
}));

export const HDivider = styled('div')(({ theme, selected }: any) => ({
  borderTop: `1px solid ${
    selected ? theme.colors.white : theme.palette.primary.main
  }`,
  width: '116px',
}));

export const Hour = styled('div')(() => ({
  fontSize: '36px',
  fontWeight: 400,
}));

export const Content = styled('div')({
  display: 'flex',
  padding: '16px',
  '& p': {
    color: '#424242',
    padding: '0  16px',
  },
  alignItems: 'center',
  justifyContent: 'center',
});

export const Schedule = styled('div')({
  display: 'flex',
});

export const List = styled('div')({
  marginTop: 24,
  padding: '12px 24px',
  maxHeight: 278,
  overflowY: 'scroll',
  borderTop: '1px solid #e8e8e8',
  borderBottom: '1px solid #e8e8e8',

  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

export const ItemList = styled('div')(({ theme, selected }: any) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  width: '149px',
  paddingTop: 0,
  marginTop: 16,
  marginRight: 12,
  marginBottom: 24,
  borderRadius: 20,
  backgroundColor: !selected ? theme.colors.white : theme.palette.primary.main,
  color: selected ? theme.colors.white : theme.palette.primary.main,
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  transition: '0.3s ease all',
  '&:last-child': {
    marginBottom: 0,
  },
}));

export const Footer = styled('div')({
  padding: 16,
  display: 'flex',
  marginTop: 30,
  justifyContent: 'space-between',
});

export const Button = styled('button')(({ theme, type }: any) => ({
  width: 239,
  height: 50,
  padding: '12px 0px',
  border: `1px solid ${theme.palette.primary.main}`,
  backgroundColor: type === 'default' ? theme.colors.primary : 'transparent',
  borderRadius: 30,
  color: type === 'default' ? '#fff' : theme.palette.primary.main,
  fontSize: 16,
  fontWeight: 500,
  outline: 'none',
  transition: '0.3s ease all',

  '&:hover': {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor:
      type === 'default' ? theme.palette.primary.dark : 'transparent',
    color: type === 'default' ? '#fff' : theme.palette.primary.dark,
  },

  '&:disabled': {
    color: type === 'default' ? '#fff' : '#424242',
    cursor: 'default',
  },
}));
