import { createContext, useContext } from 'react';

import { isEmpty } from 'lodash';

import { ICustomer } from 'shared/domain/entities';

interface IActiveCustomerContextData {
  customer: ICustomer | null;
  setCustomer: (customer: ICustomer | null) => void;
}

export const ActiveCustomerContext = createContext<IActiveCustomerContextData>(
  {} as IActiveCustomerContextData,
);

export const useActiveCustomer = () => {
  const context = useContext(ActiveCustomerContext);

  if (isEmpty(context)) {
    throw new Error(
      'useActiveCustomer should be called within a ActiveCustomerContext',
    );
  }

  return context;
};
