import { useEffect, useRef } from 'react';

import CanvasDraw, { CanvasDrawProps } from 'react-canvas-draw';

import { CanvasIcon, CanvasWrapper, Container } from './styles';

const canvasConfig: CanvasDrawProps = {
  hideGrid: true,
  hideInterface: true,
  brushRadius: 1,
  lazyRadius: 10,
  backgroundColor: 'transparent',
  canvasWidth: '100%',
  canvasHeight: '100%',
};

interface ISigningProps {
  clearRef: (clearFunc: () => void) => void;
  canvasRef: (canvas: () => HTMLCanvasElement) => void;
}

const Signing: React.FC<ISigningProps> = ({
  clearRef,
  canvasRef: canvasRefCallback,
}) => {
  const canvasRef = useRef<any>(undefined);

  useEffect(() => {
    clearRef(() => canvasRef?.current.clear);
    canvasRefCallback(canvasRef?.current.canvasContainer.children[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasRef]);

  return (
    <Container>
      <CanvasWrapper>
        <CanvasDraw ref={canvasRef} {...canvasConfig} />
        <CanvasIcon />
      </CanvasWrapper>
    </Container>
  );
};

export default Signing;
