export const sunExposure = {
  title: '¡Check-in realizado con éxito!',
  texts: {
    thanks: 'Gracias por responder!',
    exposure:
      'La <1>exposición reciente</1> de la piel al sol estimula la producción de melanina, lo que aumenta el <3>riesgo de reacciones</3> no deseadas durante las sesiones de depilación láser.',
    info: 'En la sala de aplicación, por favor informe a la aplicadora las regiones en las que ha tomado sol.',
  },
  button: 'Entendí',
};
