import Button from 'components/button';
import Modal from 'components/modal';
import theme from 'styles/theme';
import { TSchedule } from 'types/api';

import SadLisa from 'assets/videos/lisa_sad.webm';
import { useLocale } from 'shared/presentation/contexts';

import { ModalWrapper, TitleModal } from './styles';

interface IModalCancelScheduleProps {
  openModal: boolean;
  closeModal(): void;
  schedule: TSchedule;
  onCancel: (id: number) => void;
}

const ModalCancelSchedule = ({
  openModal,
  closeModal,
  schedule,
  onCancel,
}: IModalCancelScheduleProps) => {
  const { t } = useLocale('translations');

  return (
    <Modal open={openModal} onClose={closeModal}>
      <>
        <ModalWrapper>
          <video src={SadLisa} height="100" width="100" autoPlay loop muted />
          <TitleModal>{t('pages.schedules.modal.cancelSchedule')}</TitleModal>
          <Button
            color={theme.colors.white}
            background={theme.colors.red}
            onClick={() => onCancel(schedule.id)}
          >
            {t('pages.schedules.cancel')}
          </Button>
        </ModalWrapper>
      </>
    </Modal>
  );
};

export default ModalCancelSchedule;
