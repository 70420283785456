import { useCallback, useEffect, useState } from 'react';

const ONE_SECOND_MS = 1000;

export default function useCountdown(seconds: number) {
  const [timeLeft, setTimeLeft] = useState(seconds);

  useEffect(() => {
    const timeout = setInterval(
      () => setTimeLeft(timeLeft => Math.max(0, timeLeft - 1)),
      ONE_SECOND_MS,
    );

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const reset = useCallback(() => setTimeLeft(seconds), [seconds]);

  return {
    timeLeft,
    reset,
  };
}
