import { styled } from '@material-ui/core/styles';

type TTitleProps = {
  color: string;
};
export const Title = styled('h1')(({ color }: TTitleProps) => ({
  color,
  marginBottom: '.5rem',
}));

export const Subtitle = styled('h3')({
  marginBottom: '1.5rem',
});

export const Content = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
