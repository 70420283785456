import { styled } from '@material-ui/core/styles';

export const ContainerSessionDuration = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  background: theme.colors.white,
  color: theme.colors.gray,
  borderRadius: '50px',
  padding: 24,
  '& span': {
    fontSize: 18,
    '& strong': {
      color: theme.palette.text.primary,
      fontWeight: theme.font.bold,
    },
  },
  '& span:first-child': {
    paddingRight: 32,
  },
}));
