import React from 'react';

import { UserIdentification } from 'shared/presentation/components/templates';
import { Redirect, useActiveCustomer } from 'shared/presentation/contexts';

const MySchedules: React.FC = () => {
  const { customer } = useActiveCustomer();

  if (!customer) {
    return <UserIdentification />;
  }

  return <Redirect to="/user-schedules" />;
};

export default MySchedules;
