import { useEffect } from 'react';

import useCountdown from './useCountdown';

type TParams = {
  timeout: number;
  onTimeUp?: () => void;
};

export default function useTimeout({ timeout, onTimeUp }: TParams) {
  const timer = useCountdown(timeout);

  useEffect(() => {
    if (!onTimeUp) return;

    if (timer.timeLeft > 0) return;

    onTimeUp();
  }, [timer.timeLeft, onTimeUp]);

  return timer;
}
