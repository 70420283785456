import styled, { FlattenSimpleInterpolation, css } from 'styled-components';

export type TAlignment = 'top' | 'center' | 'bottom' | 'left' | 'right';

const ALIGN_STYLES: Record<TAlignment, FlattenSimpleInterpolation> = {
  top: css`
    align-items: flex-start;
    justify-content: center;
  `,
  center: css`
    align-items: center;
    justify-content: center;
  `,
  bottom: css`
    align-items: flex-end;
    justify-content: center;
  `,
  left: css`
    align-items: center;
    justify-content: flex-start;
  `,
  right: css`
    align-items: center;
    justify-content: flex-end;
  `,
};

interface IVisibleProps {
  visible: boolean;
  align: TAlignment;
}

export const Overlay = styled.div<IVisibleProps>`
  ${({ visible, align }) => css`
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.25);
    transition: 0.3s;
    opacity: 0;
    z-index: -1;
    visibility: hidden;

    bottom: 0;
    top: 0;
    left: 0;
    right: 0;

    ${visible &&
    css`
      opacity: 1;
      z-index: 7000;
      visibility: visible;
    `};

    display: flex;
    ${ALIGN_STYLES[align]}

    .modal-dialog {
      display: flex;
      align-items: center;
      flex-direction: center;
    }
  `}
`;
