import { useState, useEffect } from 'react';

import * as S from './styles';
import Signing from '../Signing';

interface ISignProps {
  clearRef: (clearCallback: () => void) => void;
  signatureCanvasRef: (canvas: HTMLCanvasElement) => void;
}

const Sign: React.FC<ISignProps> = ({ clearRef, signatureCanvasRef }) => {
  const [canvas, setCanvasRef] = useState<HTMLCanvasElement>();

  // boa sorte se rolar um bug por aqui (techlead aprooves)
  useEffect(() => {
    if (canvas) {
      signatureCanvasRef(canvas);
    }
  }, [canvas, signatureCanvasRef]);

  return (
    <S.Container>
      <Signing clearRef={clearRef} canvasRef={setCanvasRef} />
    </S.Container>
  );
};

export default Sign;
