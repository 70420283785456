import { styled, Theme } from '@material-ui/core/styles';

type TTitleProps = {
  theme: Theme;
  color?: string;
};

export const ModalWrapper = styled('div')({
  textAlign: 'center',
  '& img': {
    margin: '1rem',
  },
});

export const TitleModal = styled('h1')(({ theme, color }: TTitleProps) => ({
  fontSize: theme.font.sizes.small,
  color: color || theme.colors.error,
  paddingTop: 24,
}));

export const SubTitleModal = styled('div')(({ theme }) => ({
  fontSize: theme.font.sizes.small,
  color: theme.colors.gray,
  padding: '1rem',
}));

export const Controls = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  '& button': {
    margin: '1rem 0',
    border: `1px solid ${theme.colors.primary}`,
  },
}));
