import ITotemInfoPubsub from 'shared/domain/pubsubs/ITotemInfoPubsub';
import { makeWebSocketProvider } from 'shared/infra/providers';

import LegacyTotemInfoPubsub from './implementations/LegacyTotemInfoPubsub';

const PUBSUBS = {
  legacy: LegacyTotemInfoPubsub,
} as const;

type TType = keyof typeof PUBSUBS;

const instances = new Map<TType, ITotemInfoPubsub>();

export default function makeTotemInfoPubsub({
  type = 'legacy',
}: { type?: TType } = {}) {
  if (!instances.has(type)) {
    const provider = makeWebSocketProvider();

    const Implementation = PUBSUBS[type];

    instances.set(type, new Implementation(provider));
  }

  return instances.get(type) as ITotemInfoPubsub;
}
