import IconButton from '@material-ui/core/IconButton';
import { styled } from '@material-ui/core/styles';

export const Container = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr auto',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexGrow: 1,
  marginBottom: '1rem',
}));

export const CarouselButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: theme.colors.white,
  '&:hover, &:active': {
    backgroundColor: theme.colors.white,
  },
}));

export const CarouselWrapper = styled('div')(() => ({
  flexGrow: 1,
  padding: '1rem',
  '& .slider-frame': {
    minHeight: '470px',
    '& .slider-list': {
      '& .slider-slide': {
        minHeight: '470px',
      },
    },
  },
}));
