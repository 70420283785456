import React, {
  ComponentRef,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';

import { TLocale } from 'shared/domain/valueObjects';
import { Modal, VirtualKeyboard } from 'shared/presentation/components/atoms';
import { Button } from 'shared/presentation/components/molecules';
import { ICONS, MaskFormatter, MASKS } from 'shared/presentation/constants';
import { useLocale } from 'shared/presentation/contexts';

import { isCIDIMEXValid, isCIValid, isCPFValid } from './helpers';
import * as S from './styles';

const DOCUMENT_VALIDATORS: Record<TLocale, (document: string) => boolean> = {
  pt: isCPFValid,
  es: isCIValid,
  'es-CR': isCIDIMEXValid,
};

interface IDocumentTypingPadRef {
  open(): void;
  close(): void;
}

interface IDocumentTypingPadProps {
  loading: boolean;
  requestError?: string;
  onSubmit(document: string): Promise<unknown>;
}

const DocumentTypingPad: React.ForwardRefRenderFunction<
  IDocumentTypingPadRef,
  IDocumentTypingPadProps
> = ({ loading, onSubmit, requestError }, ref) => {
  const modalRef = useRef<ComponentRef<typeof Modal>>(null);

  const { t, language } = useLocale('shared');
  const [document, setDocument] = useState('');
  const [validationError, setValidationError] = useState('');

  const documentMask = useMemo(
    () => new MaskFormatter(MASKS.DOCUMENT[language]),
    [language],
  );

  useImperativeHandle(
    ref,
    () => ({
      open: () => modalRef.current?.open(),
      close: () => modalRef.current?.close(),
    }),
    [],
  );

  const handleAddDigit = (digit: string) =>
    setDocument(document => {
      const newDocument = document + digit;

      if (!documentMask.isInputMaskable(newDocument)) return document;

      return newDocument;
    });

  const handleBackspacePress = () => {
    setDocument(document => document.substring(0, document.length - 1));
    setValidationError('');
  };

  const handleClearPress = () => {
    setDocument('');
    setValidationError('');
  };

  const handleSubmit = () => {
    if (!documentMask.isValid(document)) {
      return setValidationError(
        t('components.documentTypingPad.validation_error'),
      );
    }

    const isDocumentValid = DOCUMENT_VALIDATORS[language];

    if (!isDocumentValid(document)) {
      return setValidationError(
        t('components.documentTypingPad.validation_error'),
      );
    }

    setValidationError('');
    onSubmit(document);
  };

  const error = validationError || requestError;
  const isConfirmDisabled =
    !!validationError || !documentMask.isValid(document);

  return (
    <Modal ref={modalRef}>
      <S.Container>
        <div className="texts">
          <span data-filled={!!document}>
            {document
              ? documentMask.format(document)
              : t('components.documentTypingPad.placeholder')}
          </span>

          {!!error && <small>{error}</small>}
        </div>

        <VirtualKeyboard
          onKeyPress={handleAddDigit}
          layout="PHONE"
          onBackspacePress={handleBackspacePress}
          onClearPress={handleClearPress}
          displayOverrides={{
            '{clear}': '×',
            '{bksp}': '«',
          }}
        />

        <footer>
          <Button
            variant="primary"
            disabled={isConfirmDisabled}
            onClick={handleSubmit}
            loading={loading}
          >
            {t('components.documentTypingPad.confirm')}
            <Button.Icon icon={ICONS.DOUBLE_CHECK} />
          </Button>

          <Button variant="default" onClick={() => modalRef.current?.close()}>
            {t('components.documentTypingPad.cancel')}
            <Button.Icon icon={ICONS.X} />
          </Button>
        </footer>
      </S.Container>
    </Modal>
  );
};

export default forwardRef(DocumentTypingPad);
