import IWebSocketProvider from 'shared/domain/providers/IWebSocketProvider';

import IOWebSocketProvider from './implementations/IOWebSocketProvider';

let instance: IWebSocketProvider | null = null;

const url = process.env.REACT_APP_WEBSOCKET_URL;

export default function makeWebSocketProvider(): IWebSocketProvider {
  if (!instance) {
    instance = new IOWebSocketProvider(url || '');
  }

  return instance;
}
