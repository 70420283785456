import React, {
  ComponentRef,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import { Modal } from 'shared/presentation/components/atoms';
import { Button } from 'shared/presentation/components/molecules';
import { TIconType } from 'shared/presentation/constants';
import { useLocale } from 'shared/presentation/contexts';
import { useTimeout } from 'shared/presentation/hooks';

import * as S from './styles';

interface IAlertRef {
  open(): void;
  close(): void;
}

interface IAlertProps {
  title: string;
  message: string;
  icon: TIconType;
}

const DEFAULT_TIMEOUT = 15;

const Alert: React.ForwardRefRenderFunction<IAlertRef, IAlertProps> = (
  { title, message, icon: Icon },
  ref,
) => {
  const modalRef = useRef<ComponentRef<typeof Modal>>(null);
  const { t } = useLocale('shared');

  const timer = useTimeout({
    timeout: DEFAULT_TIMEOUT,
    onTimeUp: () => modalRef.current?.close(),
  });

  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        timer.reset();
        modalRef.current?.open();
      },
      close: () => modalRef.current?.close(),
    }),
    [timer],
  );

  return (
    <Modal ref={modalRef}>
      <S.Container>
        <Icon />

        <div>
          <h2>{title}</h2>

          <p>{message}</p>
        </div>

        <Button variant="default" onClick={() => modalRef.current?.close()}>
          {t('components.alert.close')}
        </Button>
      </S.Container>
    </Modal>
  );
};

export default forwardRef(Alert);
