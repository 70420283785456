import React, {
  ComponentRef,
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import ControlledCheckInput from 'pages/Checkin/components/ControlledCheckInput';

import { Modal } from 'shared/presentation/components/atoms';
import { Button } from 'shared/presentation/components/molecules';
import { ICONS } from 'shared/presentation/constants';
import { useLocale } from 'shared/presentation/contexts';
import { useInteractionTimeout } from 'shared/presentation/hooks';

import { usePersistAnswer } from './hooks';
import * as S from './styles';

const TIMEOUT = 30;

interface IIncidencesModalRef {
  open(): void;
}

interface IIncidencesModalProps {
  items: Array<{
    id: number;
    title: string;
    image: string;
    incidencesId: number[];
  }>;
  customer: { id: number; name: string };
  onSuccess(customer: { id: number }): void;
}

type TAreasStateItem = {
  selected: boolean;
  incidences: number[];
  id: number;
  name: string;
};

const IncidencesModal: ForwardRefRenderFunction<
  IIncidencesModalRef,
  IIncidencesModalProps
> = ({ items, customer, onSuccess }, ref) => {
  const modalRef = useRef<ComponentRef<typeof Modal>>(null);

  const [selectedAreas, setSelectedAreas] = useState<
    Record<string, TAreasStateItem>
  >({});
  const [confirm, setConfirm] = useState(false);

  const timer = useInteractionTimeout({
    timeout: TIMEOUT,
    onTimeUp: useCallback(() => modalRef.current?.close(), []),
  });

  const handleDeselectAllAreas = useCallback(
    () =>
      setSelectedAreas(
        items.reduce(
          (acc, actual) => ({
            ...acc,
            [String(actual.id)]: {
              selected: false,
              incidences: actual.incidencesId,
              id: actual.id,
              name: actual.title,
            },
          }),
          {} as Record<string, TAreasStateItem>,
        ),
      ),
    [items],
  );

  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        modalRef.current?.open();
        timer.reset();
        handleDeselectAllAreas();
      },
    }),
    [timer, handleDeselectAllAreas],
  );

  const controller = usePersistAnswer();
  const { t } = useLocale('translations');

  const handleSelectAllAreas = () =>
    setSelectedAreas(
      items.reduce((acc, actual) => {
        return {
          ...acc,
          [String(actual.id)]: {
            incidences: actual.incidencesId,
            id: actual.id,
            name: actual.title,
            selected: true,
          },
        };
      }, {} as Record<string, TAreasStateItem>),
    );

  const handleSelectArea = (id: number) =>
    setSelectedAreas(currentAreas => {
      const key = String(id);
      const currentArea = currentAreas[key];

      return {
        ...currentAreas,
        [key]: {
          ...currentArea,
          selected: !currentArea.selected,
        },
      };
    });

  const handleSendAnswer = () =>
    controller.send(
      { areas: selectedAreas },
      {
        onSuccess: () => {
          onSuccess(customer);
          modalRef.current?.close();
        },
      },
    );

  const selectedIds = Object.entries(selectedAreas)
    .filter(([, value]) => value.selected)
    .map(([key]) => Number(key));
  const hasSelection = !!selectedIds.length;
  const isConfirmDisabled = hasSelection && !confirm;

  const areAllItemsSelected = selectedIds.length === items.length;
  const { ButtonIcon, selectButtonText, onSelectButtonClick } =
    areAllItemsSelected
      ? {
          ButtonIcon: ICONS.X,
          selectButtonText: t('pages.checkin.incidences.unselect_all'),
          onSelectButtonClick: handleDeselectAllAreas,
        }
      : {
          ButtonIcon: ICONS.DOUBLE_CHECK,
          selectButtonText: t('pages.checkin.incidences.select_all'),
          onSelectButtonClick: handleSelectAllAreas,
        };

  return (
    <Modal
      ref={modalRef}
      initialOpen
      onClose={() => {
        setSelectedAreas({});
        setConfirm(false);
      }}
    >
      <S.Container>
        <header>
          <h1>{t('pages.checkin.incidences.title')}</h1>
        </header>

        <p>{t('pages.checkin.incidences.description')}</p>

        <ul>
          <li>
            <S.SelectAllButton onClick={onSelectButtonClick}>
              <ButtonIcon />
            </S.SelectAllButton>

            <span>{selectButtonText}</span>
          </li>

          {items.map(area => {
            const isSelected = selectedAreas[String(area.id)]?.selected;

            return (
              <li key={area.id} data-selected={isSelected}>
                <S.AreaButton
                  data-selected={isSelected}
                  onClick={() => handleSelectArea(area.id)}
                >
                  <img src={area.image} alt={area.title} />
                </S.AreaButton>

                <span>{area.title}</span>
              </li>
            );
          })}
        </ul>

        <caption>{t('pages.checkin.incidences.caption')}</caption>

        {!!controller.error && (
          <caption className="error">{controller.error}</caption>
        )}

        {hasSelection && (
          <ControlledCheckInput
            id="confirm"
            value={confirm}
            onChange={setConfirm}
          >
            <span>
              {t('pages.checkin.incidences.terms', {
                name: customer.name,
                areas: items
                  .filter(item => selectedIds.includes(item.id))
                  .map(item => item.title)
                  .join(', '),
                count: selectedIds.length,
              })}
            </span>
          </ControlledCheckInput>
        )}

        <Button
          variant="primary"
          disabled={isConfirmDisabled}
          onClick={handleSendAnswer}
          loading={controller.loading}
        >
          {t('pages.checkin.incidences.action')}
        </Button>
      </S.Container>
    </Modal>
  );
};

export default forwardRef(IncidencesModal);
