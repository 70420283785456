import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { makeHTTPProvider } from 'shared/infra/providers';

import Contrato from '../domain/Contrato';
import Pagination from '../domain/Pagination';
import Logger from '../utils/Logger';

type TContractsState = {
  userContracts: Contrato[];
  selectedContract: Contrato | undefined;
  termsAccepted: boolean;
  signature: string | null;
  currentStep: number;
  loading: boolean;
};

type TContractsContextData = TContractsState & {
  userId: number;
  userName: string;
  hasCheckin: boolean;
};

type TContractsContextActions = {
  setTermsAccepted: (accepted: boolean) => void;
  setSignature: (signature: string | null) => void;
  setSelectedContract: (id: number) => void;
  onStepChange: (step: number) => void;
  signContract: (signature: string) => Promise<boolean>;
  getUserContracts(): void;
};

const ContractsContext = createContext<
  TContractsContextData & TContractsContextActions
>({} as TContractsContextData & TContractsContextActions);

interface IContractsContextProviderProps {
  userId: number;
  userName: string;
  children: React.ReactNode;
  value?: object;
  hasCheckin: boolean;
}

const ContractsContextProvider: React.FC<IContractsContextProviderProps> = ({
  userId,
  userName,
  children,
  hasCheckin,
}) => {
  const [state, setState] = useState<TContractsState>({
    userContracts: [],
    selectedContract: undefined,
    termsAccepted: false,
    signature: null,
    currentStep: 0,
    loading: false,
  });

  const getUserContracts = useCallback(async () => {
    setState(previusState => ({ ...previusState, loading: true }));

    const response = await makeHTTPProvider().get<Pagination<Contrato>>(
      `/area-cliente/financeiro/${userId}/contratos?assinados=false`,
    );

    if (response.data) {
      setState(previusState => ({
        ...previusState,
        userContracts: response.data,
        loading: false,
      }));
    }

    setState(previusState => ({ ...previusState, loading: false }));
  }, [userId]);

  useEffect(() => {
    if (userId) getUserContracts();
  }, [userId, getUserContracts]);

  const setTermsAccepted = (accepted: boolean) => {
    setState(previusState => ({ ...previusState, termsAccepted: accepted }));
  };

  const setSignature = (signature: string | null) => {
    setState(previusState => ({ ...previusState, signature }));
  };

  const setSelectedContract = (id: number) => {
    const selectedContract = state.userContracts.find(
      contract => contract.id === id,
    );
    setState(previusState => ({ ...previusState, selectedContract }));
  };

  const onStepChange = (step: number) => {
    if (step === 1 && state.selectedContract === undefined) {
      return;
    }

    if (step === 2 && !state.termsAccepted) {
      return;
    }

    setState(previusState => ({ ...previusState, currentStep: step }));
  };

  const signContract = useCallback(
    async (signature: string): Promise<boolean> => {
      if (!state.selectedContract || !signature) return false;

      try {
        await makeHTTPProvider().post(
          `/area-cliente/financeiro/${userId}/contrato/${state.selectedContract.id}/assinar`,
          {
            assinatura: signature,
            origem: 'TOTEM',
          },
        );

        return true;
      } catch (error) {
        Logger.error(error);
        return false;
      }
    },
    [state.selectedContract, userId],
  );

  return (
    <ContractsContext.Provider
      value={{
        ...state,
        userId,
        userName,
        hasCheckin,
        setTermsAccepted,
        setSignature,
        setSelectedContract,
        onStepChange,
        signContract,
        getUserContracts,
      }}
    >
      {children}
    </ContractsContext.Provider>
  );
};

const useContracts = (): TContractsContextData & TContractsContextActions => {
  const context = useContext(ContractsContext);

  if (!context) {
    throw new Error('useCheckIn must be used within an CheckInProvider');
  }

  return context;
};

export { ContractsContextProvider, useContracts };
