import ItemContrato, { itemContratoImageResolver } from 'domain/ItemContrato';
import Pacote from 'domain/Pacote';
import TipoItem from 'domain/TipoItem';

import { createContext, useContext, useEffect, useState } from 'react';

import { IRegionCardData } from 'components/Scheduling/ScheduleRegions/RegionSelector/RegionCard';
import { handleError } from 'helpers/handleError';
import moment, { Duration, duration, Moment } from 'moment';
import Tracker from 'services/tracker';
import { HOUR_12, HOUR_19 } from 'utils/constants';
import { formatDate } from 'utils/functions';

import { makeHTTPProvider } from 'shared/infra/providers';
import {
  useActiveCustomer,
  useRouteQuery,
  useUnit,
} from 'shared/presentation/contexts';

type TScheduleItem = IRegionCardData;

const ItemContratoToScheduleItem = (item: ItemContrato): TScheduleItem => {
  const packt: Pacote =
    item.tipoItem === TipoItem.PACOTE ? item.pacote : item.produto;
  return {
    id: item.id,
    description: packt.descricao,
    image: itemContratoImageResolver(packt),
    nextSession: `${item.quantidadeSessoeRealizadas}/${item.quantidadeSessoes}`,
    nextSchedule: moment(item.dataMinimaParaAgendamento, 'DD/MM/YYYY'),
    sessionDuration: duration(packt.tempoAplicacaoSessao, 'minutes'),
    blockingScheduleMessage: item.msgBloqueioAgendamento,
  };
};

const findAreaByDescription = (
  availableAreas: TScheduleItem[],
  description: string,
) => {
  for (const area of availableAreas) {
    if (area.description === description) {
      return area;
    }
  }
};

const getSelectedAreas = (
  availableAreas: TScheduleItem[],
  items?: string[],
) => {
  let selectedAreas = {};

  if (!items) {
    return selectedAreas;
  }

  for (const element of items) {
    const area = findAreaByDescription(availableAreas, element);
    if (area) {
      selectedAreas = {
        ...selectedAreas,
        [area.id]: true,
      };
    }
  }

  return selectedAreas;
};

export interface IHourAvailabilityPerDayItem {
  total: number;
  byPeriods: { [key: string]: number };
}

interface IHourAvailability {
  data: string;
  dsData: string;
  horarios: string[];
  salas: { [key: string]: number };
}

export interface ISchedulingContextState {
  availableAreas: TScheduleItem[];
  hourAvailabilityPerDay: {
    [key: string]: IHourAvailabilityPerDayItem;
  };
  hourAvailability: IHourAvailability[];
  selectedAreas: { [key: number]: boolean };
  selectedDate: Moment | undefined;
  selectedHour: string | undefined;
  selectedDayPeriods: { [key: string]: boolean };
  minDate: Moment;
  sessionDuration: Duration;
  error: string;
  scheduleId?: number;
  loading: boolean;
}

interface ISchedulingContextActions {
  toggleArea: (area: number, selected: boolean) => void;
  setMinDate: (minDate: Moment) => void;
  setSelectedDate: (date: Moment) => void;
  setSelectedHour: (hour: string | undefined) => void;
  toggleDayPeriod: (period: string) => void;
  setSelectedMonth: (month: Moment) => void;
  newSchedule: (callback: (data: { urlIndicador: string }) => void) => void;
  setSelectedAreas: (areas: { [key: number]: boolean }) => void;
  setError: (error: string) => void;
}

interface ISchedulingProviderProps {
  clientId: number;
}

type TSchedulingContext = ISchedulingContextState &
  ISchedulingContextActions & { absentItems: ItemContrato[] };

const Context = createContext<TSchedulingContext>({} as TSchedulingContext);

const SchedulingContextProvider: React.FC<ISchedulingProviderProps> = ({
  clientId,
  children,
}) => {
  const [state, setState] = useState<ISchedulingContextState>(() => ({
    availableAreas: [],
    hourAvailabilityPerDay: {},
    hourAvailability: [],
    selectedAreas: {},
    selectedDate: undefined,
    selectedHour: undefined,
    selectedDayPeriods: {
      afternoon: true,
      morning: true,
      night: true,
    },
    minDate: moment(),
    sessionDuration: duration(0, 'minutes'),
    error: '',
    scheduleId: 0,
    loading: false,
  }));
  const { id, items } = useRouteQuery<'/schedules'>();
  const { unit } = useUnit();
  const { customer } = useActiveCustomer();
  const [selectedMonth, setSelectedMonth] = useState<Moment | undefined>();
  const [absentItems, setAbsentItems] = useState<ItemContrato[]>([]);

  useEffect(() => {
    if (!clientId || !unit) return;

    setState(s => ({ ...s, loading: true }));
    const scheduleId = id;

    makeHTTPProvider()
      .get<ItemContrato[]>('/itensContrato', {
        params: {
          id_cliente: clientId,
          id_unidade: unit.id,
        },
      })
      .catch(() => {
        return [];
      })
      .then((r: ItemContrato[]) => {
        const availableAreas = r.map(it => ItemContratoToScheduleItem(it));
        const selectedAreas = getSelectedAreas(
          availableAreas,
          items && JSON.parse(items),
        );

        setState(s => ({
          ...s,
          availableAreas,
          selectedAreas,
          scheduleId,
          loading: false,
        }));
      });
  }, [clientId, id, unit, items]);

  useEffect(() => {
    if (!clientId || !state.availableAreas.length) return;

    makeHTTPProvider()
      .get<ItemContrato[]>('/itensContrato', {
        params: {
          id_cliente: clientId,
        },
      })
      .then(itens => {
        const exclusiveItemsFromAllUnits: ItemContrato[] = itens.filter(
          item => item.exclusivoUnidade,
        );

        const exclusiveItemsAbsentFromThisUnit =
          exclusiveItemsFromAllUnits.filter(item => {
            const isItemAvailableInCurrentUnit = state.availableAreas.find(
              area => area.id === item.id,
            );

            return !isItemAvailableInCurrentUnit;
          });

        setAbsentItems(exclusiveItemsAbsentFromThisUnit);
      });
  }, [clientId, state.availableAreas]);

  useEffect(() => {
    if (!unit) return;

    const { minDate } = state.availableAreas
      .filter(it => state.selectedAreas[it.id])
      .reduce(
        (max, item) => ({
          minDate: max.minDate.isBefore(item.nextSchedule)
            ? item.nextSchedule
            : max.minDate,
          sessionDuration: max.sessionDuration.add(item.sessionDuration),
        }),
        {
          minDate: moment(),
          sessionDuration: duration(0, 'minutes'),
        },
      );

    const pcktsIds = Object.keys(state.selectedAreas)
      .map(p => p)
      .join('|');

    if (pcktsIds) {
      makeHTTPProvider()
        .get<number>(
          `/agendas/calcularDuracao?id_unidade=${unit.id}&id_itens_contratados=${pcktsIds}&idcliente=${clientId}`,
        )
        .then(response => {
          setState(s => ({
            ...s,
            minDate,
            sessionDuration: duration(response, 'minutes'),
          }));
        })
        .catch(() =>
          setState(s => ({
            ...s,
            minDate: moment(),
            sessionDuration: duration(0, 'minutes'),
          })),
        );
    } else {
      setState(s => ({
        ...s,
        minDate: moment(),
        sessionDuration: duration(0, 'minutes'),
      }));
    }
  }, [clientId, unit, state.availableAreas, state.selectedAreas]);

  useEffect(() => {
    const interval = state.sessionDuration.asMinutes();

    if (selectedMonth) {
      const today = moment();
      let dataInicio = '';
      let dataFim = '';

      const month = selectedMonth.month();
      const isMinDateInMonth = state.minDate && state.minDate.month() === month;

      if (month !== today.month()) {
        dataInicio = isMinDateInMonth
          ? state.minDate.format('DD/MM/YYYY')
          : selectedMonth.startOf('month').format('DD/MM/YYYY');
        dataFim = selectedMonth.endOf('month').format('DD/MM/YYYY');
      } else {
        dataInicio = state.minDate
          ? state.minDate.format('DD/MM/YYYY')
          : today.format('DD/MM/YYYY');

        state.minDate
          ? (dataFim = moment(state.minDate)
              .endOf('month')
              .format('DD/MM/YYYY'))
          : (dataFim = selectedMonth.endOf('month').format('DD/MM/YYYY'));
      }

      const query = Object.entries({
        id_unidade: unit?.id,
        intervalo_duracao: interval,
        data_inicio: dataInicio,
        data_fim: dataFim,
        hora_inicio: '00:00',
        hora_fim: '23:59',
      })
        .map(([k, v]) => `${k}=${v}`)
        .join('&');

      makeHTTPProvider()
        .get<IHourAvailability[]>(`/agendas/horariosDisponiveis?${query}`)
        .catch(() => [])
        .then(r =>
          setState(s => ({
            ...s,
            hourAvailability: r,
          })),
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth]);

  useEffect(() => {
    const separateByPeriod = (schedules: string[]) => {
      const ret: { [key: string]: number } = {
        morning: 0,
        afternoon: 0,
        night: 0,
      };
      schedules.forEach(schedule => {
        const momentSchedule = moment(schedule, 'hh:mm');

        if (momentSchedule.isAfter(HOUR_19)) {
          ret.night++;
          return;
        }
        if (momentSchedule.isAfter(HOUR_12)) {
          ret.afternoon++;
          return;
        }
        ret.morning++;
      });
      return ret;
    };

    const hoursPerDay: {
      [key: string]: IHourAvailabilityPerDayItem;
    } = state.hourAvailability.reduce((agg, curr) => {
      return {
        ...agg,
        [curr.data]: {
          total: curr.horarios.length,
          byPeriods: separateByPeriod(curr.horarios),
        },
      };
    }, {});

    setState(s => ({
      ...s,
      hourAvailabilityPerDay: hoursPerDay,
    }));
  }, [state.hourAvailability, state.minDate]);

  useEffect(() => {
    if (!state.selectedDate) {
      const today = moment();
      const date = state.minDate.isAfter(today) ? state.minDate : today;

      const dateToFormat = state.hourAvailabilityPerDay[
        date.format('YYYY-MM-DD')
      ]
        ? date
        : Object.keys(state.hourAvailabilityPerDay)[0];

      const selectedDate = moment(dateToFormat);

      setState(s => ({
        ...s,
        selectedDate,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.hourAvailabilityPerDay, state.minDate]);

  const toggleArea = (area: number, selected: boolean) => {
    if (state.scheduleId) {
      return undefined;
    }
    setState(s => {
      const { selectedAreas } = s;

      if (selected) {
        return {
          ...s,
          selectedAreas: {
            ...selectedAreas,
            [area]: selected,
          },
        };
      }

      Reflect.deleteProperty(selectedAreas, area);

      return {
        ...s,
        // Muda o objeto para disparar o effect que calcula a duração da sessão
        selectedAreas: { ...selectedAreas },
      };
    });
  };

  const setMinDate = (minDate: Moment) =>
    setState(s => ({
      ...s,
      minDate,
    }));

  const setSelectedDate = (selectedDate: Moment) => {
    setState(s => {
      // transparently avoids selecting a date lower than minDate.
      const newDate = s.minDate.isAfter(selectedDate)
        ? s.minDate
        : selectedDate;

      return {
        ...s,
        selectedDate: newDate,
      };
    });
  };

  const setSelectedHour = (hour: string | undefined) =>
    setState(s => ({
      ...s,
      selectedHour: hour,
    }));

  const toggleDayPeriod = (period: string) =>
    setState(s => ({
      ...s,
      selectedDayPeriods: {
        ...s.selectedDayPeriods,
        [period]: !s.selectedDayPeriods[period],
      },
    }));

  const newSchedule = (callback: (data: { urlIndicador: string }) => void) => {
    const {
      selectedAreas,
      selectedDate,
      selectedHour,
      sessionDuration,
      scheduleId,
    } = state;

    if (!selectedAreas || !selectedDate || !selectedHour || !customer) return;
    const items = Object.keys(selectedAreas).map(key => ({ id: key }));

    const url = scheduleId
      ? `agendas/${scheduleId}/reagendamento`
      : 'agendas/agendamento';
    let data: any = {
      dataHoraInicio: `${
        typeof selectedDate === 'string'
          ? formatDate(selectedDate)
          : selectedDate.format('DD/MM/YYYY')
      } ${selectedHour}`,
      origem: 'TOTEM',
      idUnidade: unit?.id,
    };

    if (!scheduleId) {
      data = {
        ...data,
        itensContrato: items,
        cpf: customer.document,
        email: customer.email,
        intervaloDuracao: sessionDuration.asMinutes(),
        agendaAvaliacao: false,
      };
    }

    makeHTTPProvider()
      .put<{ urlIndicador: string }>(url, data)
      .then(response => {
        if (callback) callback(response);
      })
      .catch(e => {
        const error = handleError(e);
        Tracker.trackAction(customer.id, 'agendamentoErroAoRegistrar');

        setState(s => ({
          ...s,
          error: error.message,
        }));

        setTimeout(() => {
          setState(s => ({
            ...s,
            error: '',
          }));
        }, 6000);
      });
  };

  const setSelectedAreas = (areas: { [key: number]: boolean }) =>
    setState(s => ({
      ...s,
      selectedAreas: areas,
    }));

  const setError = (error: string) =>
    setState(s => ({
      ...s,
      error,
    }));

  return (
    <Context.Provider
      value={{
        ...state,
        absentItems,
        toggleArea,
        setMinDate,
        setSelectedDate,
        setSelectedHour,
        toggleDayPeriod,
        setSelectedMonth,
        newSchedule,
        setSelectedAreas,
        setError,
      }}
    >
      {children}
    </Context.Provider>
  );
};

const useScheduling = (): TSchedulingContext => {
  const context = useContext(Context);

  if (!context) {
    throw new Error(
      'useScheduling must be used within a SchedulingContextProvider',
    );
  }

  return context;
};

export { SchedulingContextProvider, useScheduling };
