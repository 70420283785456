import Contrato from 'domain/Contrato';

import { CardContent, useTheme } from '@material-ui/core';
import LocalizedCurrencyFormat from 'components/localizedCurrencyFormat';

import HappyLisa from 'assets/videos/happyLisa.webm';
import { useLocale } from 'shared/presentation/contexts';

import * as S from './styles';

interface IThanksProps {
  name?: string;
  contract?: Contrato;
}

const Thanks: React.FC<IThanksProps> = ({ name, contract }) => {
  const theme = useTheme();
  const { t } = useLocale('translations');

  return (
    <S.Container>
      <S.divVideo>
        <S.Video src={HappyLisa} autoPlay loop muted />
      </S.divVideo>
      <S.Main>
        <S.ContainerThanks>
          <S.DivThank>
            <S.Title>{t('pages.contracts.thanks.title', { name })}</S.Title>
          </S.DivThank>
          <S.Card>
            <CardContent style={{ width: '500px' }}>
              <S.Schedules>
                <S.SchedulesItems>
                  <S.TitleBodyCardSession>
                    {t('pages.contracts.thanks.contract')}{' '}
                    <span
                      style={{
                        color: theme.palette.text.primary,
                        fontWeight: 'bolder',
                      }}
                    >
                      {contract?.codigo}
                    </span>
                  </S.TitleBodyCardSession>
                </S.SchedulesItems>
                <S.SchedulesItems>
                  <S.TitleBodyCardSession>
                    {t('pages.contracts.thanks.data')}{' '}
                    <span
                      style={{
                        color: theme.palette.text.primary,
                        fontWeight: 'bolder',
                      }}
                    >
                      {contract?.dataCompra}
                    </span>
                  </S.TitleBodyCardSession>
                </S.SchedulesItems>
              </S.Schedules>
              <S.Schedules>
                <S.SchedulesItems>
                  <S.TitleBodyCardSession>
                    {t('pages.contracts.thanks.region')}{' '}
                    <span
                      style={{
                        color: theme.palette.text.primary,
                        fontWeight: 'bolder',
                      }}
                    >
                      {contract?.descricaoContrato}
                    </span>
                  </S.TitleBodyCardSession>
                </S.SchedulesItems>
              </S.Schedules>

              <S.Schedules>
                <S.SchedulesItems>
                  <S.TitleBodyCardSession>
                    {t('pages.contracts.thanks.valorTotal')}{' '}
                    <span
                      style={{
                        color: '#fff',
                        padding: '10px',
                        fontWeight: 'bolder',
                        borderRadius: '20px',
                        backgroundColor: theme.colors.primary,
                      }}
                    >
                      <LocalizedCurrencyFormat
                        value={contract?.valorTotal || 0}
                      />
                    </span>
                  </S.TitleBodyCardSession>
                </S.SchedulesItems>
              </S.Schedules>
            </CardContent>
          </S.Card>
        </S.ContainerThanks>
      </S.Main>
    </S.Container>
  );
};

export default Thanks;
