import i18n from 'i18next';
import { esLocale } from 'locales/es';
import { ptLocale } from 'locales/pt';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';

import appModules from 'shared/presentation/modules';

import 'moment/locale/pt';
import 'moment/locale/es';

moment.locale('pt');

const DEFAULT_NAMESPACE = 'translations';

const appResources = appModules.locale;

/** @public */
export const resources = {
  pt: {
    ...appResources.pt,
    [DEFAULT_NAMESPACE]: ptLocale,
  },
  es: {
    ...appResources.es,
    [DEFAULT_NAMESPACE]: esLocale,
  },
  'es-CR': {
    ...appResources['es-CR'],
    [DEFAULT_NAMESPACE]: {},
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: {
    'es-CR': ['es'],
    default: ['pt'],
  },
  nsSeparator: ':',
  defaultNS: DEFAULT_NAMESPACE,
  interpolation: {
    escapeValue: false,
  },
});

i18n.changeLanguage('pt');
