import styled, { css } from 'styled-components';

export const Container = styled.main`
  ${({ theme }) => css`
    width: 100vw;
    height: 100vh;
    background-color: ${theme.palette.neutral[0]};

    ${theme.layout.rowGap(8)}
    padding: ${theme.layout.spacing(8)};

    color: ${theme.palette.text.main};

    display: flex;
    flex-direction: column;

    header {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;

      h1 {
        font-size: ${theme.typography.sizes.title2};
        font-weight: ${theme.typography.weight.extraBold};
      }
    }

    section {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      > div {
        display: flex;
        flex-direction: column;

        width: ${theme.layout.spacing(74)};
        ${theme.layout.rowGap(5.5)}
        padding: ${theme.layout.spacing(5.5)};
        border-radius: 2.5rem;

        background-color: ${theme.palette.neutral[100]};

        p {
          color: ${theme.palette.text.light};
          font-size: ${theme.typography.sizes.body1};
          font-weight: ${theme.typography.weight.regular};
          text-align: center;

          strong {
            font-weight: ${theme.typography.weight.bold};

            &.secondary {
              color: ${theme.palette.secondary.main};
            }
          }
        }
      }
    }

    footer {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;

      > div:nth-child(2) {
        display: flex;
        justify-content: center;
      }
    }
  `}
`;

export const DocumentButton = styled.button`
  ${({ theme }) => css`
    background: none;
    border: 1px solid ${theme.palette.primary.main};
    border-radius: 1rem;

    padding: ${theme.layout.spacing(1.5, 2.5)};

    color: ${theme.palette.primary.main};
    font-size: ${theme.typography.sizes.caption};
    font-weight: ${theme.typography.weight.bold};
  `}
`;
