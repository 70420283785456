import { useMutation } from 'react-query';

import { makeFetchCustomerByIdUseCase } from 'modules/checkin/domain/useCases';
import { useActiveCustomer } from 'shared/presentation/contexts';

type TParams = {
  onError?: (error: unknown) => void;
};

export default function useFindCustomerById(params: TParams = {}) {
  const { setCustomer } = useActiveCustomer();

  const mutation = useMutation(
    (id: number) => makeFetchCustomerByIdUseCase().execute({ id }),
    { onSuccess: setCustomer, onError: params.onError },
  );

  return {
    loading: mutation.isLoading,
    find: mutation.mutateAsync,
  };
}
