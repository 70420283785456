import {
  InputHTMLAttributes,
  useRef,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  Fragment,
  useMemo,
} from 'react';

import { useField } from 'formik';

import { Spinner } from 'shared/presentation/components/atoms';
import { MaskFormatter, TMask, TIconType } from 'shared/presentation/constants';
import { useTheme } from 'shared/presentation/contexts';

import * as S from './styles';

const getLabel = (label: string, required?: boolean) => {
  if (!required) return label;

  return label + ' *';
};

interface ITextInputProps {
  name: string;
  type?: InputHTMLAttributes<HTMLInputElement>['type'];
  onFocus?: InputHTMLAttributes<HTMLInputElement>['onFocus'];
  onBlur?: InputHTMLAttributes<HTMLInputElement>['onBlur'];
  maxLength?: InputHTMLAttributes<HTMLInputElement>['maxLength'];
  disabled?: InputHTMLAttributes<HTMLInputElement>['disabled'];
  placeholder?: InputHTMLAttributes<HTMLInputElement>['placeholder'];
  required?: InputHTMLAttributes<HTMLInputElement>['required'];
  className?: InputHTMLAttributes<HTMLInputElement>['className'];
  onValueChange?: (value: string) => void;

  label?: string;
  isLoading?: boolean;
  icon?: TIconType;
  mask?: TMask;

  textTransform?: 'uppercase' | 'lowercase';
  fieldValueFormatter?: (text: string) => string;
  backgroundColor?: string;

  autofocus?: boolean;
}

interface ITextInputRef {
  change(value: string): void;
  getInput(): HTMLInputElement | null;
}

const TextInput: ForwardRefRenderFunction<ITextInputRef, ITextInputProps> = (
  {
    name,
    label,
    disabled,
    isLoading,
    icon: Icon = Fragment,
    mask,
    required,
    className,
    textTransform,
    fieldValueFormatter = text => text,
    onValueChange,
    backgroundColor,
    onBlur,
    ...input
  },
  ref,
) => {
  const { theme } = useTheme();

  const [field, meta, helpers] = useField<string>(name);
  const inputRef = useRef<HTMLInputElement>(null);
  const hasError = Boolean(meta.error && meta.touched);

  const maskFormatter = useMemo(() => {
    if (!mask) {
      return MaskFormatter.empty();
    }

    return new MaskFormatter(mask);
  }, [mask]);

  useImperativeHandle(
    ref,
    () => ({
      change(value) {
        const input = inputRef.current;

        if (!input) return;

        const formattedValue = fieldValueFormatter(value);

        if (!maskFormatter.isInputMaskable(formattedValue)) return;
        helpers.setValue(formattedValue);
        onValueChange?.(formattedValue);
      },
      getInput() {
        return inputRef.current;
      },
    }),
    [fieldValueFormatter, helpers, onValueChange, maskFormatter],
  );

  return (
    <S.Container
      disabled={disabled}
      hasError={hasError}
      value={field.value}
      textTransform={textTransform}
      backgroundColor={backgroundColor}
      className={className}
    >
      {!!label && <label htmlFor={name}>{getLabel(label, required)}</label>}

      <div>
        <input
          {...field}
          ref={inputRef}
          id={name}
          name={name}
          onBlur={event => {
            onBlur?.(event);
            field.onBlur(event);
          }}
          onChange={undefined}
          autoComplete="off"
          {...input}
          value={maskFormatter.format(field.value)}
        />

        {isLoading ? (
          <Spinner size={1} color={theme.palette.primary.main} />
        ) : (
          <Icon />
        )}
      </div>

      {hasError && <span>{meta.error}</span>}
    </S.Container>
  );
};

export default forwardRef(TextInput);
