import { StrictMode } from 'react';

import ReactDOM from 'react-dom';
import { pdfjs } from 'react-pdf';

import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';

import 'config/locale';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

ReactDOM.render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
