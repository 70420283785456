export const esLocale = {
  common: {
    logo_alt: 'Logotipo de Vialaser Depilación',
    actions: {
      select: 'Seleccione',
    },
    states: {
      selected: 'Seleccione',
    },
  },
  components: {
    wizard: {
      buttons: {
        next: 'Proximo paso',
        finish: 'Terminar',
        previous: 'Vuelve',
        scheduleContinue: 'Continuar Agendando',
        exit: 'Salir',
        select_all: 'Seleccionar todo',
        unselect_all: 'Deseleccionar todo',
        select_day_hour: '¡Debes seleccionar un día y una hora!',
      },
    },
    numberPad: {
      title: 'Ingrese su Identidad',
      actions: {
        clear: 'Eliminar',
        send: 'Enviar',
      },
    },
    suggestion_item_a: {
      news: 'Mira esta nueva función:\nAdición',
      price_label: 'a tu paquete\npor solo',
      additional_price_label: 'extra en tu paquete',
      details: 'Quiero saber mas detalles',
      thanks: 'No gracias',
    },
    suggestion_item_b: {
      news: '¿Vamos aprovechar?',
      price_label: 'por apenas',
      additional_price_label:
        'más en su cuota,\ncon el próximo pago\ndesde aquí',
      days: '{{days}} dias',
      details: 'Me gusta, quiero comprar',
      thanks: 'No gracias',
    },
    modal: {
      attention: 'Atención',
      actions: {
        cancel: 'Vuelve',
      },
      biometry_request: {
        title: 'Identifícate para continuar',
        subtitle:
          'Coloque su dedo en el lector \n biométrico ubicado debajo de la pantalla.',
        action: 'IDENTIFICARSE CON CÉDULA DE IDENTIDADE',
        icon_alt: 'Icono de contador',
      },
      biometry_error: {
        title: 'Vaya, ¿lo intentamos de nuevo?',
        subtitle:
          'Coloque su dedo en el lector \n biométrico ubicado debajo de la pantalla.',
        icon_alt: 'icono de huella digital',
      },
      cpf_error: {
        title: 'Infelizmente, ainda não conseguimos :(',
        subtitle:
          'Pero no te preocupes, acércate \n al mostrador y te ayudaremos.',
        icon_alt: 'Icono de contador',
      },
      checkin_already_done: {
        title: 'Ops!',
        subtitle: 'Tu chek-in ya esta hecho',
        icon_alt: '',
      },
      no_schedules: {
        title: 'No se encontraron citas',
        subtitle: 'Aún no tienes cita con nosotros :(',
        icon_alt: '',
      },
      no_schedules_unit: {
        title: 'No se encontraron citas',
        subtitle: 'No tienes horario en esta Clínica :(',
        icon_alt: '',
      },
      no_schedules_day: {
        title: 'No se encontraron citas',
        subtitle: 'No tienes horario hoy :(',
        icon_alt: '',
      },
      last_session_validation: {
        not_confirmed_last: '{{name}}, aún no ha confirmado su última sesión.',
        start_request: '¿Lo haremos ahora?',
        date_time: 'FECHA / HORA DE LA SESIÓN',
        unit: 'CLÍNICA',
        applicator: 'APLICADORA',
        confirm_session: 'confirmar sesión',
        not_confirmed:
          '{{name}}, aún no ha confirmado su sesión. \n Pero no te preocupes, ve al mostrador y te ayudamos',
      },
      unassigned_contract_validation: {
        title: '{{name}}, su contrato de {{description}} no está firmado',
        subtitle: 'Suscríbete antes de completar tu check-in',
        sign_contract: 'firmar contrato',
      },
      schedule_suggestions: {
        title: '¿Vamos a acelerar este proceso?',
        description:
          'A continuación se muestran algunos horarios sugeridos para usted :-)',
        btn_primary: 'Agendar la hora seleccionada',
        btn_secondary: 'Elige otro momento',
      },
    },
    badge: {
      offer: 'Oferta Especial',
      today: 'Solo Hoy!',
    },
    schedule_regions: {
      duration: 'Duración de la sesión: ',
      minimun_date: 'Fecha mínima de programación: ',
      select: 'Seleccionar',
      selected: 'Seleccionado',
      schedule_from_day: 'Programación desde el día',
      schedule_title:
        'Hola {{name}}, selecciona las regiones que deseas programar:',
      next_session: 'Próxima sesión',
      absent_exclusive_warning:
        'Sus sesiones de {{items}} solamente pueden ser agendadas y realizadas en la unidad donde fueron adquiridas',
      and: 'y',
    },
    schedule_calendar: {
      avaiable_hours: 'Horarios disponibles',
      periods: {
        day: 'Mañana',
        afternoon: 'Tarde',
        evening: 'Noche',
      },
    },
  },
  pages: {
    home: {
      actions: {
        new_schedule: 'Programar sesión',
        my_schedules: 'Ver mis citas',
        sign_contract: 'Firmar contrato',
        cpf_checkin: 'Check-in con cédula de identidad',
        buy: 'Comparar y Comprar',
      },
    },
    checkin: {
      success: {
        title: 'Check-in finalizado, {{name}}',
        subtitle: '¡Estamos muy contentos de que estés aquí! :)',
        happyLisa: 'Lisa celebrando',
      },
      error_message: '¡Tótem no disponible!',
      identification_error: 'Código de identidad no encontrado',
      refer_and_win: '¡Indica y Gana!',
      access_qr_code_discounts:
        '¡Accedé al enlace del código QR debajo para realizar recomendaciones y obtener descuentos.!',
      procced_with_checkin: 'Continuar con el check-in',
      confirm: {
        title: 'Casi allí!',
        description:
          'Para completar su check-in, verifique que sus datos de contacto a continuación sean correctos. Si están desactualizados, ¡actualícelos!',
        update: 'Actualizar datos',
        confirm: 'Confirmar datos',
      },
      sunExposure: {
        title_one:
          'Has tomado sol en alguna de las regiones programadas en el último día?',
        title_other:
          'Has tomado sol en alguna de las regiones programadas en los últimos {{count}} días?',
        caption:
          'En caso afirmativo, toque para seleccionar las áreas donde ha tomado el sol',
        confirm: 'Sí, tomé sol en al menos una región',
        decline: 'No tomé sol en las zonas previstas',
      },
      incidences: {
        title: 'Parece que tienes un caso abierto',
        description:
          'Selecciona a continuación las regiones de tu piel que consideras intactas y saludables',
        select_all: 'Seleccionar todo',
        unselect_all: 'Deseleccionar todo',
        caption:
          '* Si consideras que ninguna de las regiones está íntegra, no selecciones ninguna opción y simplemente haz clic en el botón de abajo.',
        action: 'Responder y registrarse',
        terms_one:
          'Yo, {{name}}, confirmo que mi piel en la zona de {{areas}} está completamente intacta y sin marcas resultantes del tratamiento con láser',
        terms_other:
          'Yo, {{name}}, confirmo que mi piel en las zonas de {{areas}} está completamente intacta y sin marcas resultantes del tratamiento con láser',
      },
      update: {
        title: 'Actualización de datos',
        description:
          'Ingrese sus datos de contacto a continuación y toque "Actualizar detalles" para actualizarlos y registrarse',
        email: 'Correo electrónico',
        email_validity: 'Por favor proporcione un correo electrónico válido',
        email_required: 'El correo electrónico es obligatorio',
        phone: 'Teléfono',
        phone_validity: 'Por favor proporcione un número de teléfono válido',
        phone_required: 'El teléfono es obligatorio',
        biometry:
          'Utilice el lector biométrico para confirmar su identidad y completar el check-in',
        try_gain: 'Intente nuevamente',
        biometry_error:
          'Hubo un error al leer los datos biométricos, inténtelo nuevamente',
        wrong_fingerprint:
          'La huella dactilar introducida no coincide con el registro. Inténtalo de nuevo',
        fingerprint_not_registered:
          'La huella dactilar introducida no esta registrada. Busque un representante de servicio al cliente para resolver el problema.',
        not_confirmed_warning:
          'No se proporcionó una huella dactilar correspondiente al registro.',
      },
      no_biometry: {
        title: 'No se pudo completar el check-in',
        description:
          'Identificamos que no tienes datos biométricos registrados. Antes de continuar, busque un asistente para resolver su registro!',
        close: 'Cerrar',
      },
    },
    contracts: {
      signing: {
        header: 'Escriba su firma en el campo de abajo con su dedo:',
        actions: {
          clear: 'Firma clara',
        },
      },
      finish: {
        actions: {
          home: 'Pantalla inicial',
          sign_another: 'Firma otro contrato',
          schedule: 'Programar sesión',
          checkin: 'Finalizar el check-in',
        },
      },
      detail: {
        actions: {
          terms: 'Condiciones del contrato',
          terms_services: 'Condiciones de servicio',
        },
      },
      toSign: {
        noContracts: '{{name}}, no tienes contratos que firmar',
        header: '{{name}}, aquí están sus contratos sin firmar:',
        contract: 'Contrato: ',
        date: 'Fecha: ',
        regions: 'Regiones contratadas: ',
        value: 'Valor total: ',
        helpText:
          'Haga clic en el contrato que desee y proceda a la suscripción.',
        check_contract_terms: 'He leído y acepto el contrato y sus condiciones',
      },
      thanks: {
        title: '¡Gracias, {{name}}! Ahora solo programe su sesión.',
        contract: 'Contrato: ',
        data: 'Fecha: ',
        region: 'Regiones contratadas: ',
        valorTotal: 'Valor total: ',
      },
      wizard_header: {
        select_contract: 'Firmar contrato o enmienda',
        contract_details: 'Detalles del contrato o enmienda',
        sign: 'Firma',
        end: '¡Finalizado!',
      },
    },
    acknowledgment: {
      success: {
        title: 'Registro completado, {{name}}!',
        subtitle: 'te esperamos en la próxima sesión : )',
        scheduleTo: 'Programado para: ',
        scheduleRegions: 'Regiones programadas:',
        session: 'Sesión:',
        clinic: 'CLÍNICA',
        locals: 'LOCAL',
        recommendationText:
          'Cuida de la salud de tu piel. Aplica protector solar diariamente y mantenela hidratada a todo momento.',
      },
    },
    schedules: {
      areasToSchedule: 'Áreas para programar',
      schedule: 'Cita',
      finishSchedule: 'Terminar Cita',
      title: 'Sus citas:',
      noAreas: 'No tienes áreas para programar :(',
      noSchedules: 'Aún no tienes cita con nosotros :(',
      back: 'Vuelve',
      newSchedule: 'Programar cita',
      cancel: 'Cancelar',
      finishConfirm: 'Tu sesión ha sido programada!',
      firstRecomendation:
        'Evitar la exposición solar 15 días antes de la sesión para zonas muy expuestas al sol y 5 días antes para zonas menos expuestas.',
      gotIt: 'Entendido',
      secondRecomendation:
        'Recorta el vello con navaja o crema depilatoria uno o dos días antes de la sesión. Es importante que solo quede expuesta la punta del cabello.',
      continue: 'Continuar',
      card: {
        scheduleDate: 'Programado para:',
        scheduledAreas: 'Regiones programadas:',
        session: 'Cita:',
        unit: 'Clínica',
        location: 'Ubicación',
        reschedule: 'Reprogramar',
        cancelAppointment: 'Cancelar cita',
      },
      modal: {
        happyLisa: 'Lisa feliz',
        sadLisa: 'Lisa triste',
        reschedule:
          'Estoy seguro de que encontraremos un mejor momento para ti. ¿Realmente quieres reprogramar?',
        cancelSchedule: '¿De verdad quieres cancelar tu cita? :(',
      },
    },
    qrcodeCheckin: {
      title: 'Realice mi check-in',
      subtitle: 'Unidad {{unit}}',
      display: 'Ingrese con su ID/CPF',
      checkin: 'Check-In',
      help: 'Si tienes alguna pregunta, busque ayuda con nuestro servicio de mesa!',
      success: '{{name}}, tu registro fue exitoso!',
      accessDenied: 'No tienes acceso a esta pagina!',
      finished: 'Proceso de registro completado!',
      canceled: 'Proceso de registro cancelado!',
      error:
        'Algo salió mal al intentar realizar el registro. Inténtalo de nuevo!',
      alreadyMadeCheckin: '¡Ya te has registrado!',
      hasContracts: '¡Tienes contratos que firmar!',
    },
  },
};
