import { Fade } from '@material-ui/core';

import lisaHappyVideo from 'assets/videos/happyLisa.webm';

import * as S from './styles';

const SuccessModal: React.FC<{ visible: boolean; message: string }> = ({
  visible,
  message,
}) => {
  return (
    <Fade in={visible}>
      <S.Container>
        <S.Title>{message}</S.Title>

        <S.Video src={lisaHappyVideo} autoPlay loop muted />
      </S.Container>
    </Fade>
  );
};

export default SuccessModal;
