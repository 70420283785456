import { makeCheckinRepository } from 'shared/infra/repositories';

import CheckinCustomerUseCase from './implementations/CheckinCustomerUseCase';

let instance: CheckinCustomerUseCase | null = null;

export default function makeCheckinCustomerUseCase() {
  if (!instance) {
    const repository = makeCheckinRepository();

    instance = new CheckinCustomerUseCase(repository);
  }

  return instance;
}
