import IHashProvider from 'shared/domain/providers/IHashProvider';

import Base64HashProvider from './implementations/Base64HashProvider';

let instance: IHashProvider | null = null;

export default function makeHashProvider(): IHashProvider {
  if (!instance) {
    instance = new Base64HashProvider();
  }

  return instance;
}
