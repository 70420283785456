import React from 'react';

import RegionSelector from './RegionSelector';
import RegionSelectionHeader from './RegionSelector/RegionSelectionHeader';
import * as S from './styles';

const ScheduleRegions: React.FC = () => (
  <S.Container>
    <RegionSelectionHeader />

    <S.ContainerMain>
      <RegionSelector />
    </S.ContainerMain>
  </S.Container>
);

export default ScheduleRegions;
