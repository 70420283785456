import { LAYOUTS } from '../layouts';
import { TLayoutNames, TModifierState } from '../types';

export default function getCurrentLayoutName(
  state: TModifierState,
  name: TLayoutNames,
) {
  const entries = [...Object.entries(state)];
  const layout = LAYOUTS[name];

  for (const [modifier, value] of entries) {
    if (modifier in layout && !!value) return modifier;
  }

  return 'default';
}
