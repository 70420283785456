import { MuiThemeProvider } from '@material-ui/core';

import appModules from 'shared/presentation/modules';

import { ModalProvider } from './context/ModalContext';
import { SnackBarProvider } from './context/SnackBarContext';
import Router from './Router';
import theme from './styles/theme';

import './App.css';

const AppContainer = appModules.Container;

const App = () => (
  <AppContainer>
    <MuiThemeProvider theme={theme}>
      <ModalProvider>
        <SnackBarProvider>
          <Router />
        </SnackBarProvider>
      </ModalProvider>
    </MuiThemeProvider>
  </AppContainer>
);

export default App;
