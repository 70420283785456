import { IFetchCustomerByDocumentDTO } from 'modules/checkin/domain/dtos';
import { ICustomersRepository } from 'shared/domain/repositories';

export default class FetchCustomerByDocumentUseCase {
  constructor(private customerRepository: ICustomersRepository) {}

  public async execute({ document }: IFetchCustomerByDocumentDTO) {
    return this.customerRepository.findByDocument(document);
  }
}
