import { createContext, useCallback, useContext, useState } from 'react';

import {
  TCheckinLastSessionResponse,
  TCheckinNotSignedResponse,
} from 'types/api';

import Modal from '../components/modal';
import {
  ModalBiometryValidation,
  ModalCpfValidation,
  ModalIdentifyValidation,
  ModalLastSessionValidation,
  ModalUnassignedContractValidation,
} from '../components/ModalValidations';
import ModalDefaultError from '../components/ModalValidations/ModalDefaultError';
import { ModalTypes } from '../enums/ModalTypes';

type TTypeModal = {
  type?: string;
  message?: string;
  response?: unknown;
  action?(): void;
};

type TModalContextActions = {
  showModal: (type: TTypeModal) => void;
  handleClose: () => void;
};

const ModalContext = createContext({} as TModalContextActions);

const parseResponseIntoContract = (
  response?: any,
): Partial<TCheckinNotSignedResponse> => ({
  contractId: response?.contrato,
  contractDescription: response?.descricaoContrato,
  clientId: response?.idCliente,
  client: response?.cliente,
});

const parseResponseIntoLastPersonInfo = (
  response?: any,
): Partial<TCheckinLastSessionResponse> => ({
  session: response?.sessao,
  username: response?.cliente,
  aplyier: response?.aplicadora,
  unit: response?.unidade,
  data: `${response?.data} ${response?.hora}`,
  areas: response?.areas?.map((area: any) => ({
    name: area.nome,
    photo: area.foto,
  })),
});

interface IModalContextProviderProps {
  children: React.ReactNode;
}

const ModalProvider: React.FC<IModalContextProviderProps> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<TTypeModal>({} as TTypeModal);

  const showModal = useCallback((type: TTypeModal) => {
    setModalType(type);
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const renderChildren = () => {
    switch (modalType.type) {
      case ModalTypes.IDENTIFY: {
        if (modalType.action) {
          return <ModalIdentifyValidation openCpf={modalType.action} />;
        }
        break;
      }
      case ModalTypes.BIOMETRIA: {
        return <ModalBiometryValidation />;
      }
      case ModalTypes.PENDENCIA: {
        return <ModalCpfValidation />;
      }
      case ModalTypes.CONTRATO_NAO_ASSINADO: {
        if (modalType.message) {
          return (
            <ModalUnassignedContractValidation
              info={parseResponseIntoContract(modalType.response)}
            />
          );
        }
        break;
      }
      case ModalTypes.SESSAO_ANTERIOR_NAO_CONFIRMADA: {
        if (modalType.message) {
          return (
            <ModalLastSessionValidation
              info={parseResponseIntoLastPersonInfo(modalType.response)}
              closeModal={handleClose}
            />
          );
        }
        break;
      }
      case ModalTypes.CHECKIN_JA_REALIZADO:
      case ModalTypes.NAO_AGENDADO_DIA:
      case ModalTypes.NAO_AGENDADO_UNIDADE:
      case ModalTypes.SEM_AGENDAMENTO: {
        return <ModalDefaultError errorType={modalType.type} />;
      }
    }

    return <></>;
  };

  return (
    <ModalContext.Provider value={{ showModal, handleClose }}>
      <Modal open={open} onClose={handleClose}>
        {renderChildren()}
      </Modal>
      {children}
    </ModalContext.Provider>
  );
};

const useModal = (): TModalContextActions => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModal must be used within an ModalProvider');
  }

  return context;
};

export { ModalProvider, useModal };
