import React, {
  ComponentRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { Modal } from 'shared/presentation/components/atoms';
import { Button } from 'shared/presentation/components/molecules';
import { ICONS } from 'shared/presentation/constants';
import { TRoutePathsWithParams, useLocale } from 'shared/presentation/contexts';
import { useInteractionTimeout } from 'shared/presentation/hooks';

import { useSendAnswer } from './hooks';
import * as S from './styles';

const TIMEOUT = 30;

const AlertIcon = ICONS.ALERT_CIRCLE;

interface ICheckSunExposureModalRef {
  open(): void;
}

interface ICheckSunExposureModalProps {
  scheduleId: number;
  customerId: number;
  sunToleranceInDays: number;
  items: Array<{ id: number; title: string; image: string }>;
  onDoCheckin(params: { customerId: number; to?: TRoutePathsWithParams }): void;
}

const CheckSunExposureModal: React.ForwardRefRenderFunction<
  ICheckSunExposureModalRef,
  ICheckSunExposureModalProps
> = (
  { items, scheduleId, customerId, onDoCheckin, sunToleranceInDays },
  ref,
) => {
  const modalRef = useRef<ComponentRef<typeof Modal>>(null);

  const timer = useInteractionTimeout({
    timeout: TIMEOUT,
    onTimeUp: useCallback(() => modalRef.current?.close(), []),
  });

  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        modalRef.current?.open();
        timer.reset();
      },
    }),
    [timer],
  );

  const { t } = useLocale('translations');

  const controller = useSendAnswer({ scheduleId, customerId });

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [, setDeclineLoading] = useState(false);
  const [selectedAreas, setSelectedAreas] = useState<number[]>([]);

  const handleToggleAll = () => {
    setSelectedAreas(() => (shouldSelectAll ? items.map(item => item.id) : []));
  };

  const handleToggleArea = (areaId: number) => {
    setSelectedAreas(selectedAreas => {
      const areaIndex = selectedAreas.findIndex(area => area === areaId);

      if (areaIndex < 0) return [...selectedAreas, areaId];

      const newSelection = [...selectedAreas];
      newSelection.splice(areaIndex, 1);

      return newSelection;
    });
  };

  const handleConfirmExposure = async () => {
    if (!selectedAreas.length) {
      return;
    }

    setConfirmLoading(true);
    await controller.confirmExposure(
      { answer: true, areaIds: selectedAreas },
      {
        onSettled: () => {
          setConfirmLoading(false);
          modalRef.current?.close();
          onDoCheckin({ customerId, to: '/sun-exposure' });
        },
      },
    );
  };

  const handleDeclineExposure = async () => {
    if (controller.loading) return;

    setDeclineLoading(true);
    await controller.confirmExposure(
      { answer: false },
      {
        onSettled: () => {
          setDeclineLoading(false);
          modalRef.current?.close();
          onDoCheckin({ customerId, to: '/' });
        },
      },
    );
  };

  const shouldSelectAll = selectedAreas.length !== items.length;

  return (
    <Modal
      ref={modalRef}
      initialOpen
      onClose={() => {
        setSelectedAreas([]);
      }}
    >
      <S.Container>
        <h2>
          {t('pages.checkin.sunExposure.title', { count: sunToleranceInDays })}
        </h2>

        <section>
          <div>
            {items.map(item => (
              <button
                key={item.id}
                onClick={() => handleToggleArea(item.id)}
                role="option"
                aria-selected={selectedAreas.includes(item.id)}
              >
                <img src={item.image} alt={item.title} />
                <span>{item.title}</span>
              </button>
            ))}
          </div>

          <caption>
            <AlertIcon /> {t('pages.checkin.sunExposure.caption')}
          </caption>
        </section>

        <footer>
          <Button variant="default" outline={true} onClick={handleToggleAll}>
            {shouldSelectAll ? 'Selecionar tudo' : 'Deselecionar tudo'}
          </Button>

          <Button
            variant="secondary"
            loading={confirmLoading}
            onClick={handleConfirmExposure}
            disabled={!selectedAreas.length}
          >
            {t('pages.checkin.sunExposure.confirm')}
          </Button>

          <Button
            variant="primary"
            loading={controller.loading}
            onClick={handleDeclineExposure}
          >
            {t('pages.checkin.sunExposure.decline')}
          </Button>
        </footer>
      </S.Container>
    </Modal>
  );
};

export default forwardRef(CheckSunExposureModal);
