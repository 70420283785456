import { memo, useMemo } from 'react';

import Modal from 'components/modal';
import ServiceTerm from 'components/ServiceTerm';
import { Document, Page } from 'react-pdf';
import Logger from 'utils/Logger';

import { makeHTTPProvider } from 'shared/infra/providers';
import { useLocale } from 'shared/presentation/contexts';

import * as S from './styles';

type TDetailsProps = {
  clientId: number;
  contractId: number;
  termsChecked: boolean;
  showTerms: boolean;
  setShowTerms: (show: boolean) => void;
  onTermsChecked: (checked: boolean) => void;
};

const Details: React.FC<TDetailsProps> = ({
  clientId,
  contractId,
  termsChecked,
  showTerms,
  setShowTerms,
  onTermsChecked,
}) => {
  const { t } = useLocale('translations');

  const provider = useMemo(() => makeHTTPProvider(), []);

  return (
    <S.Container>
      <Document
        onLoadError={error => Logger.error(error.message)}
        file={{
          url: `${provider.domain}area-cliente/financeiro/${clientId}/contrato/${contractId}/pdf`,
          httpHeaders: {
            Authorization: process.env.REACT_APP_API_TOKEN,
            Accept: 'application/pdf',
          },
        }}
      >
        <Page pageNumber={1} width={1740} />
      </Document>

      <S.WrapperCheckbox onClick={() => onTermsChecked(!termsChecked)}>
        <S.InputCheck selected={termsChecked} />
        <label>{t('pages.contracts.toSign.check_contract_terms')}</label>
      </S.WrapperCheckbox>

      <Modal open={showTerms} onClose={() => setShowTerms(false)}>
        <S.ModalWrapper>
          <ServiceTerm />
        </S.ModalWrapper>
      </Modal>
    </S.Container>
  );
};

export default memo(Details);
