import { useCallback, useState } from 'react';

import { makeHTTPProvider } from 'shared/infra/providers';

type TVectisCustomer = {
  id: number;
  nomeCompleto: string;
  cpf: string;
  email: string;
  foneCelular: string;
  deveConfirmarDadosContato: boolean;
  biometriaCadastrada: boolean;
};

interface ICustomer {
  id: number;
  fullName: string;
  email: string;
  phone: string;
  document: string;
}

export default function useCheckDataConfirmation() {
  const [customerData, setCustomerData] = useState<ICustomer | null>(null);

  const isDataConfirmed = useCallback(
    async (params: { document?: string; id?: number }) => {
      const vectisCustomer = await makeHTTPProvider().get<TVectisCustomer>(
        '/clientes/confirmacaoContato',
        {
          params: {
            cpfCliente: params.document,
            idCliente: params.id,
          },
        },
      );

      if (vectisCustomer.deveConfirmarDadosContato) {
        setCustomerData({
          id: vectisCustomer.id,
          fullName: vectisCustomer.nomeCompleto,
          document: vectisCustomer.cpf,
          email: vectisCustomer.email,
          phone: vectisCustomer.foneCelular,
        });
      }

      return {
        isConfirmed: !vectisCustomer.deveConfirmarDadosContato,
        hasBiometry: vectisCustomer.biometriaCadastrada,
      };
    },
    [],
  );

  return {
    isDataConfirmed,
    customerData,
    resetData: () => setCustomerData(null),
  };
}
