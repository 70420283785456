import React from 'react';

import { CircularProgress } from '@material-ui/core';

import * as S from './styles';

const RegionSelector: React.FC = () => (
  <S.Container>
    <CircularProgress size={80} />
  </S.Container>
);

export default RegionSelector;
