import React, { useEffect } from 'react';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { makeHTTPProvider } from 'shared/infra/providers';
import { useTotemInfo } from 'shared/presentation/contexts';

const LocaleProvider: React.FC = ({ children }) => {
  const { language } = useTotemInfo();
  const { i18n } = useTranslation('translations');

  useEffect(() => {
    if (!language) return;

    i18n.changeLanguage(language);
    moment.locale(language);

    makeHTTPProvider().setLocale(language);
  }, [i18n, language]);

  return <>{children}</>;
};

export default LocaleProvider;
