import Button from 'components/button';
import Modal from 'components/modal';
import theme from 'styles/theme';

import HappyLisa from 'assets/videos/happyLisa.webm';
import { useLocale, useNavigation } from 'shared/presentation/contexts';

import { ModalWrapper, TitleModal } from './styles';

interface IModalRescheduleProps {
  openModal: boolean;
  closeModal(): void;
  scheduleId: number;
  items: Record<string, unknown>[];
}

const ModalReschedule = ({
  openModal,
  closeModal,
  scheduleId,
  items,
}: IModalRescheduleProps) => {
  const navigation = useNavigation();
  const { t } = useLocale('translations');

  const reSchedule = (): void => {
    return navigation.push(`/schedules`, {
      id: scheduleId,
      items: JSON.stringify(items.map(item => item.name)),
    });
  };

  return (
    <Modal open={openModal} onClose={closeModal}>
      <>
        <ModalWrapper>
          <video src={HappyLisa} height="100" width="100" autoPlay loop muted />
          <TitleModal>{t('pages.schedules.modal.reschedule')}</TitleModal>
          <Button
            color={theme.colors.white}
            background={theme.colors.primary}
            onClick={() => reSchedule()}
          >
            {t('pages.schedules.card.reschedule')}
          </Button>
        </ModalWrapper>
      </>
    </Modal>
  );
};

export default ModalReschedule;
