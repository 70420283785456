import { IUnit } from 'shared/domain/entities';

export interface IVectisUnit {
  id: number;
  descricaoWeb: string;
  cidade: string;
  uf: string;
}

export class Unit implements IUnit {
  public id: IUnit['id'];
  public name: IUnit['name'];
  public address: IUnit['address'];

  constructor({ id, descricaoWeb, cidade, uf }: IVectisUnit) {
    this.id = id;
    this.name = descricaoWeb;
    this.address = {
      city: cidade,
      district: uf,
    };
  }
}
