export const formatDate = (
  date: string,
  type?: 'DD/MM/YYYY' | 'YYYY-MM-DD',
): string => {
  if (type === 'YYYY-MM-DD') {
    return date.split('/').reverse().join('-');
  } else {
    return date.split('-').reverse().join('/');
  }
};
