import { useMutation } from 'react-query';

import { makeHTTPProvider } from 'shared/infra/providers';
import { useUnit } from 'shared/presentation/contexts';

type TParams = {
  scheduleId: number;
  customerId: number;
};

type TMutateParams = {
  answer: boolean;
  areaIds?: number[];
};

export default function useSendAnswer(params: TParams) {
  const { unit } = useUnit();

  const mutation = useMutation(({ answer, areaIds }: TMutateParams) => {
    if (!unit) throw new Error();

    if (answer && !areaIds?.length) throw new Error('Error selecting areas');

    return makeHTTPProvider().post('/avaliacaoPreSessao/checkin/resposta', {
      idAgenda: params.scheduleId,
      idCliente: params.customerId,
      idUnidade: unit.id,
      tomouSol: answer,
      areasTomouSol: areaIds,
    });
  });

  return {
    loading: mutation.isLoading,
    confirmExposure: mutation.mutateAsync,
  };
}
