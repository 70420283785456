import { styled } from '@material-ui/core/styles';

export const Container = styled('div')(({ theme }) => ({
  width: '100%',
  display: ' flex',
  flexDirection: 'column',
  background: theme.colors.background,
  color: theme.colors.black,
}));

export const Logo = styled('div')({
  width: '100%',
  textAlign: 'left',
  paddingLeft: '3rem',
});

export const Main = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '26px 47px 250px 300px',
  background: theme.colors.background,
}));

export const ContainerThanks = styled('div')(({ theme }) => ({
  display: ' flex',
  flexDirection: 'column',
  background: theme.colors.background,
  color: theme.colors.black,
}));

export const DivThank = styled('div')({
  alignSelf: 'flex-start',
  marginBottom: '20px',
});

export const Title = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'nowrap',
  fontSize: theme.font.sizes.medium,
  fontWeight: theme.font.normal,
  color: theme.colors.primary,
}));

export const Card = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'nowrap',
  background: theme.colors.white,
  fontSize: theme.font.sizes.small,
  fontWeight: theme.font.normal,
  color: theme.colors.success,
  borderRadius: '30px',
  width: 'min-content',
  boxShadow: '5px 10px 20px rgba(0,0,0,0.1)',
}));

export const CardButtons = styled('div')({
  display: 'inline-grid',
  gridAutoFlow: 'column',
  margin: '35px 0px 0px 50px',
});

export const CardRecommendationText = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '35px 0px 0px 50px',
  padding: '10px 20px 10px 20px',
  background: theme.colors.white,
  borderRadius: '100px',
}));

export const TitleHeadCard = styled('span')(({ theme }) => ({
  fontSize: theme.font.sizes.xsmall,
  fontWeight: theme.font.bold,
  color: theme.colors.gray,
}));

export const TitleBodyCardSchedule = styled('span')(({ theme }) => ({
  fontSize: theme.font.sizes.xxxsmall,
  fontWeight: theme.font.bold,
  color: theme.colors.black,
}));

export const TitleBodyCardSession = styled('span')(({ theme }) => ({
  fontSize: theme.font.sizes.xxsmall,
  fontWeight: theme.font.normal,
  color: theme.colors.gray,
  padding: '20px',
}));

export const TitleBodyCardLocation = styled('span')(({ theme }) => ({
  fontSize: theme.font.sizes.xxsmall,
  fontWeight: theme.font.normal,
  color: theme.colors.gray,
  padding: '2px',
}));

export const Schedules = styled('div')({
  display: 'flex',
  margin: '10px',
});

export const SchedulesItems = styled('div')({
  padding: '10px 20px 20px 10px',
});

export const SchedulesLocation = styled('div')({
  display: 'flex',
});

export const ScheduleIconPosition = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0px 10px 0px 0px',
});

export const Location = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  margin: '0px 30px 0px 0px',
});

export const TextRecommendation = styled('span')(({ theme }) => ({
  fontSize: theme.font.sizes.xsmall,
  fontWeight: theme.font.bold,
  color: theme.colors.black,
}));

export const Video = styled('video')({
  height: '80vh',
  objectFit: 'cover',
});

export const divVideo = styled('div')({
  position: 'absolute',
  bottom: '0',
  left: '0',
  zIndex: 'auto',
});
