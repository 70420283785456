import CurrencyFormat from 'react-currency-format';

import { useLocale } from 'shared/presentation/contexts';

const DECIMAL_SEPARATOR = { pt: ',', es: '', 'es-CR': ',' } as const;
const SCALE = { pt: 2, es: 0, 'es-CR': 0 } as const;
const THOUSAND_SEPARATOR = { pt: '.', es: '.', 'es-CR': '.' } as const;
const PREFIX = { pt: 'R$ ', es: '₲ ', 'es-CR': '₲ ' } as const;

type TLocalizedCurrencyProps = {
  className?: string;
  value: number;
  suffix?: string;
  title?: string;
};

const LocalizedCurrencyFormat: React.FC<TLocalizedCurrencyProps> = ({
  className,
  value,
  suffix,
  title,
}) => {
  const { language } = useLocale();

  return (
    <CurrencyFormat
      className={className}
      value={value}
      suffix={suffix}
      title={title}
      thousandSeparator={THOUSAND_SEPARATOR[language]}
      decimalSeparator={DECIMAL_SEPARATOR[language]}
      prefix={PREFIX[language]}
      decimalScale={SCALE[language]}
      fixedDecimalScale
      displayType="text"
    />
  );
};

export default LocalizedCurrencyFormat;
