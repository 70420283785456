import { styled, Theme } from '@material-ui/core/styles';

type TContainerProps = {
  theme: Theme;
  selected?: boolean;
};

export const Card = styled('div')(({ theme, selected }: TContainerProps) => ({
  display: 'flex',
  background: selected ? theme.colors.greenLight : theme.colors.white,
  borderRadius: 35,
  color: selected ? theme.colors.darkPrimary : theme.colors.pink,
  justifyContent: 'center',
  margin: '0 12px',
  height: '100%',
}));

export const CardContent = styled('div')({
  display: 'grid',
  background: 'transparent',
  padding: '12px 18px',
  '& Button': {
    padding: '12px 18px',
  },
});

export const CardItem = styled('div')(
  ({
    theme,
    disabled,
    selected,
  }: {
    theme: Theme;
    disabled: boolean;
    selected: boolean;
  }) => ({
    '& h2': {
      fontSize: 26,
      textAlign: 'center',
      color: selected
        ? theme.colors.black
        : disabled
        ? theme.colors.gray
        : theme.colors.pink,
    },
  }),
);

export const Img = styled('img')(({ disabled }: { disabled: boolean }) => ({
  margin: '12px 0',
  height: '150px',
  width: '300px',

  ...(disabled && { filter: 'saturate(0)' }),
}));

export const CardInfo = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  fontSize: '10px',
  padding: '12px 18px',
});

export const CardSession = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: 8,
  borderTop: `1px solid ${theme.colors.lightGray}`,
  borderBottom: `1px solid ${theme.colors.lightGray}`,
}));

export const CardSessionDescription = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 12,
});

export const Strong = styled('strong')(
  ({ theme, disabled }: { disabled: boolean } & TContainerProps) => ({
    fontSize: theme.font.sizes.xxxsmall,
    textTransform: 'uppercase',
    ...(disabled && { color: theme.colors.gray }),
  }),
);

export const Span = styled('span')(({ theme, selected }: TContainerProps) => ({
  color: theme.colors.black,
  fontWeight: selected ? theme.font.bold : theme.font.normal,
  fontSize: theme.font.sizes.xxsmall,
}));

export const CardSchedule = styled('span')(({ theme }) => ({
  display: 'flex',
  padding: '0.5rem',
  borderBottom: `1px solid ${theme.colors.lightGray}`,
}));

export const CardScheduleDescription = styled('span')({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 12,
});

export const CardFooter = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  padding: '12px 16px',

  '& p': {
    color: theme.colors.gray,
  },
}));
