import { Modal } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

export const ModalWrapper = styled(Modal)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  whiteSpace: 'pre-line', // To enable line breaks (\n) on texts inside modal.
});

export const ModalBody = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: '1rem',
  color: theme.colors.black,
  maxWidth: '80%',
  backgroundColor: theme.colors.white,
  borderRadius: '35px',
}));

export const ModalContent = styled('div')({
  padding: '1rem 1.5rem 0',
});

export const ModalFooter = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '24px',
});
