import { useCallback, useState } from 'react';

import { makeHTTPProvider } from 'shared/infra/providers';
import { useUnit } from 'shared/presentation/contexts';

interface IVectisIncidence {
  id: number;
  produtos: Array<{
    id: number;
    descricao: string;
    urlImagemAltaResolucao: string;
  }>;
  idCliente: number;
  nomeCompletoCliente: string;
  realizarConfirmacaoPeleIntegra: boolean;
}

interface IIncidencesAggregator {
  items: Array<{
    id: number;
    title: string;
    image: string;
    incidencesId: number[];
  }>;
  customer: { id: number; name: string };
}

export default function useCheckForIncidences() {
  const [incidenceData, setIncidenceData] =
    useState<IIncidencesAggregator | null>(null);
  const { unit } = useUnit();

  const shouldQuestionAboutIncidences = useCallback(
    async (params: { id?: number; document?: string }) => {
      if (!unit) return false;

      const vectisIncidences = await makeHTTPProvider().get<IVectisIncidence[]>(
        'ocorrencias/ocorrenciasSessaoEmAberto',
        {
          params: {
            unidade: unit.id,
            cliente: params.id,
            cpf: params.document,
          },
        },
      );

      const incidences = vectisIncidences.filter(
        incidence => incidence.realizarConfirmacaoPeleIntegra,
      );

      const hasIncidences = !!incidences.length;

      if (hasIncidences) {
        setIncidenceData(buildIncidenceData(incidences));
      }

      return hasIncidences;
    },
    [unit],
  );

  return {
    shouldQuestionAboutIncidences,
    incidenceData,
    resetData: () => setIncidenceData(null),
  };
}

function buildIncidenceData(
  incidences: IVectisIncidence[],
): IIncidencesAggregator {
  const itemsMap = new Map<number, IIncidencesAggregator['items'][number]>();

  incidences.forEach(incidence => {
    incidence.produtos.forEach(product => {
      const existingItem = itemsMap.get(product.id);

      if (!existingItem) {
        return itemsMap.set(product.id, {
          id: product.id,
          image: product.urlImagemAltaResolucao,
          title: product.descricao,
          incidencesId: [incidence.id],
        });
      }

      return itemsMap.set(product.id, {
        ...existingItem,
        incidencesId: [...existingItem.incidencesId, incidence.id],
      });
    });
  });

  const [firstIncidence] = incidences;

  return {
    items: [...itemsMap.values()],
    customer: {
      name: firstIncidence.nomeCompletoCliente,
      id: firstIncidence.idCliente,
    },
  };
}
