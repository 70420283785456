import IHTTPProvider from 'shared/domain/providers/IHTTPProvider';
import { ICustomersRepository } from 'shared/domain/repositories';
import { Customer, IVectisCustomer } from 'shared/infra/adapters';

export default class VectisCustomersRepository implements ICustomersRepository {
  constructor(private httpProvider: IHTTPProvider) {}

  public findByDocument: ICustomersRepository['findByDocument'] =
    async document => {
      const customer = await this.httpProvider.get<IVectisCustomer | null>(
        `/clientes`,
        {
          params: { cpfCnpj: document },
        },
      );

      if (!customer) return null;

      return new Customer(customer);
    };

  public findByEmail: ICustomersRepository['findByEmail'] = async email => {
    const customer = await this.httpProvider.get<IVectisCustomer | null>(
      `/clientes`,
      {
        params: { email },
      },
    );

    if (!customer) return null;

    return new Customer(customer);
  };

  public findById: ICustomersRepository['findById'] = async id => {
    const customer = await this.httpProvider.get<IVectisCustomer | null>(
      `/clientes/${id}`,
    );

    if (!customer) return null;

    return new Customer(customer);
  };
}
