import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    ${theme.layout.rowGap(10)}

    footer {
      width: ${theme.layout.spacing(40)};

      display: flex;
      flex-direction: column;
      ${theme.layout.rowGap(4)}

      p {
        text-align: center;
        font-size: ${theme.typography.sizes.body1};
      }
    }
  `}
`;

const translate = keyframes`
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-15%);
  }

  100% {
    transform: translateY(0);
  }
`;

export const FingerprintImage = styled.img`
  animation: ${translate} 2s infinite;
`;
