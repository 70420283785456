import { styled } from '@material-ui/core/styles';

import theme from '../../../styles/theme';

export const Wrap = styled('div')({
  '& h2': {
    width: '100%',
    textAlign: 'center',
    color: theme.colors.gray,
    fontWeight: 700,
  },
  '& h3': {
    textAlign: 'center',
    color: theme.colors.primary,
    fontWeight: 700,
    margin: '2rem 0',
  },
});

export const Content = styled('div')({
  display: 'grid',
  width: '23vw',
  gap: '2rem 1rem',
  justifyContent: 'center',
  gridTemplateAreas: `'area info'
  'button button'`,
  '& .carousel-root': {
    gridArea: 'area',
  },
  '& button': {
    gridArea: 'button',
  },
  '& .carousel.carousel-slider .control-arrow': {
    top: 'calc(50% - 4rem)',
    height: '8rem',
  },
});

export const Infos = styled('div')({
  gridArea: 'info',
  width: '15rem',
  display: 'grid',
  gridTemplateRows: 'repeat(3, auto)',
});

export const Info = styled('div')({
  height: '4rem',
  display: 'grid',
  gap: '0 1rem',
  gridTemplateColumns: '2rem auto',
  gridTemplateRows: '1rem 1rem',
  gridTemplateAreas: `'icon InfoTitle'
  'icon InfoSubtitle'`,
});

export const InfoTitle = styled('h5')({
  gridArea: 'InfoTitle',
  color: theme.colors.pink,
  fontWeight: 700,
});

export const InfoSubtitle = styled('h5')({
  gridArea: 'InfoSubtitle',
  color: theme.colors.gray,
  fontWeight: 700,
});

export const Icon = styled('div')({
  width: '2rem',
  height: '2rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 5,
  borderRadius: 25,
  background: theme.colors.pink,
  '& img': {
    width: '100%',
  },
});
