import { IFetchCustomerByIdDTO } from 'modules/checkin/domain/dtos';
import { ICustomersRepository } from 'shared/domain/repositories';

export default class FetchCustomerByIdUseCase {
  constructor(private customerRepository: ICustomersRepository) {}

  public async execute({ id }: IFetchCustomerByIdDTO) {
    return this.customerRepository.findById(id);
  }
}
