import { Content, Subtitle, Title } from './styles';
import Modal from '../../';

type TSimpleModalProps = {
  title: string;
  subtitle: string;
  content: React.ReactNode;
  titleColor?: string;
  open: boolean;
  onClose: () => void;
};

const SimpleModal: React.FC<TSimpleModalProps> = ({
  titleColor = '#ff6868',
  title,
  subtitle,
  content,
  open,
  onClose,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <>
        <Title color={titleColor}>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <Content>{content}</Content>
      </>
    </Modal>
  );
};

export default SimpleModal;
