import { Suspense, lazy } from 'react';

import {
  Navigator,
  Redirect,
  Route,
  Switch,
} from 'shared/presentation/contexts';
import appModules from 'shared/presentation/modules';

import Appointments from '../pages/Appointments';
import Checkin from '../pages/Checkin';
import Contracts from '../pages/Contracts';
import QRCodeCheckin from '../pages/QRCodeCheckin';
import Schedules from '../pages/Schedules';
import Thank from '../pages/Thank';
import UserSchedules from '../pages/UserSchedules';

const AsyncThirdPartyScripts = lazy(
  () => import('../components/ThirdyPartyScripts'),
);

const modulesRoutes = appModules.routes;

const Router = () => {
  return (
    <>
      <Switch>
        {modulesRoutes.map(route => {
          if (route.type === 'navigator') {
            return <Navigator key={route.path} {...route} />;
          }

          if (route.type === 'redirect') {
            return <Redirect key={route.from} {...route} />;
          }

          return <Route key={route.path} {...route} />;
        })}
        <Route path="/" exact component={Checkin} />
        <Route path="/schedules" component={Schedules} />
        <Route path="/user-schedules" component={UserSchedules} />
        <Route path="/appointments" component={Appointments} />
        <Route path="/contracts" component={Contracts} />
        <Route path="/thank/:name" component={Thank} />
        <Route path="/qrcode-checkin/:hash" component={QRCodeCheckin} />
        <Route path="/" component={Checkin} />
      </Switch>
      <Suspense fallback={null}>
        <AsyncThirdPartyScripts />
      </Suspense>
    </>
  );
};
export default Router;
