import {
  ComponentRef,
  ForwardRefRenderFunction,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';

import { useConfirmCustomerData } from 'pages/Checkin/hooks';
import { FiMail, FiPhone } from 'react-icons/fi';

import { Modal } from 'shared/presentation/components/atoms';
import { Button } from 'shared/presentation/components/molecules';
import { useLocale } from 'shared/presentation/contexts';
import { useTimeout } from 'shared/presentation/hooks';

import * as S from './styles';

const TIMEOUT = 30;

interface IConfirmDataModalRef {
  open(): void;
}

interface ICustomer {
  id: number;
  document: string;
  email: string;
  phone: string;
}

interface IConfirmDataModalProps {
  customer: ICustomer;
  onUpdate(): void;
  onConfirm(customer: ICustomer): void;
}

const ConfirmDataModal: ForwardRefRenderFunction<
  IConfirmDataModalRef,
  IConfirmDataModalProps
> = ({ customer, onUpdate, onConfirm }, ref) => {
  const modalRef = useRef<ComponentRef<typeof Modal>>(null);

  const timer = useTimeout({
    timeout: TIMEOUT,
    onTimeUp: useCallback(() => modalRef.current?.close(), []),
  });

  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        modalRef.current?.open();
        timer.reset();
      },
    }),
    [timer],
  );

  const controller = useConfirmCustomerData(customer);

  const { t } = useLocale('translations');

  const hasAnyMissingData = !customer.email || !customer.phone;

  return (
    <Modal ref={modalRef}>
      <S.Container>
        <header>
          <h1>{t('pages.checkin.confirm.title')}</h1>

          <p>{t('pages.checkin.confirm.description')}</p>
        </header>

        <ul>
          <li>
            <FiPhone /> <span>{customer.phone}</span>
          </li>

          <li>
            <FiMail /> <span>{customer.email}</span>
          </li>
        </ul>

        <footer>
          <Button
            type="button"
            onClick={() => {
              onUpdate();
              modalRef.current?.close();
            }}
          >
            {t('pages.checkin.confirm.update')}
          </Button>

          {!hasAnyMissingData && (
            <Button
              type="button"
              variant="primary"
              onClick={async () => {
                await controller.confirm(customer);

                modalRef.current?.close();

                onConfirm(customer);
              }}
            >
              {t('pages.checkin.confirm.confirm')}
            </Button>
          )}
        </footer>
      </S.Container>
    </Modal>
  );
};

export default forwardRef(ConfirmDataModal);
