export const components = {
  alert: { close: 'Cerrar' },
  documentTypingPad: {
    placeholder: 'Introduza su CI a continuación',
    confirm: 'Identificar',
    cancel: 'Cancelar',
    validation_error: 'Por favor, proporcione un número de CI válido',
  },
  timer: {
    title: 'Hora de finalizar la sesión',
  },
  user_identification: {
    title: {
      text: 'Identificación',
    },
    content: {
      explanation:
        'Coloque su dedo en el <1>lector biométrico</1> <3>debajo del tótem</3> y su identificación se realizará en unos instantes.',
      backButtonText: 'Volver arriba',
    },
    alerts: {
      biometryFailure: {
        title: 'Alerta!',
        text: 'No fue posible identificarlo mediante biometría. Por favor inténtalo de nuevo',
      },
    },
    documentButtonText: 'Identificación con CI',
    cpf_identification_failed:
      'No se puede encontrar un usuario con este número de CI',
  },
  virtual_keyboard: {
    backspace: 'Eliminar',
    clear: 'Cerrar',
  },
};
