import { styled } from '@material-ui/core/styles';

export const Container = styled('div')(({ theme }) => ({
  width: '100vw',
  height: '100vh',
  color: theme.colors.black,
  background: theme.colors.background,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  '& main': {
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export const Logo = styled('div')({
  width: '100%',
  textAlign: 'left',
  paddingLeft: '3rem',
});

export const Balloon = styled('div')(({ theme }) => ({
  padding: '1.5rem 3.5rem',
  background: theme.colors.primary,
  color: theme.colors.white,
  borderRadius: 100,
  textAlign: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
}));

export const Title = styled('div')(({ theme }) => ({
  fontSize: theme.font.sizes.large,
  fontWeight: theme.font.normal,
}));

export const SubTitle = styled('div')(({ theme }) => ({
  fontSize: theme.font.sizes.small,
  fontWeight: theme.font.light,
}));

export const Video = styled('video')({
  height: '70vh',
  objectFit: 'cover',
});
