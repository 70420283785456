import { styled, Theme } from '@material-ui/core/styles';

type TContainerProps = {
  theme: Theme;
  selected?: boolean;
};

export const Container = styled('div')(
  ({ theme, selected }: TContainerProps) => ({
    display: 'flex',
    alignItems: 'center',
    borderRadius: '25px',
    padding: '3px',
    backgroundColor: selected ? theme.colors.secondary : theme.colors.white,
    color: selected ? theme.colors.white : theme.palette.text.primary,
    cursor: 'pointer',
    marginBottom: '.6rem',
    minWidth: '6rem',
    minHeight: '2.35rem',
    transition: 'all .25s ease',
    transform: selected ? 'scale(1.15)' : '',
  }),
);

export const GreenDot = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: '50%',
  width: '2rem',
  height: '2rem',
  backgroundColor: theme.colors.primary,
}));

export const HourText = styled('div')(() => ({
  fontWeight: 'bold',
  padding: '0 10px',
}));
