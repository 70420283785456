import React, { ComponentRef, useEffect, useRef, useState } from 'react';

import { HTTPError } from 'shared/domain/errors';
import { makeCheckinPubSub } from 'shared/infra/pubsubs';
import { Animation, ClockTimer } from 'shared/presentation/components/atoms';
import {
  Button,
  HoveringFingerprint,
} from 'shared/presentation/components/molecules';
import {
  Alert,
  DocumentTypingPad,
} from 'shared/presentation/components/organisms';
import { ICONS } from 'shared/presentation/constants';
import {
  Trans,
  useLocale,
  useNavigation,
  useTotemInfo,
} from 'shared/presentation/contexts';

import biometryAnimation from './assets/biometry.json';
import { useFindCustomerByDocument, useFindCustomerById } from './hooks';
import * as S from './styles';

const UserIdentification: React.FC = () => {
  const { t } = useLocale('shared');
  const { version } = useTotemInfo();
  const navigation = useNavigation();

  const biometryFailureAlertRef = useRef<ComponentRef<typeof Alert>>(null);
  const requestErrorAlertRef = useRef<ComponentRef<typeof Alert>>(null);
  const padRef = useRef<ComponentRef<typeof DocumentTypingPad>>(null);

  const [requestErrorAlertMessage, setRequestErrorAlertMessage] = useState(
    t('components.user_identification.alerts.biometryFailure.text'),
  );

  const handleCloseAllAlerts = () => {
    biometryFailureAlertRef.current?.close();
    requestErrorAlertRef.current?.close();
    padRef.current?.close();
  };

  const biometryController = useFindCustomerById({
    onError: error => {
      if (error instanceof HTTPError && !!error.response) {
        setRequestErrorAlertMessage(error.message);
      } else {
        setRequestErrorAlertMessage(
          t('components.user_identification.alerts.biometryFailure.text'),
        );
      }
      requestErrorAlertRef.current?.open();
    },
  });
  const documentController = useFindCustomerByDocument();

  useEffect(() => {
    const checkinPubSub = makeCheckinPubSub({ type: version });

    const unsubscribeFromCheckin = checkinPubSub.subscribeForIdentification(
      async ({ customerId }) => {
        handleCloseAllAlerts();
        biometryController.find(customerId);
      },
    );

    const unsubscribeFromFailure = checkinPubSub.subscribeForFailure(() => {
      handleCloseAllAlerts();
      biometryFailureAlertRef.current?.open();
    });

    return () => {
      unsubscribeFromCheckin();
      unsubscribeFromFailure();
    };
  }, [version, biometryController]);

  return (
    <>
      <S.Container>
        <header>
          <h1>{t('components.user_identification.title.text')}</h1>

          <ClockTimer onTimeUp={() => navigation.push('/')} />
        </header>

        <section>
          <div>
            <p>
              <Trans
                t={t}
                localeKey="components.user_identification.content.explanation"
              >
                {' '}
                <strong className="secondary"> </strong> <strong> </strong>{' '}
              </Trans>
            </p>

            <div>
              <Animation
                animation={{
                  data: biometryAnimation,
                  width: 100,
                  height: 185,
                }}
                loop
              />
            </div>

            <Button
              variant="secondary"
              outline
              onClick={() => navigation.push('/')}
            >
              {t('components.user_identification.content.backButtonText')}
              <Button.Icon icon={ICONS.HOME} />
            </Button>
          </div>
        </section>

        <footer>
          <div>
            <S.DocumentButton
              type="button"
              onClick={() => padRef.current?.open()}
            >
              {t('components.user_identification.documentButtonText')}
            </S.DocumentButton>
          </div>

          <div>
            <HoveringFingerprint loading={biometryController.loading} />
          </div>

          <div />
        </footer>
      </S.Container>

      <Alert
        ref={biometryFailureAlertRef}
        title={t('components.user_identification.alerts.biometryFailure.title')}
        message={t(
          'components.user_identification.alerts.biometryFailure.text',
        )}
        icon={ICONS.FINGERPRINT}
      />

      <Alert
        ref={requestErrorAlertRef}
        title={t('components.user_identification.alerts.biometryFailure.title')}
        message={requestErrorAlertMessage}
        icon={ICONS.ALERT_CIRCLE}
      />

      <DocumentTypingPad
        ref={padRef}
        loading={documentController.loading}
        onSubmit={document => documentController.find(document)}
        requestError={documentController.error}
      />
    </>
  );
};

export default UserIdentification;
