import { styled } from '@material-ui/core/styles';

export const Schedule = styled('div')(({ theme }) => ({
  width: 750,
  display: ' grid',
  color: theme.colors.black,
  background: theme.colors.white,
  padding: 24,
  borderRadius: 35,
}));

export const Title = styled('div')(({ theme }) => ({
  color: theme.colors.gray,
  fontSize: 24,
  marginBottom: 16,
  '& strong': {
    color: theme.palette.text.primary,
    fontWeight: 700,
    marginLeft: 8,
  },
}));

export const AreasTitle = styled('div')(({ theme }) => ({
  fontSize: 18,
  fontWeight: 700,
  padding: '8px 0',
  borderBottom: `1px solid ${theme.colors.lightGray}`,
}));

export const Areas = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  padding: '12px 0',
  overflowY: 'auto',
  height: 150,
  marginBottom: 12,
  '& img': {
    width: '8rem',
  },
});

export const Area = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '8px 0',
});

export const AreaSession = styled('div')(({ theme }) => ({
  color: theme.colors.gray,
  '& h2': {
    color: theme.colors.pink,
    fontWeight: 700,
    fontSize: 24,
  },
  '& strong': {
    fontSize: 18,
    fontWeight: 700,
  },
}));

export const Info = styled('div')(({ theme }) => ({
  width: '12rem',
  display: 'grid',
  gridTemplateAreas: `'icon label'
  'icon info'`,
  justifyContent: 'flex-start',
  color: theme.colors.gray,
  '& strong': {
    gridArea: 'label',
    fontWeight: 700,
  },
  '& span': {
    gridArea: 'info',
  },
}));

export const InfosWrap = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-evenly',
});

export const InfoIcon = styled('figure')(({ theme }) => ({
  gridArea: 'icon',
  height: 50,
  width: 50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 5,
  background: theme.colors.lightGray,
  borderRadius: 25,
  marginRight: 12,
  alignSelf: 'center',
}));

export const Actions = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, calc(50% - 0.5rem))',
  gap: '1rem',
  marginTop: 18,
}));
