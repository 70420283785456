import { TFunction } from 'i18next';
import * as Yup from 'yup';

import {
  makeCheckPhoneValidityUseCase,
  makeFetchCustomerByEmailUseCase,
} from 'shared/domain/useCases';
import { TLocale } from 'shared/domain/valueObjects';
import { validateCellphone, validateEmail } from 'shared/presentation/helpers';

type TParams = {
  t: TFunction<'translations'>;
  sharedT: TFunction<'shared'>;
  lng: TLocale;
  customer: {
    email?: string;
    phone?: string;
  };
};

export const UpdateDataSchema = ({ t, sharedT, lng, customer }: TParams) =>
  Yup.object().shape({
    email: Yup.string()
      .email(t('pages.checkin.update.email_validity'))
      .test('Email validity', async (value, context) => {
        if (value === customer.email) return true;

        const { valid, message } = await validateEmail({
          t: sharedT,
          value,
          useCase: makeFetchCustomerByEmailUseCase(),
          strategy: 'unique',
        });

        if (message) return context.createError({ message });
        return valid;
      })
      .required(t('pages.checkin.update.email_required')),
    phone: Yup.string()
      .required(t('pages.checkin.update.phone_required'))
      .test(
        'whatsapp validity',
        t('pages.checkin.update.phone_validity'),
        async (value, context) => {
          if (value === customer.phone?.replace(/\D/g, '')) return true;

          const { valid, message } = await validateCellphone({
            value,
            t: sharedT,
            language: lng,
            useCase: makeCheckPhoneValidityUseCase(),
          });

          if (message) return context.createError({ message });
          return valid;
        },
      ),
  });
