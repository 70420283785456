export const typography = {
  family: 'Ubuntu, sans-serif',
  sizes: {
    title1: '2.75rem', // 44px
    title2: '2.25rem', // 36px
    title3: '1.5rem', // 24px
    subtitle: '1.25rem', // 20px
    body1: '1.25rem', // 20px
    body2: '1rem', // 16px
    caption: '0.875rem', // 14px
  },
  weight: {
    extraBold: '700',
    bold: '500',
    regular: '400',
    light: '300',
  },
};
