import { styled } from '@material-ui/core';

export const Container = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: '6rem 3rem auto 8rem',
  height: '100vh',
  width: '100vw',
  padding: '1rem',
  background: theme.colors.background,
}));

export const Header = styled('header')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export const Logo = styled('div')({
  '& img': {
    width: '10rem',
  },
});

export const Title = styled('h3')(({ theme }) => ({
  width: '100%',
  padding: '0 2rem',
  fontSize: '2rem',
  color: theme.colors.gray,
  background: 'transparent',
  borderBottom: `1px solid ${theme.colors.lightGray}`,
}));

export const Loading = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const Schedules = styled('div')({
  padding: '6rem 2rem 0 2rem',
});

export const NoSchedules = styled('h1')(({ theme }) => ({
  height: '3rem',
  fontSize: '3rem',
  color: theme.colors.primary,
  margin: '4rem 0 0 4rem',
}));

export const Actions = styled('div')({
  height: '4rem',
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 14rem)',
  justifyContent: 'center',
  gap: '1rem',
  marginTop: '2rem',
});
