import StringMask from 'string-mask';

import { TLocale } from 'shared/domain/valueObjects';

export type TMask =
  | {
      pattern: string;
      maxLength: number;
    }
  | ((value: string) => {
      pattern: string;
      maxLength: number;
    });

const DOCUMENT: Record<TLocale, TMask> = {
  pt: {
    pattern: '000.000.000-00',
    maxLength: 11,
  },
  es: {
    pattern: '0.000.000',
    maxLength: 7,
  },
  'es-CR': value => {
    if (value.length <= 9) {
      return {
        pattern: '0 0000 0000',
        maxLength: 12,
      };
    }

    return {
      pattern: '000000000000',
      maxLength: 12,
    };
  },
};

const PHONE: Record<TLocale, TMask> = {
  pt: {
    pattern: '(00) 00000 0000',
    maxLength: 11,
  },
  es: {
    pattern: '0000 00000',
    maxLength: 9,
  },
  'es-CR': { pattern: '0000 0000', maxLength: 8 },
};

export const MASKS = {
  DOCUMENT,
  PHONE,
};

export class MaskFormatter {
  constructor(private mask: TMask) {}

  public isInputMaskable(value: string): boolean {
    const mask = this.getMaskFromValue(value);
    return value.length <= mask.maxLength;
  }

  public isValid(value: string): boolean {
    const formatter = this.getFormatter(value);
    return formatter.validate(value);
  }

  public format(value: string): string {
    const formatter = this.getFormatter(value);
    return formatter.apply(value);
  }

  private getMaskFromValue(value: string) {
    if (typeof this.mask === 'function') return this.mask(value);

    return this.mask;
  }

  private getFormatter(value: string) {
    return new StringMask(this.getMaskFromValue(value).pattern);
  }

  public static empty() {
    return {
      format(value: string) {
        return value;
      },
      isInputMaskable() {
        return true;
      },
      isValid() {
        return true;
      },
    } as unknown as MaskFormatter;
  }
}
