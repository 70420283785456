import { HTTPError } from 'shared/domain/errors';

interface IHttpResponse {
  message?: string;
  type?: string;
}

const DEFAULT_ERROR_TYPE = 'UNKNOWN_ERROR';
const DEFAULT_ERROR_MESSAGE =
  'Erro desconhecido. Por favor, peça ajuda a atendente.';

const isHttpError = (error: unknown): error is HTTPError<IHttpResponse> =>
  error instanceof HTTPError;

export const handleError = (error: unknown) => {
  if (isHttpError(error)) {
    const data = error.response;

    return {
      message: data?.message || DEFAULT_ERROR_MESSAGE,
      type: data?.type,
      response: error.response,
    };
  }

  const isKnownError = error instanceof Error;

  if (isKnownError) {
    return {
      message: error.message,
      type: 'ERROR',
    };
  }

  return {
    type: DEFAULT_ERROR_TYPE,
    message: DEFAULT_ERROR_MESSAGE,
  };
};
