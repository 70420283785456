import { styled } from '@material-ui/core/styles';

export const Container = styled('div')({
  margin: '0 32px',
  display: 'flex',
  fontFamily: 'Ubuntu',
  height: '100%',
});

export const Content = styled('div')({
  flex: 0.85,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const Header = styled('div')(({ theme }) => ({
  textAlign: 'center',
  marginBottom: 32,

  '& h1.title': {
    fontWeight: 'bold',
    fontSize: 38,
    marginBottom: 8,
    color: theme.colors.primary,
  },

  '& h2.subtitle': {
    color: theme.colors.gray,
  },
}));

export const Recomendation = styled('div')(({ theme }) => ({
  backgroundColor: theme.colors.white,
  marginTop: 32,
  padding: 24,
  borderRadius: 16,
  width: 750,

  '& span.recomendation-text': {
    fontSize: 18,
    fontWeight: 700,
    color: theme.colors.primary,
    lineHeight: '150%',
  },
}));

export const Video = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
});
