export const sunExposure = {
  title: 'Check-in realizado com sucesso!',
  texts: {
    thanks: 'Obrigada por responder!',
    exposure:
      'A <1>exposição solar</1> recente na pele estimula a produção de melanina, o que aumenta o <3>risco de reações</3> indesejadas durante suas sessões de depilação a laser.',
    info: 'Na sala de aplicação, por favor informe as regiões em que tomou sol à aplicadora.',
  },
  button: 'Entendi',
};
