const info = (message: any, ...params: any[]) => {
  // tslint:disable-next-line: no-console
  console.info(message, ...params);
};

const log = (message: any, ...params: any) => {
  if (process.env.NODE_ENV !== 'production') {
    // tslint:disable-next-line: no-console
    console.log(message, ...params);
  }
};

const error = (message: any, ...params: any) => {
  if (process.env.NODE_ENV !== 'production') {
    // tslint:disable-next-line: no-console
    console.error(message, ...params);
  }
};

const debug = (message: any, ...params: any) => {
  if (process.env.NODE_ENV !== 'production') {
    // tslint:disable-next-line: no-console
    console.debug(message, ...params);
  }
};

const Logger = { info, log, error, debug };

export default Logger;
