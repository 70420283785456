import IHTTPProvider from 'shared/domain/providers/IHTTPProvider';
import { ICheckinRepository } from 'shared/domain/repositories';

interface IVectisCheckinRespose {
  cliente: string;
  urlIndicador?: string;
}

export default class VectisCheckinRepository implements ICheckinRepository {
  constructor(private httpProvider: IHTTPProvider) {}

  public create: ICheckinRepository['create'] = async ({
    unitId,
    customer,
  }) => {
    const { cliente, urlIndicador } =
      await this.httpProvider.post<IVectisCheckinRespose>('/checkin', {
        origem: 'TOTEM',
        unidade: unitId,
        cliente: customer.id,
        cpf: customer.document,
      });

    return {
      customerName: cliente,
      recommendationUrl: urlIndicador,
    };
  };
}
