import { WiDayHaze, WiDaySunny, WiNightAltCloudy } from 'react-icons/wi';

import { useLocale } from 'shared/presentation/contexts';

import { useScheduling } from '../../../context/SchedulingContext';
import CheckCard from '../../CheckCard';

type TDayPeriodCardData = {
  key: string;
  icon: React.ReactNode;
  label: string;
};

const DayPeriodSelector: React.FC = () => {
  const { t } = useLocale('translations');
  const { selectedDayPeriods, toggleDayPeriod } = useScheduling();

  const periods: TDayPeriodCardData[] = [
    {
      key: 'morning',
      icon: <WiDayHaze />,
      label: `${t('components.schedule_calendar.periods.day')} (8:00-12:00)`,
    },
    {
      key: 'afternoon',
      icon: <WiDaySunny />,
      label: `${t(
        'components.schedule_calendar.periods.afternoon',
      )} (12:00-19:00)`,
    },
    {
      key: 'night',
      icon: <WiNightAltCloudy />,
      label: `${t(
        'components.schedule_calendar.periods.evening',
      )} (19:00-21:00)`,
    },
  ];

  return (
    <>
      {periods.map(card => {
        return (
          <CheckCard
            dataCy={card.key}
            key={card.label}
            icon={card.icon}
            label={card.label}
            selected={selectedDayPeriods[card.key] === true}
            toggle={() => toggleDayPeriod(card.key)}
          />
        );
      })}
    </>
  );
};

export default DayPeriodSelector;
