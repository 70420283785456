import styled, { css } from 'styled-components';

export const Container = styled.main`
  ${({ theme }) => css`
    flex: 1;
    display: flex;

    padding: ${theme.layout.spacing(5, 4, 0)};
    background-color: ${theme.palette.neutral[100]};
    ${theme.layout.columnGap(1.5)};

    img {
      width: ${theme.layout.spacing(57.5)};
      height: ${theme.layout.spacing(57.5)};
      align-self: flex-end;
      flex-shrink: 0;
    }

    section {
      color: ${theme.palette.text.main};
      border-radius: 2rem;
      align-self: flex-start;

      background-color: ${theme.palette.neutral[50]};

      display: flex;
      flex-direction: column;

      padding: ${theme.layout.spacing(5)};
      ${theme.layout.rowGap(10)};

      .texts {
        display: flex;
        text-align: center;
        flex-direction: column;
        ${theme.layout.rowGap(5)};

        h1 {
          background-color: ${theme.palette.primary.dark};
          color: ${theme.palette.text.white};
          border-radius: 1rem;

          padding: ${theme.layout.spacing(2.5)};

          font-size: ${theme.typography.sizes.title1};
          font-weight: ${theme.typography.weight.bold};
        }

        > div {
          display: flex;
          flex-direction: column;

          ${theme.layout.rowGap(2)};
          padding: ${theme.layout.spacing(0, 4.5)};
          font-size: ${theme.typography.sizes.title3};
          font-weight: ${theme.typography.weight.regular};

          strong {
            font-weight: ${theme.typography.weight.bold};
          }
        }
      }

      button {
        align-self: center;
        min-width: ${theme.layout.spacing(25)};
      }
    }
  `}
`;
