import { makeUnitsRepository } from 'shared/infra/repositories';

import FetchUnitByIdUseCase from './implementations/FetchUnitByIdUseCase';

let instance: FetchUnitByIdUseCase | null;

export default function makeFetchUnitByIdUseCase() {
  if (!instance) {
    const repository = makeUnitsRepository();

    instance = new FetchUnitByIdUseCase(repository);
  }

  return instance;
}
