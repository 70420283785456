import styled, { css } from 'styled-components';

export const Container = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    ${theme.layout.rowGap(4)}
    padding:${theme.layout.spacing(8)};

    background-color: ${theme.palette.neutral[50]};
    border-radius: 2rem;

    width: ${theme.layout.spacing(50)};

    div.texts {
      display: flex;
      flex-direction: column;
      ${theme.layout.rowGap(1)}

      > span {
        width: 100%;
        text-align: center;

        color: ${theme.palette.text.light};
        font-size: ${theme.typography.sizes.title3};
        font-weight: ${theme.typography.weight.regular};

        &[data-filled='true'] {
          color: ${theme.palette.text.main};
        }
      }

      small {
        color: ${theme.palette.error.light};
        font-size: ${theme.typography.sizes.caption};
        font-weight: ${theme.typography.weight.light};
        text-align: center;
      }
    }

    footer {
      display: flex;
      flex-direction: column;
      ${theme.layout.rowGap(2)}
    }
  `}
`;
