import { styled } from '@material-ui/core';

export const Container = styled('div')({
  height: '100vh',
  padding: '24px 24px 0 24px',
  fontFamily: 'Ubuntu',
  display: 'flex',
  flexDirection: 'column',
});

export const IconButton = styled('button')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'transparent',
  border: 'none',
  outline: 'none',

  '& svg': {
    fontSize: 32,
    color: theme.colors.gray,
  },
}));

export const Title = styled('h1')(({ theme }) => ({
  color: theme.colors.black,
  margin: '24px 0 4px 0',
}));

export const Subtitle = styled('h3')(({ theme }) => ({
  color: theme.colors.gray,
}));

export const Content = styled('div')({
  flex: 1,
  margin: '48px 0',
  display: 'flex',
  flexDirection: 'column',
});

export const Display = styled('input')(({ theme }) => ({
  width: '100%',
  fontSize: 24,
  border: 'none',
  outline: 'none',
  backgroundColor: 'transparent',
  color: theme.colors.black,
  textAlign: 'center',
}));

export const Divider = styled('div')({
  margin: '24px 0',
});

export const Keyboard = styled('div')({
  alignSelf: 'center',
  display: 'grid',
  gap: 24,
  gridTemplateColumns: 'auto auto auto',
});

export const Key = styled('button')(({ theme }) => ({
  width: 70,
  height: 70,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  outline: 'none',
  borderRadius: 12,
  fontSize: 24,
  color: theme.colors.primary,
  backgroundColor: 'transparent',
  border: `1px solid ${theme.colors.primary}`,
  WebkitTapHighlightColor: 'transparent',
  transition: '0.1s ease all',

  '&:active': {
    color: theme.colors.white,
    backgroundColor: theme.colors.primary,
  },
}));

export const Error = styled('span')(({ theme }) => ({
  textAlign: 'center',
  color: theme.colors.error,
  marginTop: 24,
  fontWeight: 500,
  textTransform: 'uppercase',
}));

export const Footer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: 24,
});

export const Button = styled('button')(({ theme }) => ({
  border: 'none',
  outline: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '18px 0',
  fontSize: 18,
  borderRadius: 12,
  color: theme.colors.white,
  backgroundColor: theme.colors.primary,
  transition: '0.3s ease all',

  '&:disabled': {
    backgroundColor: theme.colors.gray,
  },
}));

export const HelpText = styled('span')(({ theme }) => ({
  margin: '24px 24px 0 24px',
  color: theme.colors.gray,
  textAlign: 'center',
}));
