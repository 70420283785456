import Pacote from './Pacote';
import TipoItem from './TipoItem';

interface IItemContrato {
  id: number;
  dataMinimaParaAgendamento: string;
  quantidadeSessoeRealizadas: number;
  quantidadeSessoes: number;
  tipoItem: TipoItem;
  pacote: Pacote;
  produto: Pacote;
  exclusivoUnidade: boolean;
  msgBloqueioAgendamento?: string;
}

export const itemContratoImageResolver = (item: Pacote) => {
  const sexoPacote = item.sexo;

  if (sexoPacote === 'MASCULINO') {
    return item.urlImagemMasculinaAltaResolucao;
  }

  return item.urlImagemFemininaAltaResolucao;
};

export default IItemContrato;
