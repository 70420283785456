import React from 'react';

import { TFunction } from 'i18next';

import { useActiveCustomer, useLocale } from 'shared/presentation/contexts';

import * as S from './styles';
import { useScheduling } from '../../../../../context/SchedulingContext';
import ItemContrato from '../../../../../domain/ItemContrato';

const buildAbsentNames = (items: ItemContrato[], t: TFunction) => {
  const names = items.map(
    item => item.produto?.descricao || item.pacote?.descricao,
  );

  const [last, ...all] = names.reverse();

  const joined = all.reverse().join(', ');

  if (!last) return '';

  if (!all.length) return last;

  return `${joined} ${t('components.schedule_regions.and')} ${last}`;
};

const RegionSelectionHeader: React.FC = () => {
  const { customer } = useActiveCustomer();
  const { absentItems } = useScheduling();
  const { t } = useLocale('translations');

  const absentItemsNames = buildAbsentNames(absentItems, t);

  return (
    <>
      <S.HelloMessage>
        {t('components.schedule_regions.schedule_title', {
          name: customer?.fullName,
        })}
      </S.HelloMessage>

      {Boolean(absentItems.length) && (
        <S.HelloMessage>
          {t('components.schedule_regions.absent_exclusive_warning', {
            items: absentItemsNames,
          })}
        </S.HelloMessage>
      )}
    </>
  );
};

export default RegionSelectionHeader;
