import { IUnitsRepository } from 'shared/domain/repositories';
import { makeHTTPProvider } from 'shared/infra/providers';

import VectisUnitsRepository from './implementations/VectisUnitsRepository';

let instance: IUnitsRepository | null = null;

export default function makeUnitsRepository() {
  if (!instance) {
    const httpProvider = makeHTTPProvider();

    instance = new VectisUnitsRepository(httpProvider);
  }

  return instance;
}
