import { createContext, useContext } from 'react';

import { TButtonVariant } from './styles';

export interface IButtonContext {
  loading: boolean;
  variant?: TButtonVariant;
  outline?: boolean;
  registerIcon: (iconName: string) => void;
  unregisterIcon: (iconName: string) => void;
}

export const ButtonContext = createContext<IButtonContext>(
  {} as IButtonContext,
);

export function useButton() {
  return useContext(ButtonContext);
}
