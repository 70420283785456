import { SchedulingContextProvider } from 'context/SchedulingContext';

import { Redirect, useActiveCustomer } from 'shared/presentation/contexts';

import SchedulingWizard from './SchedulingWizard';

const Schedules: React.FC = () => {
  const { customer } = useActiveCustomer();

  if (!customer) {
    return <Redirect to="/" params={{}} />;
  }

  return (
    <SchedulingContextProvider clientId={customer.id}>
      <SchedulingWizard />
    </SchedulingContextProvider>
  );
};

export default Schedules;
