import { ActiveCustomerProvider } from './ActiveCustomerContext';
import { HttpProvider } from './HttpContext';
import { LocaleProvider } from './LocaleContext';
import { NavigationProvider } from './NavigationContext';
import { ThemeProvider } from './ThemeContext';
import { TotemInfoProvider } from './TotemInfoContext';
import { UnitProvider } from './UnitContext';

export * from './ActiveCustomerContext';
export * from './LocaleContext';
export * from './NavigationContext';
export * from './ThemeContext';
export * from './TotemInfoContext';
export * from './UnitContext';

const SharedContainer: React.FC = ({ children }) => (
  <NavigationProvider>
    <TotemInfoProvider>
      <LocaleProvider>
        <ThemeProvider>
          <HttpProvider>
            <UnitProvider>
              <ActiveCustomerProvider>{children}</ActiveCustomerProvider>
            </UnitProvider>
          </HttpProvider>
        </ThemeProvider>
      </LocaleProvider>
    </TotemInfoProvider>
  </NavigationProvider>
);

export default SharedContainer;
