import { styled } from '@material-ui/core/styles';

import signaturePencil from '../../../assets/svgs/signature_pencil.svg';

export const Container = styled('div')(() => ({
  width: '100%',
  flexFlow: 'column',
  padding: '1rem',
}));

export const CanvasWrapper = styled('div')(() => ({
  position: 'relative',
  '& div, & canvas': {
    borderRadius: '30px',
    fill: 'red',
  },
  borderRadius: '30px',
  backgroundColor: '#f1f1f1',
  width: '100%',
  height: '728px',
  boxShadow: '0px 5px 10px #aaa',
  marginBottom: '1rem',
}));

export const CanvasIcon = styled('div')(() => ({
  position: 'absolute',
  right: '1rem',
  bottom: '1rem',
  fill: 'red',
  background: `url("${signaturePencil}") no-repeat right bottom`,
  width: '100px',
  height: '100px',
}));
