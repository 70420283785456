import IWebSocketProvider from 'shared/domain/providers/IWebSocketProvider';
import ICheckinPubSub from 'shared/domain/pubsubs/ICheckinPubsub';

const EVENTS = {
  IDENTIFICATION: 'identify',
  MESSAGE: 'message',
};

export default class LegacyTotemCheckinPubsub implements ICheckinPubSub {
  constructor(private webSocketProvider: IWebSocketProvider) {}

  public subscribeForIdentification: ICheckinPubSub['subscribeForIdentification'] =
    subscription => {
      this.webSocketProvider.addEventListener<{ idCliente: number }>(
        EVENTS.IDENTIFICATION,
        payload => {
          subscription({ customerId: payload.idCliente });
        },
      );

      return () => {
        this.webSocketProvider.removeEventListener(EVENTS.IDENTIFICATION);
      };
    };

  public subscribeForFailure: ICheckinPubSub['subscribeForFailure'] =
    subscription => {
      this.webSocketProvider.addEventListener<{ type: string }>(
        EVENTS.MESSAGE,
        ({ type }) => {
          if (type === 'identify-failure') {
            subscription();
          }
        },
      );

      return () => {
        this.webSocketProvider.removeEventListener(EVENTS.MESSAGE);
      };
    };
}
