import moment from 'moment';

import { useLocale } from 'shared/presentation/contexts';

import * as S from './styles';
import { useScheduling } from '../../../../../context/SchedulingContext';

const RegionSelectionFooter: React.FC = () => {
  const { minDate, sessionDuration } = useScheduling();
  const { t } = useLocale('translations');

  const minSchedulingDate = minDate.format('DD/MM/YYYY');
  const sessionDurationTxt = `${moment
    .utc(sessionDuration.asMilliseconds())
    .format('HH:mm')}`;

  return (
    <>
      <S.ContainerSessionDuration>
        <span>
          {t('components.schedule_regions.duration')}{' '}
          <strong>{sessionDurationTxt}</strong>
        </span>
        <span>
          {t('components.schedule_regions.minimun_date')}{' '}
          <strong>{minSchedulingDate}</strong>
        </span>
      </S.ContainerSessionDuration>
    </>
  );
};

export default RegionSelectionFooter;
