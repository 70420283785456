import { styled } from '@material-ui/core';

export const Container = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.colors.lightGray,
}));

export const Title = styled('h1')(({ theme }) => ({
  color: theme.colors.gray,
  margin: 24,
  textAlign: 'center',
}));
