import { FC } from 'react';

import * as S from './styles';

type TButtonProps = {
  icon?: React.ReactNode;
  iconBackground?: string;
  color?: string;
  background?: string;
  bordercolor?: string;
  border?: string;
  dataCy?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const Button: FC<TButtonProps> = ({
  children,
  icon,
  iconBackground,
  color,
  background,
  bordercolor,
  dataCy,
  onClick,
}) => {
  return (
    <S.Button
      color={color}
      background={background}
      bordercolor={bordercolor}
      icon={icon}
      onClick={onClick}
      data-cy={dataCy}
    >
      {icon && (
        <S.Icon background={background} iconBackground={iconBackground}>
          {icon}
        </S.Icon>
      )}
      <S.ButtonContent>{children}</S.ButtonContent>
    </S.Button>
  );
};

export const ButtonSchedule: FC<TButtonProps> = ({
  children,
  icon,
  color,
  background,
  onClick,
  border,
}) => {
  return (
    <S.ButtonSchedule
      border={border}
      color={color}
      background={background}
      icon={icon}
      onClick={onClick}
    >
      {icon && <S.Icon>{icon}</S.Icon>}
      <S.ButtonContentSchedule>{children}</S.ButtonContentSchedule>
    </S.ButtonSchedule>
  );
};

export default Button;
