import { ButtonBase } from '@material-ui/core';
import { styled, Theme } from '@material-ui/core/styles';

type TCustomProps = {
  theme: Theme;
  disabled?: boolean;
  selected?: boolean;
};

export const ClicableContainer = styled(ButtonBase)(
  ({ theme, disabled, selected }: TCustomProps) => ({
    display: 'flex',
    flexFlow: 'column',
    borderRadius: '1.3rem',
    backgroundColor: selected
      ? theme.colors.primary
      : disabled
      ? theme.colors.lightGray
      : theme.colors.white,
    color: selected
      ? theme.colors.white
      : disabled
      ? theme.colors.gray
      : 'initial',
    padding: '.5rem',
    margin: '.3rem',
    transition: 'color .2s ease-out, background-color .3s ease-in',
  }),
);

export const DayNumberWrapper = styled('div')(() => ({
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 'bold',
  fontSize: '3rem',
}));

export const DayInfoWrapper = styled('div')(
  ({ theme, selected, disabled }: TCustomProps) => ({
    display: 'grid',
    gridTemplateColumns: '35% 65%',
    alignItems: 'center',
    color: selected
      ? theme.colors.white
      : disabled
      ? theme.colors.gray
      : theme.colors.primary,
    paddingTop: '.5rem',
    borderTop: `1px solid ${
      disabled ? theme.colors.gray : theme.colors.lightGray
    }`,
  }),
);

export const DayInfoNumber = styled('div')(() => ({
  fontWeight: 'bold',
  fontSize: '1.4rem',
}));

export const DayInfoText = styled('div')(() => ({
  fontSize: '.8rem',
  textAlign: 'left',
}));
