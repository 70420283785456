import { styled } from '@material-ui/core/styles';

export const Content = styled('div')({
  display: 'grid',
  justifyContent: 'center',
  '& button': {
    width: '15vw',
    margin: '0 auto',
  },
});

export const Title = styled('h2')(({ theme }) => ({
  width: '22vw',
  textAlign: 'center',
  color: theme.colors.primary,
}));

export const Subtitle = styled('h3')(({ theme }) => ({
  width: '20vw',
  color: theme.colors.gray,
  textAlign: 'center',
  margin: '1rem 0',
}));
