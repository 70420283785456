export const components = {
  documentTypingPad: {
    placeholder: 'Introduza su CI a continuación',
    validation_error: 'Por favor, proporcione un número de CI válido',
  },
  user_identification: {
    documentButtonText: 'Identificación con CI',
    cpf_identification_failed:
      'No se puede encontrar un usuario con este número de CI',
  },
};
