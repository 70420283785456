import { styled } from '@material-ui/core';

export const Container = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.colors.white,
}));

export const Title = styled('h1')(({ theme }) => ({
  color: theme.colors.primary,
  margin: 24,
  textAlign: 'center',
}));

export const Video = styled('video')({
  position: 'fixed',
  bottom: 0,
  objectFit: 'cover',
  alignSelf: 'center',
});
