import { useState } from 'react';

import { HiOutlineLocationMarker } from 'react-icons/hi';

import { useLocale } from 'shared/presentation/contexts';

import * as S from './styles';
import unit from '../../../assets/svgs/areas.svg';
import Button from '../../../components/button';
import theme from '../../../styles/theme';
import { TSchedule } from '../../../types/api';
import ModalCancelSchedule from '../ModalCancelSchedule';
import ModalReschedule from '../ModalReschedule';

interface IScheduleProps {
  schedule: TSchedule;
  onCancel: (id: number) => void;
}

const ScheduleCard: React.FC<IScheduleProps> = ({ schedule, onCancel }) => {
  const [openModalReschedule, setOpenModalReschedule] = useState(false);
  const [openModalCancel, setOpenModalCancel] = useState(false);
  const { t } = useLocale('translations');

  const renderAreas = () => {
    return (
      <>
        <S.AreasTitle>{t('pages.schedules.card.scheduledAreas')}</S.AreasTitle>

        <S.Areas>
          {schedule.areas.map(area => (
            <S.Area key={`${area.name}-${area.session}`}>
              <img src={area.image} alt={area.name} />

              <S.AreaSession>
                <h2>{area.name}</h2>

                <strong>
                  {t('pages.schedules.card.session') + ' ' + area.session}
                </strong>
              </S.AreaSession>
            </S.Area>
          ))}
        </S.Areas>
      </>
    );
  };

  const renderInfos = () => {
    return (
      <S.InfosWrap>
        <S.Info>
          <S.InfoIcon>
            <img src={unit} alt="unit" />
          </S.InfoIcon>
          <strong>{t('pages.schedules.card.unit')}</strong>
          <span>{schedule.unit}</span>
        </S.Info>

        <S.Info>
          <S.InfoIcon>
            <HiOutlineLocationMarker size={32} color={theme.colors.white} />
          </S.InfoIcon>
          <strong>{t('pages.schedules.card.location')}</strong>
          <span>{schedule.location}</span>
        </S.Info>
      </S.InfosWrap>
    );
  };

  const renderActions = () => {
    return (
      <S.Actions>
        <Button
          color={theme.colors.red}
          background={theme.colors.white}
          bordercolor={theme.colors.red}
          onClick={() => setOpenModalCancel(true)}
        >
          {t('pages.schedules.card.cancelAppointment')}
        </Button>
        <Button
          color={theme.colors.white}
          background={theme.colors.primary}
          onClick={() => setOpenModalReschedule(true)}
        >
          {t('pages.schedules.card.reschedule')}
        </Button>
      </S.Actions>
    );
  };

  return (
    <S.Schedule>
      <S.Title>
        {t('pages.schedules.card.scheduleDate')}
        <strong>
          {schedule.date} {schedule.hour}
        </strong>
      </S.Title>

      {schedule.areas.length > 0 && renderAreas()}
      {renderInfos()}
      {renderActions()}

      <ModalReschedule
        openModal={openModalReschedule}
        closeModal={() => setOpenModalReschedule(false)}
        scheduleId={schedule.id}
        items={schedule.areas}
      />
      <ModalCancelSchedule
        openModal={openModalCancel}
        closeModal={() => setOpenModalCancel(false)}
        schedule={schedule}
        onCancel={onCancel}
      />
    </S.Schedule>
  );
};

export default ScheduleCard;
