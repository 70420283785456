import { useEffect, useState } from 'react';

import moment from 'moment';

import { useLocale } from 'shared/presentation/contexts';

import HourItem from './HourItem';
import {
  Container,
  HourPeriodTitle,
  HourPeriodWrapper,
  HoursWrapper,
} from './styles';
import { useScheduling } from '../../../context/SchedulingContext';
import { HOUR_12, HOUR_19 } from '../../../utils/constants';

const periodoPor = (hora: string) => {
  const horario = moment(hora, 'hh:mm');

  if (horario.isAfter(HOUR_19)) {
    return 'components.schedule_calendar.periods.evening';
  }

  if (horario.isAfter(HOUR_12)) {
    return 'components.schedule_calendar.periods.afternoon';
  }

  return 'components.schedule_calendar.periods.day';
};

type THourPerPeriod = {
  [key: string]: string[];
};

const HourSelector: React.FC = () => {
  const {
    selectedDate,
    selectedHour,
    hourAvailability,
    selectedDayPeriods,
    setSelectedHour,
    minDate,
  } = useScheduling();
  const { t } = useLocale('translations');
  const [periods, setPeriods] = useState<THourPerPeriod>({});

  useEffect(() => {
    const datesSelected = {
      resultData:
        typeof selectedDate === 'string'
          ? selectedDate
          : minDate.isBefore(selectedDate)
          ? selectedDate?.format('YYYY-MM-DD')
          : minDate?.format('YYYY-MM-DD'),
    };

    const availableHour = hourAvailability.find(
      v => v.data === datesSelected.resultData,
    );

    if (!availableHour) {
      return;
    }

    const tmpPeriods = availableHour.horarios.reduce(
      (porPeriodo: any, hora) => {
        const periodo = t(periodoPor(hora));

        return {
          ...porPeriodo,
          [periodo]: [...(porPeriodo[periodo] || []), hora],
        };
      },
      {},
    );

    setPeriods(tmpPeriods);
  }, [hourAvailability, selectedDate, selectedDayPeriods, minDate, t]);

  return (
    <Container>
      {Object.entries(periods).map(([key, hours]) => (
        <HourPeriodWrapper key={key}>
          <HourPeriodTitle variant="h5">{key}</HourPeriodTitle>
          <HoursWrapper>
            {hours.map(h => (
              <HourItem
                key={h}
                hour={h}
                onClick={setSelectedHour}
                selected={selectedHour === h}
              />
            ))}
          </HoursWrapper>
        </HourPeriodWrapper>
      ))}
    </Container>
  );
};

export default HourSelector;
