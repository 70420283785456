import { makeCustomersRepository } from 'shared/infra/repositories';

import FetchCustomerByIdUseCase from './implementations/FetchCustomerByIdUseCase';

let instance: FetchCustomerByIdUseCase | null;

export default function makeFetchCustomerByIdUseCase() {
  if (!instance) {
    const repository = makeCustomersRepository();

    instance = new FetchCustomerByIdUseCase(repository);
  }

  return instance;
}
