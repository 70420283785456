import { ContractsContextProvider } from 'context/ContractsContext';

import {
  Redirect,
  useActiveCustomer,
  useRouteQuery,
} from 'shared/presentation/contexts';

import ContractsWizard from './ContractsWizard';

const Contracts: React.FC = () => {
  const { hasCheckin } = useRouteQuery<'/contracts'>();
  const { customer } = useActiveCustomer();

  if (!customer) {
    return <Redirect to="/" params={{}} />;
  }

  return (
    <ContractsContextProvider
      userId={customer.id}
      userName={customer.fullName}
      hasCheckin={!!hasCheckin}
    >
      <ContractsWizard />
    </ContractsContextProvider>
  );
};

export default Contracts;
