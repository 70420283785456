import { useEffect } from 'react';

import useTimeout from './useTimeout';

type TParams = {
  timeout: number;
  onTimeUp?: () => void;
};

export default function useInteractionTimeout({ timeout, onTimeUp }: TParams) {
  const timer = useTimeout({ timeout, onTimeUp });

  useEffect(() => {
    window.addEventListener('click', timer.reset);
    window.addEventListener('touch', timer.reset);

    return () => {
      window.removeEventListener('click', timer.reset);
      window.removeEventListener('touch', timer.reset);
    };
  }, [timer.reset]);

  return timer;
}
