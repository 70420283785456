import styled, { css } from 'styled-components';

export const Container = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    width: ${theme.layout.spacing(75)};
    border-radius: 2rem;

    background-color: ${theme.palette.neutral[50]};
    color: ${theme.palette.text.main};

    padding: ${theme.layout.spacing(4)};
    ${theme.layout.rowGap(4)}

    header h1 {
      font-size: ${theme.typography.sizes.title2};
      font-weight: ${theme.typography.weight.extraBold};
      color: ${theme.palette.primary.dark};
      text-align: center;
    }

    p {
      font-size: ${theme.typography.sizes.subtitle};
      font-weight: ${theme.typography.weight.bold};
      text-align: center;
    }

    ul {
      display: flex;
      list-style: none;
      ${theme.layout.columnGap(2)}
      justify-content: center;

      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        ${theme.layout.rowGap(1.5)}

        width: ${theme.layout.spacing(8)};

        span {
          font-size: ${theme.typography.sizes.body2};
          font-weight: ${theme.typography.weight.regular};
          text-align: center;
        }

        &[data-selected='true'] {
          span {
            color: ${theme.palette.primary.main};
          }
        }
      }
    }

    caption {
      font-size: ${theme.typography.sizes.caption};
      font-weight: ${theme.typography.weight.regular};
      color: ${theme.palette.text.light};
      text-align: center;

      &.error {
        color: ${theme.palette.error.main};
      }
    }
  `}
`;

export const SelectAllButton = styled.button`
  ${({ theme }) => css`
    border: none;
    width: ${theme.layout.spacing(8)};
    height: ${theme.layout.spacing(8)};
    border-radius: 50%;

    color: ${theme.palette.text.main};
    background-color: ${theme.palette.neutral[200]};

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      font-size: 1.5rem;
    }
  `}
`;

export const AreaButton = styled(SelectAllButton)`
  ${({ theme }) => css`
    img {
      width: 100%;
      height: 100%;

      object-fit: cover;
    }

    &[data-selected='true'] {
      border: 1px solid ${theme.palette.primary.main};
    }
  `}
`;
