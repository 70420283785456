import { useTheme } from '@material-ui/core';

import DigitalIcon from 'assets/svgs/digital.svg';
import { useLocale } from 'shared/presentation/contexts';

import Button from '../../button';
import * as S from '../styles';

type TModalIdentifyProps = {
  openCpf(): void;
};

const ModalIdentifyValidation = ({ openCpf }: TModalIdentifyProps) => {
  const { t } = useLocale('translations');
  const theme = useTheme();

  return (
    <S.ModalWrapper>
      <S.TitleModal color={theme.colors.primary}>
        {t('components.modal.biometry_request.title')}
      </S.TitleModal>
      <S.SubTitleModal>
        {t('components.modal.biometry_request.subtitle')}
      </S.SubTitleModal>
      <img
        src={DigitalIcon}
        height="100"
        width="100"
        alt={t('components.modal.biometry_request.icon_alt') as string}
      />
      <S.Controls>
        <Button
          color={theme.colors.white}
          background={theme.colors.primary}
          onClick={openCpf}
          dataCy="cpfButton"
        >
          {t('components.modal.biometry_request.action')}
        </Button>
      </S.Controls>
    </S.ModalWrapper>
  );
};

export default ModalIdentifyValidation;
