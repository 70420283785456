import { keyframes } from 'styled-components';

export const CSSANIMATIONS = {
  HOVER: keyframes`
    0% {
      transform: translateY(-10%);
    }

    50% {
      transform: translateY(10%);
    }

    100% {
      transform: translateY(-10%);
    }
 `,
};
