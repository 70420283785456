import { FC } from 'react';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Slider, SliderItem, SliderItemCaption } from './styles';

type TCarouselItem = {
  src: any;
  alt: string;
  itemCaptionColor?: string;
};

type TCarouselProps = {
  items: TCarouselItem[];
  autoPlay?: boolean;
  showArrows?: boolean;
  swipeable?: boolean;
  emulateTouch?: boolean;
  infiniteLoop?: boolean;
  stopOnHover?: boolean;
  showIndicators?: boolean;
};

const Carousel: FC<TCarouselProps> = ({
  items,
  autoPlay,
  showArrows,
  swipeable,
  emulateTouch,
  infiniteLoop,
  stopOnHover,
  showIndicators,
}) => {
  return (
    <>
      <Slider
        autoPlay={autoPlay}
        showArrows={showArrows}
        swipeable={swipeable}
        emulateTouch={emulateTouch}
        infiniteLoop={infiniteLoop}
        stopOnHover={stopOnHover}
        interval={6000}
        showStatus={false}
        showIndicators={showIndicators}
      >
        {items.map(item => (
          <figure key={item.alt}>
            <SliderItem src={item.src} alt={item.alt} />
            {item.alt && (
              <SliderItemCaption color={item.itemCaptionColor}>
                {item.alt}
              </SliderItemCaption>
            )}
          </figure>
        ))}
      </Slider>
    </>
  );
};

export default Carousel;
