import React, { ComponentRef, useEffect, useRef, useState } from 'react';

import { useFormikContext } from 'formik';

import {
  VirtualKeyboard,
  useModal,
} from 'shared/presentation/components/atoms';
import { Button, TextInput } from 'shared/presentation/components/molecules';
import { MASKS } from 'shared/presentation/constants';
import { useLocale, useTheme } from 'shared/presentation/contexts';

import * as S from './styles';

type TTextInputRef = ComponentRef<typeof TextInput>;

const getKeyboardLayout = (input: (EventTarget & HTMLInputElement) | null) => {
  if (!input) return;

  if (input.type === 'email') return 'EMAIL';
  if (input.type === 'tel') return 'PHONE';
};

interface IUpdateDataFormProps {
  customer: {
    email?: string;
    phone?: string;
  };
}

const UpdateDataForm: React.FC<IUpdateDataFormProps> = ({ customer }) => {
  const { theme } = useTheme();
  const { t, language } = useLocale('translations');
  const [focusedInput, setFocusedInput] = useState<
    (EventTarget & HTMLInputElement) | null
  >(null);

  const emailRef = useRef<TTextInputRef>(null);
  const phoneRef = useRef<TTextInputRef>(null);

  const form = useFormikContext<Record<string, string>>();

  const modal = useModal();

  useEffect(() => {
    if (!modal.visible) return;

    const emailInput = emailRef.current?.getInput();

    if (emailInput) setFocusedInput(emailInput);
  }, [modal.visible]);

  const getFocusedInputData = () => {
    if (!focusedInput) return null;

    if (!(focusedInput.name in form.values)) return null;

    const refsDictionary: Record<string, React.RefObject<TTextInputRef>> = {
      phone: phoneRef,
      email: emailRef,
    };

    const inputName = focusedInput.name;
    const value = form.values[inputName];
    const ref = refsDictionary[inputName];

    return { value, ref };
  };

  const handleKeyPress = (button: string) => {
    const input = getFocusedInputData();

    if (!input) return;

    const newValue = input.value + button;

    input.ref.current?.change(newValue);
  };

  const handleBackspacePress = () => {
    const input = getFocusedInputData();

    if (!input) return;

    const newValue = input.value.substring(0, input.value.length - 1);

    input.ref.current?.change(newValue);
  };

  const inputBackground = theme.palette.neutral[50];

  const isKeyboardDisabled = !focusedInput;

  return (
    <>
      <header>
        <h1>{t('pages.checkin.update.title')}</h1>

        <p>{t('pages.checkin.update.description')}</p>
      </header>

      <S.Form>
        <section>
          <TextInput
            ref={emailRef}
            name="email"
            label={t('pages.checkin.update.email')}
            type="email"
            placeholder="email@gmail.com"
            onFocus={event => setFocusedInput(event.target)}
            backgroundColor={inputBackground}
            autofocus
          />

          {customer.email && (
            <S.DefaultDataButton
              type="button"
              onClick={() => form.setFieldValue('email', customer.email)}
            >
              {customer.email}
            </S.DefaultDataButton>
          )}

          <TextInput
            ref={phoneRef}
            name="phone"
            label={t('pages.checkin.update.phone')}
            type="tel"
            placeholder="(00) 00000-0000"
            onFocus={event => setFocusedInput(event.target)}
            backgroundColor={inputBackground}
            mask={MASKS.PHONE[language]}
          />

          {customer.phone && (
            <S.DefaultDataButton
              type="button"
              onClick={() =>
                form.setFieldValue('phone', customer.phone?.replace(/\D/g, ''))
              }
            >
              {customer.phone}
            </S.DefaultDataButton>
          )}

          <VirtualKeyboard
            onKeyPress={handleKeyPress}
            onBackspacePress={handleBackspacePress}
            layout={getKeyboardLayout(focusedInput)}
            disabled={isKeyboardDisabled}
          />
        </section>

        <footer>
          <Button type="submit" variant="primary">
            {t('pages.checkin.confirm.update')}
          </Button>

          <Button
            type="button"
            variant="primary"
            outline
            onClick={() => {
              modal.close();
              form.resetForm();
            }}
          >
            {t('components.modal.actions.cancel')}
          </Button>
        </footer>
      </S.Form>
    </>
  );
};

export default UpdateDataForm;
