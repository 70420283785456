import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    ${theme.layout.columnGap(2)}
    padding: ${theme.layout.spacing(1.5, 2)};

    width: ${theme.layout.spacing(31)};

    background: ${theme.palette.neutral[100]};
    border-radius: 22px;

    > span {
      color: ${theme.palette.text.main};
      font-size: ${theme.typography.sizes.body2};
      font-weight: ${theme.typography.weight.regular};
    }
  `}
`;

export const CircularProgress = styled.div<{ progress: number }>`
  ${({ theme, progress }) => css`
    display: flex;
    justify-content: center;
    align-items: center;

    flex-shrink: 0;

    transition: all 0.2s;

    width: ${theme.layout.spacing(5)};
    height: ${theme.layout.spacing(5)};
    border-radius: 50%;
    background: conic-gradient(
      ${theme.palette.primary.main} ${Math.round(progress)}%,
      transparent 0
    );
  `}
`;
