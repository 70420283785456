import { Namespace } from 'i18next';
import { useTranslation } from 'react-i18next';

import { TLocale } from 'shared/domain/valueObjects';

export default function useLocale<T extends Namespace>(namespace?: T) {
  const { t, i18n } = useTranslation(namespace);
  const shared = useTranslation('shared');

  return { t, sharedT: shared.t, language: i18n.language as TLocale };
}
