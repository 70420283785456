export default {
  termInformation:
    'Informações Fundamentais: (I) São necessárias, em média, até 10(dez) sessões para obter um resultado satisfatório; (II) O número de sessões pode variar de pessoa para pessoa; (III) Distúrbio hormonal, cor da pele, cor do pelo, espessura do pelo, podem influenciar no número de sessões, bem como na eliminação parcial ou completa dos pelos para a região contratada.',
  termParagraph1:
    '01. O presente contrato tem por OBJETO a prestação de serviço de depilação a laser pela Vialaser diretamente ao Contratante.',
  termParagraph2:
    '02. O Contratante obriga-se: (I) A agendar as sessões previamente; (II) A comparecer às sessões no horário agendado; (III) A respeitar o intervalo entre das sessões; (IV) A efetuar o pagamento do preço pontualmente e nas condições ajustadas.',
  termParagraph3:
    '03. A Vialaser compromete-se a prestar o serviço contratado pelo Contratante com profissionalismo, dedicação e respeito.',
  termParagraph4:
    '04. A Vialaser obriga-se a executar todas as sessões contratadas pelo Contratante.            ',
  termParagraph5:
    '05. A depilação a laser será realizada em sessões periódicas, com intervalos definidos pelo profissional responsável. O intervalo entre as sessões varia de acordo com o crescimento do pelo e a área a ser tratada.',
  termParagraph6:
    '06. As sessões de depilação a laser devem ser agendadas pelo Contratante, pessoalmente, por email ou telefone, de acordo com a disponibilidade de agenda da Vialaser.',
  termParagraph7:
    '07. Caso o Contratante não possa comparecer a qualquer sessão agendada deverá comunicar a Vialaser com antecedência mínima de 24 horas.',
  termParagraph8:
    '08. A falta injustificada do Contratante a qualquer uma das sessões será computada como parte do tratamento contratado. Nesse caso, as sessões somente poderão ser repostas mediante pagamento avulso, de acordo com a tabela de preços da época da reposição.',
  termParagraph9:
    '09. O Contratante obriga-se a pagar a Vialaser a totalidade do preço ajustado, nas exatas condições avençadas neste instrumento. No caso de atraso ou inadimplementos do Contratante, a Vialaser reserva-se do direito de não atender o Contratante até a regularização do(s) pagamento(s) em atraso.',
  termParagraph10:
    '10. O Contratante autoriza a Vialaser a realizar o tratamento estético de depilação a laser ora contratado.',
  termParagraph11:
    '11. O presente contrato é pessoal e intransferível, ou seja, o tratamento ora contratado não pode ser utilizado por outra pessoa.',
  termParagraph12:
    '12. Na hipótese de desistência por iniciativa do Contratante, o mesmo obriga-se a solicitar o cancelamento por escrito. Após o recebimento da solicitação de cancelamento por escrito, a Vialaser deverá restituir o saldo em até 30 (trinta) dias ao Contratante. O Contratante não fará jus à restituição dos valores despendidos com sessões já realizadas. O Contratante perderá o direito ao desconto ganho, caso tenha adquirido pacote promocional. A Vialaser se obriga a restituir ao Contratante apenas a quantia equivalente a 70% (setenta por cento) do saldo do valor do tratamento contratado e não usufruído. Tal percentual se justifica por conta dos custos com despesas administrativas, tributos, comissões, etc.',
  termParagraph13:
    '13. O Contratante declara-se ciente das INFORMAÇÕES e ADVERTÊNCIAS descritas a seguir: (I) No período que intermedia as sessões não é permitido realizar procedimentos que arranquem os pelos pela raiz, assim como a descoloração dos mesmos; (II) Após a aplicação do laser podem ocorrer: ardência e irritação da pele por algumas horas, apresentando uma coloração mais rosada na pele; (III) Em caso de uso de algum tipo de ácido na região a ser depilada, é necessário interromper o uso do produto 05 (cinco) dias antes e depois de cada sessão; (IV) O bronzeamento deve ser evitado durante todo o tratamento, seja através do sol, câmaras de bronzeamento ou com auto-bronzeadores; (V) Caso o bronzeamento seja inevitável, o Contratante deve respeitar um período de 3 (três) dias após a aplicação do laser para bronzear-se, da mesma forma que após o bronzeamento deve esperar um período mínimo de 30 (trinta) dias para fazer uma nova aplicação de laser; (VI) Ao se expor ao sol, é imprescindível utilizar protetor ou bloqueador solar, com FPS 30 ou mais, durante todo o tratamento; (VII) O aparecimento de crosta hipercrômica (“casquinha” escura) apesar de raro, pode ocorrer, principalmente em peles naturalmente pigmentadas ou recentemente bronzeadas; (VIII) Esta crosta tende a desaparecer sem deixar manchas ou cicatrizes, ao longo do tempo; (IV) Manchas temporárias, queimaduras, alergias ou bolhas apesar de serem raros também podem surgir, tendo a tendência a desaparecer com tratamentos ou cuidados com a exposição solar; (X) O laser não deve ser aplicado em caso de gravidez, ficando o Contratante responsável por informar a Vialaser desta situação; (XI) Após a aplicação do laser sempre seguir as recomendações da Vialaser.',
  termParagraph14:
    '14. O Contratante fica obrigado a informar à Vialaser sobre o uso de qualquer medicamento, antes do início do tratamento ou durante o mesmo. Há medicamentos que podem interferir no resultado do tratamento de depilação a laser.',
  termParagraph15:
    '15. Em caso de atraso no(s) pagamento(s), o débito poderá ser encaminhado para protesto e o nome do devedor inscrito nos órgãos de proteção ao crédito.',
  termParagraph16:
    '16. As partes reconhecem como válidas e aceitam que as comunicações entre as partes serão feitas por e-mail (informado no cadastro do cliente), bem como, consideram legais e íntegros os documentos eletrônicos produzidos pelas partes, sendo válido com a aposição da impressão digital do cliente (biometria), em contratos, aditivos e fichas de procedimentos e comparecimentos, nos termos do art. 10, §2º, da MP 2002-2/2001.',
  termParagraph17:
    '17. O Contratante DECLARA EXPRESSAMENTE QUE LEU E ENTENDEU TODAS AS CLÁUSULAS DO PRESENTE CONTRATO, inclusive as advertências, informações, cuidados especiais e, principalmente, declara expressamente que foi alertado de todos os cuidados que deve ter antes, durante e após o tratamento. Caso haja inobservância às informações, advertências e aos cuidados especiais, a Vialaser não poderá ser responsabilizada por eventuais danos ocorridos e/ou sofridos pelo Contratante.',
  termParagraph18:
    '18. Art. 49 (CDC)- O consumidor pode desistir do contrato, no prazo de 07 (sete) dias a contar de sua assinatura ou do ato de recebimento do produto ou serviço, sempre que a contratação de fornecimento de produtos e serviços ocorrer fora do estabelecimento comercial, especialmente por telefone ou a domicílio.',
};
