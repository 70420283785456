import { ModalTypes } from 'enums/ModalTypes';

import DigitalIcon from 'assets/svgs/digital.svg';
import { useLocale } from 'shared/presentation/contexts';

import * as S from './styles';
import { SubTitleModal, TitleModal } from '../styles';

type TModalDefaultErrorProps = {
  errorType: string;
};

const ModalDefaultError = ({ errorType }: TModalDefaultErrorProps) => {
  const { t } = useLocale('translations');

  const convertErrorType = (type: string) => {
    switch (type) {
      case ModalTypes.CHECKIN_JA_REALIZADO: {
        return 'checkin_already_done';
      }
      case ModalTypes.SEM_AGENDAMENTO: {
        return 'no_schedules';
      }
      case ModalTypes.NAO_AGENDADO_UNIDADE: {
        return 'no_schedules_unit';
      }
      case ModalTypes.NAO_AGENDADO_DIA: {
        return 'no_schedules_day';
      }
      default:
        return 'no_schedules';
    }
  };

  return (
    <S.Wrapper>
      <TitleModal>
        {t(`components.modal.${convertErrorType(errorType)}.title`)}
      </TitleModal>
      <SubTitleModal>
        {t(`components.modal.${convertErrorType(errorType)}.subtitle`)}
      </SubTitleModal>
      <img
        src={DigitalIcon}
        height="100"
        width="100"
        alt={
          t(
            `components.modal.${convertErrorType(errorType)}.icon_alt`,
          ) as string
        }
      />
    </S.Wrapper>
  );
};

export default ModalDefaultError;
