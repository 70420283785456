import React from 'react';

import { useLocale } from 'shared/presentation/contexts';

import DigitalIcon from '../../../assets/svgs/digital.svg';
import { ModalWrapper, SubTitleModal, TitleModal } from '../styles';

const ModalBiometryValidation = () => {
  const { t } = useLocale('translations');

  const alt = t('components.modal.biometry_error.icon_alt');

  return (
    <ModalWrapper>
      <TitleModal>{t('components.modal.biometry_error.title')}</TitleModal>
      <SubTitleModal>
        {t('components.modal.biometry_error.subtitle')}
      </SubTitleModal>
      <img src={DigitalIcon} height="100" width="100" alt={alt} />
    </ModalWrapper>
  );
};

export default ModalBiometryValidation;
