import IHTTPProvider from 'shared/domain/providers/IHTTPProvider';
import { TLocale } from 'shared/domain/valueObjects';

import AxiosHTTPProvider from './implementations/AxiosHTTPProvider';

let instance: IHTTPProvider | null = null;

export default function makeHTTPProvider(
  locale: TLocale = 'pt',
): IHTTPProvider {
  if (!instance) {
    instance = new AxiosHTTPProvider(locale);
  }

  return instance;
}
