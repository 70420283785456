import styled, { css } from 'styled-components';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    border-radius: 2rem;
    padding: ${theme.layout.spacing(4)};
    width: ${theme.layout.spacing(75)};

    background-color: ${theme.palette.neutral[50]};

    ${theme.layout.rowGap(5)}

    header {
      display: flex;
      flex-direction: column;

      ${theme.layout.rowGap(3)}

      h1 {
        color: ${theme.palette.primary.main};
        font-weight: ${theme.typography.weight.bold};
        font-size: ${theme.typography.sizes.title2};
      }

      p {
        color: ${theme.palette.text.light};
        line-height: 150%;
        font-size: ${theme.typography.sizes.body1};
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      ${theme.layout.rowGap(3)}

      li {
        display: flex;
        align-items: center;
        ${theme.layout.columnGap(3)}

        svg {
          color: ${theme.palette.primary.main};
          font-size: 1.5rem;
        }

        span {
          color: ${theme.palette.text.main};
          line-height: 150%;
          font-size: ${theme.typography.sizes.body1};
          font-weight: ${theme.typography.weight.bold};
        }
      }
    }

    footer {
      display: flex;
      flex-direction: column;
      ${theme.layout.rowGap(3)}
    }
  `}
`;
