import { useState } from 'react';

import { ICustomer } from 'shared/domain/entities';

import { ActiveCustomerContext } from '../../hooks/useActiveCustomer';

export type TTenancy = 'vialaser' | 'vialaserparaguay' | 'vialasercostarica';

const ActiveCustomerProvider: React.FC = ({ children }) => {
  const [customer, setCustomer] = useState<ICustomer | null>(null);

  return (
    <ActiveCustomerContext.Provider
      value={{
        customer,
        setCustomer,
      }}
    >
      {children}
    </ActiveCustomerContext.Provider>
  );
};

export default ActiveCustomerProvider;
