import { useState, useEffect, useMemo } from 'react';

import { CircularProgress } from '@material-ui/core';
import { FiX, FiArrowLeft } from 'react-icons/fi';

import { makeHTTPProvider } from 'shared/infra/providers';
import { MaskFormatter, MASKS } from 'shared/presentation/constants';
import { useLocale, useUnit } from 'shared/presentation/contexts';

import FinishCheckinModal from './components/FinishCheckinModal';
import SuccessModal from './components/SuccessModal';
import * as S from './styles';

const NUMBERS = ['1', '2', '3', '4', '5', '6', '7', '8', '9'];
const DEV_CPF = process.env.NODE_ENV === 'development' ? '10852040733' : '';

const QRCodeCheckin: React.FC = () => {
  const { t, language } = useLocale('translations');

  const [error, setError] = useState<string>('');
  const [keyboardValue, setKeyboardValue] = useState<string>(DEV_CPF);
  const [maskedKeyboardValue, setMaskedKeyboardValue] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [finishCheckinMessage, setFinishCheckinMessage] = useState<string>('');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const [showFinishCheckinModal, setShowFinishCheckinModal] =
    useState<boolean>(false);

  const { unit } = useUnit();

  const handleCancelCheckin = () => {
    setFinishCheckinMessage(t('pages.qrcodeCheckin.canceled') as string);
    setShowFinishCheckinModal(true);
  };

  const handleClickKey = (key: string) => {
    const newKeyboardValue = keyboardValue + key;

    if (language === 'es') {
      if (newKeyboardValue.length <= 11) {
        setKeyboardValue(newKeyboardValue);
      }
    } else {
      if (newKeyboardValue.length <= 11) {
        setKeyboardValue(newKeyboardValue);
      }
    }
  };

  const handleCheckin = async () => {
    if (!unit) return;

    try {
      setError('');
      setIsLoading(true);

      const response = await makeHTTPProvider().post<{ cliente: string }>(
        'checkin',
        {
          cpf: keyboardValue,
          unidade: unit.id,
          origem: 'QRCODE',
        },
      );

      setIsLoading(false);

      setSuccessMessage(
        t('pages.qrcodeCheckin.success', {
          name: response.cliente,
        }) as string,
      );
      setShowSuccessModal(true);

      setTimeout(() => {
        setFinishCheckinMessage(t('pages.qrcodeCheckin.finished') as string);
        setShowSuccessModal(false);
        setShowFinishCheckinModal(true);
      }, 10000);
    } catch (e) {
      setIsLoading(false);
      const err = e as any;

      if (err.response && err.response.data) {
        const { data } = err.response;

        if (data.message) {
          setError(data.message);
        } else {
          if (data.type && data.type === 'CHECKIN_JA_REALIZADO') {
            setError(t('pages.qrcodeCheckin.alreadyMadeCheckin') as string);
          } else if (data.type && data.type === 'CONTRATO_NAO_ASSINADO') {
            setError(t('pages.qrcodeCheckin.hasContracts') as string);
          } else {
            setError(t('pages.qrcodeCheckin.error') as string);
          }
        }
      } else {
        setError(t('pages.qrcodeCheckin.error') as string);
      }
    }
  };

  const formatter = useMemo(
    () => new MaskFormatter(MASKS.DOCUMENT[language]),
    [language],
  );

  useEffect(() => {
    if (!unit) {
      setFinishCheckinMessage(t('pages.qrcodeCheckin.accessDenied') as string);
      setShowFinishCheckinModal(true);
    }
  }, [unit, t]);

  useEffect(() => {
    setMaskedKeyboardValue(formatter.format(keyboardValue));
  }, [formatter, keyboardValue]);

  return (
    <S.Container>
      <div>
        <S.IconButton onClick={handleCancelCheckin}>
          <FiX />
        </S.IconButton>

        <S.Title>{t('pages.qrcodeCheckin.title')}</S.Title>

        <S.Subtitle>
          {t('pages.qrcodeCheckin.subtitle', { unit: unit?.id || '...' })}
        </S.Subtitle>
      </div>

      <S.Content>
        <S.Display
          placeholder={t('pages.qrcodeCheckin.display') as string}
          value={maskedKeyboardValue}
          disabled={true}
        />

        <S.Divider />

        <S.Keyboard>
          {NUMBERS.map(number => (
            <S.Key key={number} onClick={() => handleClickKey(number)}>
              {number}
            </S.Key>
          ))}

          <S.Key onClick={() => setKeyboardValue('')}>
            <FiX />
          </S.Key>

          <S.Key onClick={() => handleClickKey('0')}>0</S.Key>

          <S.Key onClick={() => setKeyboardValue(keyboardValue.slice(0, -1))}>
            <FiArrowLeft />
          </S.Key>
        </S.Keyboard>

        {error && <S.Error>{error}</S.Error>}
      </S.Content>

      <S.Footer>
        <S.Button
          disabled={!formatter.isValid(keyboardValue)}
          onClick={handleCheckin}
        >
          {!isLoading ? (
            t('pages.qrcodeCheckin.checkin')
          ) : (
            <CircularProgress color="inherit" size={21} />
          )}
        </S.Button>

        <S.HelpText>{t('pages.qrcodeCheckin.help')}</S.HelpText>
      </S.Footer>

      <SuccessModal visible={showSuccessModal} message={successMessage} />
      <FinishCheckinModal
        visible={showFinishCheckinModal}
        message={finishCheckinMessage}
      />
    </S.Container>
  );
};

export default QRCodeCheckin;
