import { styled, Theme } from '@material-ui/core/styles';
import { Carousel } from 'react-responsive-carousel';

type TSliderItemCaptionType = {
  theme: Theme;
  color?: string;
};

export const Slider = styled(Carousel)(({ theme }) => ({
  '& .control-dots': {
    '& .dot': {
      background: `${theme.colors.gray} !important`,
      width: '12px !important',
      height: '12px !important',
      boxShadow: '1px 1px 2px rgba(0,0,0,0.4) !important',
      outline: 0,
    },
  },
  '& .carousel': {
    '& .thumbs-wrapper': {
      height: 0,
      margin: 0,
    },
  },
  '& .slide': {
    padding: '2.3rem 0',
    background: 'transparent !important',
  },
  '& figcaption': {},
}));

export const SliderItem = styled('img')({
  maxHeight: '124px',
  width: 'auto',
});

export const SliderItemCaption = styled('figcaption')(
  ({ theme, color }: TSliderItemCaptionType) => ({
    color: color || theme.colors.gray,
    fontWeight: 700,
  }),
);
