import { styled } from '@material-ui/core/styles';

export const Checkin = styled('div')(({ theme }) => ({
  height: '100vh',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  color: theme.colors.gray,
  background: theme.colors.white,
}));

export const Main = styled('div')(({ theme }) => ({
  flex: 0.95,
  height: '100%',
  background: theme.colors.background,
  '& video, & #lisa': {
    position: 'fixed',
    bottom: 0,
    width: 600,
  },
}));

export const Actions = styled('div')(({ isSpanishLanguage }: any) => ({
  width: isSpanishLanguage ? 835 : 650,
  gridArea: 'actions',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'absolute',
  top: 550,
  left: isSpanishLanguage ? 500 : 570,

  '& canvas': {
    zIndex: 1,
    marginRight: 100,
  },
}));

export const Menu = styled('div')({
  position: 'relative',
  flex: 0.25,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '0 24px',
  height: '100%',
  '& button': {
    margin: '16px 0',
  },
});

export const LisaPopup = styled('div')({
  position: 'absolute',
  top: 120,
  left: 400,
});

export const Fingerprint = styled('img')({
  position: 'fixed',
  bottom: 50,
  left: 'calc(50% - 3rem)',
  width: '6rem',

  animationName: 'fingerprint-animation',
  animationDuration: '1.3s',
  animationTimingFunction: 'cubic-bezier(.46,.03,.52,.96)',
  animationIterationCount: 'infinite',
});

export const ButtonTouch = styled('div')({
  width: '100%',
  zIndex: 50,
  '& svg': {
    position: 'absolute',
    right: -10,
    marginRight: '-5px',
    marginTop: '-45px',
  },
});

export const ErrorAlert = styled('div')(() => ({
  position: 'absolute',
  bottom: 0,
  left: '50%',
  transform: 'translateX(-50%)',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  rowGap: '1rem',
  padding: '2rem 0',
  color: '#ed1c1c',
  fontSize: '1.25rem',
  width: '100%',

  '& svg': {
    fontSize: '1.45rem',
  },

  '& span': {
    fontWeight: 500,
  },
}));
