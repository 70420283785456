import io from 'socket.io-client';

import IWebSocketProvider from 'shared/domain/providers/IWebSocketProvider';

export default class IOWebSocketProvider implements IWebSocketProvider {
  private connection: SocketIOClient.Socket;

  constructor(url: string) {
    this.connection = io.connect(url, { reconnection: true });
  }

  public emit(event: string, data?: unknown) {
    this.connection.emit(event, data);
  }

  public addEventListener<T = unknown>(
    event: string,
    listener: (response: T) => void,
  ) {
    this.connection.on(event, (payload: unknown) => {
      const parsed =
        typeof payload === 'string' ? JSON.parse(payload) : payload;

      listener(parsed);
    });
  }

  public removeEventListener(event: string) {
    this.connection.removeEventListener(event);
  }

  public removeAllListeners() {
    this.connection.removeAllListeners();
  }
}
