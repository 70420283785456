import { useFindCustomerById } from 'pages/Checkin/hooks';

import { useLocale, useNavigation } from 'shared/presentation/contexts';

import * as S from './styles';
import Receipt from '../../../assets/svgs/receipt.svg';
import theme from '../../../styles/theme';
import { TCheckinNotSignedResponse } from '../../../types/api';
import Button from '../../button';

type TModalUnassignedContractProps = {
  info: Partial<TCheckinNotSignedResponse>;
};

const ModalUnassignedContractValidation = ({
  info,
}: TModalUnassignedContractProps) => {
  const { t } = useLocale('translations');
  const controller = useFindCustomerById();
  const navigation = useNavigation();

  return (
    <S.Content>
      <S.Title>
        {t('components.modal.unassigned_contract_validation.title', {
          name: info.client || '',
          description: info.contractDescription || '',
        })}
      </S.Title>
      <S.Subtitle>
        {t('components.modal.unassigned_contract_validation.subtitle')}
      </S.Subtitle>
      <Button
        color={theme.colors.white}
        background={theme.colors.primary}
        icon={<img src={Receipt} alt="icon" />}
        onClick={async () => {
          if (!info?.clientId || !info?.contractId) return;

          const customer = await controller.find(info.clientId);

          if (!customer) return;

          navigation.push('/contracts', { hasCheckin: true });
        }}
      >
        {t('components.modal.unassigned_contract_validation.sign_contract')}
      </Button>
    </S.Content>
  );
};

export default ModalUnassignedContractValidation;
