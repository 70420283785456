import {
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
} from 'react';

import { useLocale } from 'shared/presentation/contexts';
import { useInteractionTimeout } from 'shared/presentation/hooks';

import * as S from './styles';

const TIMEOUT = 60;

interface IClockTimerRef {
  reset(): void;
}

interface IClockTimerProps {
  onTimeUp?: () => void;
}

const ClockTimer: ForwardRefRenderFunction<IClockTimerRef, IClockTimerProps> = (
  { onTimeUp },
  ref,
) => {
  const { t } = useLocale('shared');

  const timer = useInteractionTimeout({ timeout: TIMEOUT, onTimeUp });

  useImperativeHandle(ref, () => ({ reset: () => timer.reset() }), [timer]);

  if (timer.timeLeft === 0) {
    return null;
  }

  const value = (timer.timeLeft * 100) / TIMEOUT;

  return (
    <S.Container>
      <S.CircularProgress progress={value} />

      <span>{t('components.timer.title')}</span>
    </S.Container>
  );
};

export default forwardRef(ClockTimer);
