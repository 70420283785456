import React from 'react';

import { useLocale } from 'shared/presentation/contexts';

import RegionCard from './RegionCard';
import RegionSelectionFooter from './RegionSelectorFooter';
import * as S from './styles';
import { useScheduling } from '../../../../context/SchedulingContext';
import LoadingSpiner from '../../../LoadingSpiner';
import MultiCarousel from '../../../MultiCarousel';

const RegionSelector: React.FC = () => {
  const { availableAreas, loading } = useScheduling();
  const { t } = useLocale('translations');

  if (loading) {
    return <LoadingSpiner />;
  }

  if (!availableAreas.length) {
    return (
      <S.ContainerCard>
        <S.NoAreasText>{t('pages.schedules.noAreas')}</S.NoAreasText>
      </S.ContainerCard>
    );
  }

  return (
    <>
      <S.ContainerCard>
        <MultiCarousel>
          {availableAreas.map(item => (
            <RegionCard key={item.id} schedule={item} />
          ))}
        </MultiCarousel>
      </S.ContainerCard>
      <RegionSelectionFooter />
    </>
  );
};

export default RegionSelector;
