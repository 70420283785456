import { Fade } from '@material-ui/core';

import * as S from './styles';

const FinishCheckinModal: React.FC<{ visible: boolean; message: string }> = ({
  visible,
  message,
}) => {
  return (
    <Fade in={visible}>
      <S.Container>
        <S.Title>{message}</S.Title>
      </S.Container>
    </Fade>
  );
};

export default FinishCheckinModal;
