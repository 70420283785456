import { useMutation } from 'react-query';

import { makeCheckinCustomerUseCase } from 'shared/domain/useCases';
import { useUnit } from 'shared/presentation/contexts';

type TParams = {
  onSuccess(params: { recommendationUrl?: string; customerName: string }): void;
  onError?: (error: unknown) => void;
};

export default function usePerformCheckin(params: TParams) {
  const { unit } = useUnit();

  const mutation = useMutation(
    (params: { customerId?: number; customerDocument?: string }) => {
      if (!unit) throw new Error();

      return makeCheckinCustomerUseCase().execute({
        unitId: unit.id,
        customer: {
          id: params.customerId,
          document: params.customerDocument,
        },
      });
    },
    {
      onSuccess: params.onSuccess,
      onError: params.onError,
    },
  );

  return {
    loading: mutation.isLoading,
    checkin: mutation.mutateAsync,
  };
}
