import { Buffer } from 'buffer';

import IHashProvider from 'shared/domain/providers/IHashProvider';

export default class Base64HashProvider implements IHashProvider {
  public encode(data: unknown): string {
    const stringified = JSON.stringify(data);

    return Buffer.from(stringified).toString('base64');
  }

  public decode<T>(hash: string): T {
    const decoded = Buffer.from(hash, 'base64').toString('utf-8');

    return JSON.parse(decoded) as T;
  }
}
