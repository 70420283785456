import IWebSocketProvider from 'shared/domain/providers/IWebSocketProvider';
import ICustomerBiometryPubsub from 'shared/domain/pubsubs/ICustomerBiometryPubsub';

const EVENTS = {
  FAILURE: 'tentativaLeituraConfirmacao',
};

type TFirstParameter<T extends (...args: any) => any> = Parameters<T>[0];

type TBiometryEvent = 'BIOMETRIA_NAO_CONFIRMADA';
type TEvent = TFirstParameter<
  TFirstParameter<ICustomerBiometryPubsub['subscribeForBiometryErrorMessages']>
>['event'];

const BIOMETRY_EVENTS: Record<TBiometryEvent, TEvent> = {
  BIOMETRIA_NAO_CONFIRMADA: 'NOT_CONFIRMED',
};

export default class V1CustomerBiometryPubsub
  implements ICustomerBiometryPubsub
{
  constructor(private webSocketProvider: IWebSocketProvider) {}

  public subscribeForBiometryErrorMessages: ICustomerBiometryPubsub['subscribeForBiometryErrorMessages'] =
    subscription => {
      this.webSocketProvider.addEventListener<{ evento: TBiometryEvent }>(
        EVENTS.FAILURE,
        payload => {
          if (!(payload.evento in BIOMETRY_EVENTS)) return;

          subscription({ event: BIOMETRY_EVENTS[payload.evento] });
        },
      );

      return () => {
        this.webSocketProvider.removeEventListener(EVENTS.FAILURE);
      };
    };
}
