const DEFAULT = {
  default: [
    'q w e r t y u i o p',
    'a s d f g h j k l ç',
    '{shift} z x c v b n m {bksp}',
    '{numberPad} , {space} . {enter}',
  ],
  shift: [
    'Q W E R T Y U I O P',
    'A S D F G H J K L Ç',
    '{shift} Z X C V B N M {bksp}',
    '{numberPad} , {space} . {enter}',
  ],
  numberPad: [
    '1 2 3 4 5 6 7 8 9 0',
    '@ # $ _ & - + ( ) /',
    '{symbols} * " \' : ; ! ? {bksp}',
    '{default} , {space} . {enter}',
  ],
  symbols: [
    '| • √ π ÷ × § Δ',
    '£ ¢ € ¥ ° = { } \\',
    '{symbols} % © ® ™ [ ] {bksp}',
    '{default} < {space} > {enter}',
  ],
};

const EMAIL = {
  ...DEFAULT,
  default: [
    'q w e r t y u i o p',
    'a s d f g h j k l ç',
    '{shift} z x c v b n m {bksp}',
    '{numberPad} .com @ {space} . {enter}',
  ],
  shift: [
    'Q W E R T Y U I O P',
    'A S D F G H J K L Ç',
    '{shift} Z X C V B N M {bksp}',
    '{numberPad} .com , {space} . {enter}',
  ],
};

const PHONE = {
  default: ['1 2 3', '4 5 6', '7 8 9', '{bksp} 0 {enter} {clear}'],
};

export const LAYOUTS = { DEFAULT, EMAIL, PHONE };
