import { TModifierState } from '../types';

export default function getCurrentPressedButtonts(state: TModifierState) {
  const buttons: string[] = [];

  Object.entries(state).forEach(([key, value]) => {
    if (!value) return;

    buttons.push(`{${key}}`);
  });

  return buttons.join(' ');
}
