import { styled } from '@material-ui/core/styles';

export const DayPeriodWrapper = styled('div')({
  height: '100%',
  display: 'grid',
  alignContent: 'center',
  gridTemplateRows: 'repeat(3, 14rem)',
});

export const CalendarWrapper = styled('div')({});
export const HourSelectorWrapper = styled('div')(({ active }: any) => ({
  animationName: 'hour-selector-animation',
  animationDuration: '0.5s',
  animationTimingFunction: 'cubic-bezier(.46,.03,.52,.96)',
  animationIterationCount: active ? 'infinite' : 0,
}));

export const SchedulingContainer = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 2.5fr 1fr',
  alignItems: 'start',
  justifyItems: 'center',
  height: '100%',
});

export const Logo = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '6rem',
  padding: '1rem',
  backgroundColor: theme.colors.background,
  '& img': {
    width: '10rem',
  },
}));

export const Title = styled('h3')(({ theme }) => ({
  width: '100%',
  padding: '0 2rem',
  fontSize: '2rem',
  color: theme.colors.gray,
  background: 'transparent',
  borderBottom: `1px solid ${theme.colors.lightGray}`,
}));

export const Loading = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const Schedules = styled('div')({
  padding: '3rem 2rem',
});

export const NoSchedules = styled('h1')(({ theme }) => ({
  height: '3rem',
  fontSize: '3rem',
  color: theme.colors.primary,
  margin: '4rem 0 0 4rem',
}));

export const Actions = styled('div')({
  height: '4rem',
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 14rem)',
  justifyContent: 'center',
  gap: '1rem',
  marginTop: '2rem',
});
