import moment from 'moment';
import { HiOutlineLocationMarker } from 'react-icons/hi';

import { useLocale } from 'shared/presentation/contexts';

import * as S from './styles';
import unitImage from '../../../../assets/svgs/areas.svg';
import { useScheduling } from '../../../../context/SchedulingContext';
import theme from '../../../../styles/theme';
import { TSchedule } from '../../../../types/api';
import { formatDate } from '../../../../utils/functions';
import RegionsSchedules from '../RegionsSchedules';

type TScheduleProps = {
  schedule: TSchedule;
};

const parseTime = (time: string | undefined, duration: moment.Duration) => {
  const start = moment(time, 'HH:mm');
  return `${start.locale('en').format('HH:mm')} - ${start
    .add(duration, 'minutes')
    .locale('en')
    .format('HH:mm')}`;
};

const SuccessCard: React.FC<TScheduleProps> = ({ schedule }) => {
  const {
    availableAreas,
    selectedDate,
    selectedHour,
    sessionDuration,
    selectedAreas,
  } = useScheduling();
  const { t } = useLocale('translations');

  return (
    <S.Schedule>
      <S.Title>
        {t('pages.schedules.card.scheduleDate')}
        <strong>
          {' '}
          {typeof selectedDate === 'string'
            ? formatDate(selectedDate)
            : selectedDate?.format('DD/MM/YYYY')}{' '}
          às {parseTime(selectedHour, sessionDuration)}
        </strong>
      </S.Title>

      {availableAreas.length > 0 && (
        <>
          <S.AreasTitle>
            {t('pages.schedules.card.scheduledAreas')}
          </S.AreasTitle>

          <S.Areas>
            {availableAreas.map(area =>
              selectedAreas[area.id] ? (
                <RegionsSchedules key={area.id} schedule={area} />
              ) : null,
            )}
          </S.Areas>
        </>
      )}

      <S.InfosWrap>
        <S.Info>
          <S.InfoIcon>
            <img src={unitImage} alt="unit" />
          </S.InfoIcon>
          <strong>{t('pages.schedules.card.unit')}</strong>
          <span>{schedule.unit}</span>
        </S.Info>

        <S.Info>
          <S.InfoIcon>
            <HiOutlineLocationMarker size={32} color={theme.colors.white} />
          </S.InfoIcon>
          <strong>{t('pages.schedules.card.location')}</strong>
          <span>{schedule.location}</span>
        </S.Info>
      </S.InfosWrap>
    </S.Schedule>
  );
};

export default SuccessCard;
