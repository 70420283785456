export const ptLocale = {
  common: {
    logo_alt: 'Logo da Vialaser Depilação',
    actions: {
      select: 'Selecionar',
    },
    states: {
      selected: 'Selecionado',
    },
  },
  components: {
    wizard: {
      buttons: {
        next: 'Próxima Etapa',
        finish: 'Finalizar',
        previous: 'Voltar',
        scheduleContinue: 'Continuar Agendando',
        exit: 'Sair',
        select_all: 'Selecionar Todos',
        unselect_all: 'Desselecionar Todos',
        select_day_hour: 'Você precisa selecionar um dia e horário!',
      },
    },
    numberPad: {
      title: 'Digite seu CPF',
      actions: {
        clear: 'Apagar',
        send: 'Enviar',
      },
    },
    suggestion_item_a: {
      news: 'Olha essa novidade:\nAcrescente',
      price_label: 'ao seu pacote\npor apenas',
      additional_price_label: 'a mais na sua parcela',
      details: 'Quero saber mais detalhes',
      thanks: 'Não, Obrigado',
    },
    suggestion_item_b: {
      news: 'Vamos aproveitar?',
      price_label: 'por apenas',
      additional_price_label:
        'a mais na sua parcela,\ncom próximo pagamento\ndaqui',
      days: '{{days}} dias',
      details: 'Gostei, quero comprar',
      thanks: 'Não, Obrigado',
    },
    modal: {
      attention: 'Atenção',
      actions: {
        cancel: 'Voltar',
      },
      biometry_request: {
        title: 'Identifique-se para continuar',
        subtitle:
          'Posicione o seu dedo no leitor \n biométrico localizado abaixo da tela.',
        action: 'IDENTIFICAR COM CPF',
        icon_alt: 'Ícone do balcão',
      },
      biometry_error: {
        title: 'Ops, vamos tentar novamente?',
        subtitle: 'Coloque seu dedo cadastrado no leitor \n abaixo da tela.',
        icon_alt: 'Ícone da digital',
      },
      cpf_error: {
        title: 'Infelizmente, ainda não conseguimos :(',
        subtitle:
          'Mas não se preocupe, vá até o \n balcão que vamos te ajudar.',
        icon_alt: 'ìcone do balcão',
      },
      checkin_already_done: {
        title: 'Ops!',
        subtitle: 'Você já realizou o check-in',
        icon_alt: '',
      },
      no_schedules: {
        title: 'Nenhum agendamento encontrado',
        subtitle: 'Você ainda não tem nenhum agendamento conosco :(',
        icon_alt: '',
      },
      no_schedules_unit: {
        title: 'Nenhum agendamento encontrado',
        subtitle: 'Você não possui agendamenos nessa clínica :(',
        icon_alt: '',
      },
      no_schedules_day: {
        title: 'Nenhum agendamento encontrado',
        subtitle: 'Você não possui agendamentos para hoje :(',
        icon_alt: '',
      },
      last_session_validation: {
        not_confirmed_last:
          '{{name}}, você ainda não confirmou \n sua última sessão.',
        start_request: 'Vamos fazer isso agora?',
        date_time: 'DATA/HORÁRIO DA SESSÃO',
        unit: 'CLÍNICA',
        applicator: 'APLICADORA',
        confirm_session: 'confirmar sessão',
        not_confirmed:
          '{{name}}, não foi possível confirmar sua sessão. \n Mas não se preocupe, vá até o balcão que vamos te ajudar',
      },
      unassigned_contract_validation: {
        title: '{{name}}, seu contrato de {{description}} não está assinado.',
        subtitle: 'Assine antes de concluir seu check-in',
        sign_contract: 'assinar contrato',
      },
      schedule_suggestions: {
        title: 'Vamos acelerar este processo?',
        description: 'Abaixo algumas sugestões de horário para você :-)',
        btn_primary: 'Agendar horário selecionado',
        btn_secondary: 'Escolher outro horário',
      },
    },
    badge: {
      offer: 'Oferta Especial',
      today: 'Só Hoje!',
    },
    schedule_regions: {
      duration: 'Duração da sessão:',
      minimun_date: 'Data miníma para agendamento:',
      select: 'Selecionar',
      selected: 'Selecionado',
      schedule_from_day: 'Agendamento a partir do dia',
      schedule_title: 'Olá {{name}}, selecione as regiões que deseja agendar:',
      next_session: 'Próxima sessão',
      absent_exclusive_warning:
        'As suas sessões de {{items}} só podem ser agendadas e realizadas na unidade onde foram adquiridas',
      and: 'e',
    },
    schedule_calendar: {
      avaiable_hours: 'Horários disponíveis',
      periods: {
        day: 'Manhã',
        afternoon: 'Tarde',
        evening: 'Noite',
      },
    },
  },
  pages: {
    home: {
      actions: {
        new_schedule: 'Agendar sessão',
        my_schedules: 'Ver meus agendamentos',
        sign_contract: 'Assinar Contrato',
        cpf_checkin: 'Check-in com CPF',
        buy: 'Compare e compre',
      },
    },
    checkin: {
      success: {
        title: 'Check-in concluído, {{name}}',
        subtitle: 'Estamos muito felizes que você está aqui! :)',
        happyLisa: 'Lisa comemorando',
      },
      error_message: 'Totem indisponível!',
      identification_error: 'CPF não encontrado',
      refer_and_win: 'Indique e Ganhe!',
      access_qr_code_discounts:
        'Acesse o link do QR Code abaixo para fazer indicações e ganhar descontos!',
      procced_with_checkin: 'Prosseguir com o check-in',
      confirm: {
        title: 'Quase lá!',
        description:
          'Para finalizar seu checkin, verifique se seus dados de contato abaixo estão corretos. Caso estejam desatualizados, por favor, atualize!',
        update: 'Atualizar dados',
        confirm: 'Confirmar dados',
      },
      sunExposure: {
        title_one:
          'Você tomou sol em alguma das regiões agendadas no último dia?',
        title_other:
          'Você tomou sol em alguma das regiões agendadas nos últimos {{count}} dias?',
        caption:
          'Se sim, por favor toque para selecionar as áreas em que tomou sol',
        confirm: 'Sim, tomei sol em pelo menos uma região',
        decline: 'Não tomei sol nas regiões agendadas',
      },
      incidences: {
        title: 'Parece que você tem uma ocorrência em aberto',
        description:
          'Selecione abaixo as regiões da sua pele que você considera já estar integra e saúdavel',
        select_all: 'Selecionar tudo',
        unselect_all: 'Desselecionar todos',
        caption:
          '* Se você considera que nenhuma das regiões está integra, não selecione nenhuma opção e apenas clique no botão abaixo.',
        action: 'Responder e realizar meu check-in',
        terms_one:
          'Eu, {{name}}, confirmo que minha pele na área {{areas}} encontra-se completamente íntegra e sem marcas decorrentes da intercorrência do laser',
        terms_other:
          'Eu, {{name}}, confirmo que minha pele nas áreas {{areas}} encontra-se completamente íntegra e sem marcas decorrentes da intercorrência do laser',
      },
      update: {
        title: 'Atualização de dados',
        description:
          'Informe seus dados de contato abaixo e toque em “Atualizar dados” para atualizá-los e realizar checkin',
        email: 'E-mail',
        email_validity: 'Por favor, informe um e-mail válido',
        email_required: 'O e-mail é obrigatório',
        phone: 'Telefone',
        phone_validity: 'Por favor, informe um telefone válido',
        phone_required: 'O telefone é obrigatório',
        biometry:
          'Utilize o leitor biométrico para confirmar sua identidade e concluir o checkin',
        try_gain: 'Tentar novamente',
        biometry_error:
          'Aconteceu um erro na leitura da biometria, por favor tente novamente',
        wrong_fingerprint:
          'Não foi informada uma digital correspondente ao cadastro. Por favor, tente novamente',
        fingerprint_not_registered:
          'A digital informada não está cadastrada. Procure uma atendente para resolver o problema.',
        not_confirmed_warning:
          'A digital informada não corresponde ao cadastro',
      },
      no_biometry: {
        title: 'Não foi possível concluir o checkin',
        description:
          'Identificamos que você não possui biometria cadastrada. Antes de prosseguir, por favor procure uma atendente para resolver o seu cadastro!',
        close: 'Fechar',
      },
    },
    contracts: {
      signing: {
        header: 'Escreva com o dedo a sua assinatura no campo abaixo:',
        actions: {
          clear: 'Limpar assinatura',
        },
      },
      finish: {
        actions: {
          home: 'Tela Inicial',
          sign_another: 'Assinar outro contrato',
          schedule: 'Agendar Sessão',
          checkin: 'Finalizar Check-In',
        },
      },
      detail: {
        actions: {
          terms: 'Termos de contrato',
          terms_services: 'Termos de Serviço',
        },
      },
      toSign: {
        noContracts: '{{name}}, você não possui contratos para assinar',
        header: '{{name}}, aqui estão seus contratos não assinados:',
        contract: 'Contrato: ',
        date: 'Data: ',
        regions: 'Regiões contratadas: ',
        value: 'Valor total: ',
        helpText: 'Clique no contrato que deseja e prossiga para a assinatura.',
        check_contract_terms: 'Li e aceito o contrato e seus termos',
      },
      thanks: {
        title: 'Obrigada, {{name}}! Agora é só agendar sua sessão.',
        contract: 'Contrato: ',
        data: 'Data: ',
        region: 'Regiões contratadas: ',
        valorTotal: 'Valor total: ',
      },
      wizard_header: {
        select_contract: 'Assinar contrato',
        contract_details: 'Detalhes do contrato',
        sign: 'Assinatura',
        end: 'Finalizado!',
      },
    },
    acknowledgment: {
      success: {
        title: 'Agendamento concluído, {{name}}!',
        subtitle: 'esperamos você na sua próxima sessão : )',
        scheduleTo: 'Agendado para: ',
        scheduleRegions: 'Regiões agendadas:',
        session: 'Sessão:',
        clinic: 'CLÍNICA',
        locals: 'LOCAL',
        recommendationText:
          'Cuide da saúde da sua pele. Aplique protetor solar diariamente e a mantenha hidratada a todo momento.',
      },
    },
    schedules: {
      areasToSchedule: 'Áreas para Agendar',
      schedule: 'Agendamento',
      finishSchedule: 'Finalizar Agendamento',
      title: 'Confira todos os seus agendamentos:',
      noAreas: 'Você ainda não tem nenhuma área para agendar :(',
      noSchedules: 'Você ainda não tem nenhum agendamento conosco :(',
      back: 'Voltar',
      newSchedule: 'Agendar Sessão',
      cancel: 'Cancelar',
      finishConfirm: 'Sua sessão foi agendada!',
      firstRecomendation:
        'Evitar exposição solar de 15 dias antes da sessão para áreas muito expostas ao sol e 5 dias antes para áreas menos expostas.',
      gotIt: 'Entendi',
      secondRecomendation:
        'Aparar os pelos com lâmina ou creme depilatório de um a dois dias antes da sessão. É importante que apenas a ponta do pelo esteja exposta.',
      continue: 'Prosseguir',
      card: {
        scheduleDate: 'Agendado para:',
        scheduledAreas: 'Regiões agendadas:',
        session: 'Sessão:',
        unit: 'Clínica',
        location: 'Local',
        reschedule: 'Reagendar',
        cancelAppointment: 'Cancelar Agendamento',
      },
      modal: {
        happyLisa: 'Lisa feliz',
        sadLisa: 'Lisa triste',
        reschedule:
          'Tenho certeza de que vamos encontrar um horário melhor para você! Quer mesmo reagendar?',
        cancelSchedule: 'Deseja realmente cancelar seu agendamento? :(',
      },
    },
    qrcodeCheckin: {
      title: 'Realizar meu check-in',
      subtitle: 'Unidade {{unit}}',
      display: 'Digite seu CPF',
      checkin: 'Check-In',
      help: 'Caso tenha alguma dúvida, procure nossa ajuda no balcão!',
      success: '{{name}}, seu check-in foi realizado com sucesso!',
      accessDenied: 'Você não tem acesso a está página!',
      finished: 'Processo de check-in finalizado!',
      canceled: 'Processo de check-in cancelado!',
      error: 'Algo deu errado ao tentar realizar o check-in. Tente novamente!',
      alreadyMadeCheckin: 'Você já realizou seu check-in!',
      hasContracts: 'Você possui contratos para assinar!',
    },
  },
};
