import React from 'react';

import { Duration, Moment } from 'moment';

import { useLocale } from 'shared/presentation/contexts';

import * as S from './styles';

export interface IRegionCardData {
  id: number;
  image: string;
  description: string;
  nextSession: string;
  sessionDuration: Duration;
  nextSchedule: Moment;
}

interface IRegionCardProps {
  schedule: IRegionCardData;
}

const RegionsSchedule: React.FC<IRegionCardProps> = ({ schedule }) => {
  const { t } = useLocale('translations');

  return (
    <S.Area>
      <img src={schedule.image} alt={schedule.description} />

      <S.AreaSession>
        <h2>{schedule.description}</h2>

        <strong>
          {t('pages.schedules.card.session') + ' ' + schedule.nextSession}
        </strong>
      </S.AreaSession>
    </S.Area>
  );
};

export default RegionsSchedule;
