import React, { useState } from 'react';

import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Carousel from 'nuka-carousel';

import { CarouselButton, CarouselWrapper, Container } from './styles';

type TMultiCarouselProps = {
  slidesToShow?: number;
  initialSlideHeight?: number;
};

const MultiCarousel: React.FC<TMultiCarouselProps> = ({
  children,
  slidesToShow = 5,
  initialSlideHeight = 380,
}) => {
  const [slideIndex, setSlideIndex] = useState<number>(0);

  const maxCarouselSlideIndex =
    React.Children.toArray(children).length - slidesToShow;

  const nextSlide = () =>
    setSlideIndex(i => (i < maxCarouselSlideIndex ? i + 1 : i));
  const previousSlide = () => setSlideIndex(i => (i > 0 ? i - 1 : i));

  return (
    <Container>
      <CarouselButton onClick={previousSlide}>
        <ArrowLeftIcon />
      </CarouselButton>
      <CarouselWrapper>
        <Carousel
          slideIndex={slideIndex}
          slidesToShow={slidesToShow}
          renderBottomCenterControls={null}
          renderCenterLeftControls={null}
          renderCenterRightControls={null}
          initialSlideHeight={initialSlideHeight}
        >
          {children}
        </Carousel>
      </CarouselWrapper>
      <CarouselButton onClick={nextSlide}>
        <ArrowRightIcon />
      </CarouselButton>
    </Container>
  );
};

export default MultiCarousel;
