export default {
  termInformation:
    'Información fundamental: (I) Se necesitan, en media, hasta 10 (diez) sesiones para obtener un resultado satisfactorio; (II) El número de sesiones puede variar de persona a persona; (III) El trastorno hormonal, el color de la piel, el color del cabello, el grosor del cabello, pueden influenciar en el número de sesiones, así como en la eliminación parcial o completa del cabello de la región contratada.',
  termParagraph1:
    '01. El OBJETO de este contrato es la prestación de servicios de depilación láser por Vialaser directamente al Contratista.',
  termParagraph2:
    '02. El Contratista se compromete a: (I) Programar las sesiones con anticipación; (II) Asistir a las sesiones a la hora programada; (III) Respetar el intervalo entre sesiones; (IV) Pagar el precio en tiempo y en las condiciones pactadas.',
  termParagraph3:
    '03. Vialaser se compromete a prestar o contratar servicios para el Contratista con profesionalismo, dedicación y respeto.',
  termParagraph4:
    '04. Vialaser se compromete a realizar todas las sesiones contratadas por el Contratista.',
  termParagraph5:
    '05. La depilación láser se realizará en sesiones periódicas, con intervalos definidos por el profesional responsable. El intervalo entre sesiones varía según el crecimiento del cabello y la zona a tratar.',
  termParagraph6:
    '06. Las sesiones de depilación láser deben ser programadas por el Contratista, en persona, por correo electrónico o por teléfono, de acuerdo con la disponibilidad del horario de Vialaser.',
  termParagraph7:
    '07. Si el Contratista no puede asistir a alguna de las sesiones programadas, debe notificar a Vialaser con al menos 24 horas de anticipación.',
  termParagraph8:
    '08. La ausencia injustificada del Contratista a cualquiera de las sesiones se contabilizará como parte del tratamiento contratado. En este caso, las sesiones solo se pueden restablecer mediante un pago separado, de acuerdo con la lista de precios en el momento del reemplazo.',
  termParagraph9:
    '09. La Parte Contratante se compromete a pagar a Vialaser la totalidad del precio ajustado, en las condiciones exactas pactadas en este instrumento. En caso de retraso o incumplimiento por parte del Contratista, Vialaser se reserva el derecho de no notificar al Contratista hasta que se liquiden los pagos atrasados.',
  termParagraph10:
    '10. El Contratista autoriza a Vialaser a realizar el tratamiento estético de depilación láser aquí contratado.',
  termParagraph11:
    '11. Este contrato es personal e intransferible, o sea, el tratamiento aquí contratado no puede ser utilizado por otra persona.',
  termParagraph12:
    '12. En caso de desistimiento por iniciativa del Contratista, el Contratista se compromete a solicitar la cancelación por escrito. Una vez recibida la solicitud de cancelación por escrito, Vialaser reembolsará el saldo dentro de los 30 (treinta) días al Contratista. El Contratista no tendrá derecho a reembolso de los montos gastados en sesiones ya realizadas. El Contratista perderá el derecho al descuento obtenido si ha comprado un paquete promocional. Vialaser se compromete a reembolsar a la Parte Contratante únicamente la cantidad equivalente al 70% (setenta por ciento) del saldo del valor del tratamiento contratado y no utilizado. Este porcentaje se justifica por los costos de gastos administrativos, impuestos, comisiones, etc.',
  termParagraph13:
    '13. El Contratista se declara consciente de la INFORMACIÓN y ADVERTENCIAS que se describen a continuación: (I) Durante el período entre sesiones, no está permitido realizar procedimientos que arranquen el cabello de raíz, así como decoloración de los mismos. ; (II) Después de la aplicación del láser, puede ocurrir lo siguiente: ardor e irritación de la piel por algunas horas, presentando un color más rosado en la piel; (III) En caso de uso de cualquier tipo de ácido en la región a afeitar, es necesario interrumpir el uso del producto 05 (cinco) días antes y después de cada sesión; (IV) Se debe evitar el bronceado durante todo el tratamiento, ya sea utilizando el sol, las camas solares o los autobronceadores; (V) Si el bronceado es inevitable, el Contratista deberá respetar un período de 3 (tres) días después de la aplicación del láser para broncear, de la misma manera que después del bronceado, deberá esperar un período mínimo de 30 (treinta) días para hacer una nueva aplicación de láser; (VI) Al exponerse al sol, es imprescindible utilizar protector solar o bloqueador solar, con SPF 30 o más, durante todo el tratamiento; (VII) La aparición de costra hipercrómica (“cascarita” oscura) aunque rara, puede ocurrir, principalmente en pieles pigmentadas naturalmente o recién bronceadas; (VIII) Esta costra tiende a desaparecer sin dejar imperfecciones ni cicatrices, con el tiempo; (IV) También pueden aparecer manchas, quemaduras, alergias o ampollas temporales, a pesar de ser raras, que tienden a desaparecer con tratamientos o cuidados con la exposición solar; (X) El láser no debe aplicarse en caso de embarazo, siendo el Contratista el responsable de informar a Vialaser de esta situación; (XI) Después de la aplicación del láser, siga siempre las recomendaciones de Vialaser.',
  termParagraph14:
    '14. El Contratista está obligado a informar a Vialaser sobre el uso de cualquier medicamento, antes del inicio del tratamiento o durante el mismo. Existen medicamentos que pueden interferir con el resultado del tratamiento de depilación láser.',
  termParagraph15:
    '15. En caso de demora en el pago (s), la deuda puede ser presentada para protesta y el nombre del deudor registrado en las agencias de protección crediticia.',
  termParagraph16:
    '16. Las partes reconocen como válidas y aceptan que las comunicaciones entre las partes se realizarán por correo electrónico (informado en el registro del cliente), así como considerar legales y completos los documentos electrónicos producidos por las partes, siendo válidos con la fijación de la huella digital del cliente (biometría), en los contratos, modificaciones y hojas de trámite y asistencia, de conformidad con el art. 10, párrafo 2, de MP 2002-2 / 2001.',
  termParagraph17:
    '17. La Parte Contratante REPRESENTA EXPRESAMENTE QUE HA LEÍDO Y COMPRENDIDO TODAS LAS DISPOSICIONES DE ESTE ACUERDO, incluyendo advertencias, informaciones, precauciones especiales y, sobre todo, declara expresamente que he sido advertido de todas las precauciones que pudiera tener antes, durante y después del tratamiento. En caso de incumplimiento de la informaciones, advertencias y precauciones especiales, Vialaser no se hace responsable de los daños o pérdidas causado por el Contratista.',
  termParagraph18:
    '18. Art. 49 (CDC) - El consumidor podrá desistir del contrato, dentro de los 07 (siete) días desde su firma o desde el acto de recepción del producto o servicio, siempre que la contratación de suministro de productos y servicios tenga lugar fuera de el establecimiento comercial, especialmente por teléfono o en casa.',
};
