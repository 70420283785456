import React from 'react';

import {
  useActiveCustomer,
  useLocale,
  useUnit,
} from 'shared/presentation/contexts';

import * as S from './styles';
import SuccessCard from './SuccessCard';
import happyLisaVideo from '../../../assets/videos/happyLisa.webm';

const ScheduleFinalize: React.FC = () => {
  const { customer } = useActiveCustomer();
  const { unit } = useUnit();
  const { t } = useLocale('translations');

  return (
    <S.Container>
      <S.Content>
        <S.Header>
          <h1 className="title">
            {t('pages.acknowledgment.success.title', {
              name: customer?.fullName,
            })}
          </h1>

          <h2 className="subtitle">
            {t('pages.acknowledgment.success.subtitle')}
          </h2>
        </S.Header>

        <SuccessCard
          schedule={{
            unit: unit?.name || '',
            location: `${unit?.address.city}-${unit?.address.district}`,
            date: '',
            areas: [],
            hour: '',
            id: 0,
          }}
        />

        <S.Recomendation>
          <span className="recomendation-text">
            {t('pages.acknowledgment.success.recommendationText')}
          </span>
        </S.Recomendation>
      </S.Content>

      <S.Video>
        <video src={happyLisaVideo} width="450" autoPlay loop muted />
      </S.Video>
    </S.Container>
  );
};

export default ScheduleFinalize;
