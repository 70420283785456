import React, { useCallback } from 'react';

import happyLisa from 'assets/videos/happyLisa.webm';
import { Button } from 'shared/presentation/components/molecules';
import { Trans, useLocale, useNavigation } from 'shared/presentation/contexts';
import { useTimeout } from 'shared/presentation/hooks';

import * as S from './styles';

const TIMEOUT = 30;

const SunExposure: React.FC = () => {
  const navigation = useNavigation();
  const { t } = useLocale('checkin');

  useTimeout({
    timeout: TIMEOUT,
    onTimeUp: useCallback(() => navigation.push('/'), [navigation]),
  });

  return (
    <S.Container>
      {/* <img src={happyLisa} alt="Lisa feliz" /> */}
      <video src={happyLisa} autoPlay loop muted />

      <section>
        <div className="texts">
          <h1>{t('sunExposure.title')}</h1>

          <div>
            <p>{t('sunExposure.texts.thanks')}</p>

            <p>
              <Trans t={t} localeKey="sunExposure.texts.exposure">
                exposure
                <strong>exposure</strong>
                exposure
                <strong>exposure</strong>
                exposure
              </Trans>
            </p>

            <p>{t('sunExposure.texts.info')}</p>
          </div>
        </div>

        <Button variant="primary" onClick={() => navigation.push('/')}>
          {t('sunExposure.button')}
        </Button>
      </section>
    </S.Container>
  );
};

export default SunExposure;
