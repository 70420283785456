import SignContracts from 'modules/contracts/presentation/pages/SignContracts';
import type { TRoute } from 'shared/presentation/modules/definitions';

const contractsRoutes: TRoute[] = [
  {
    type: 'route',
    path: '/sign-contracts',
    component: SignContracts,
  },
];

export default contractsRoutes;
