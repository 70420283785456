import { ButtonBase, styled, Theme } from '@material-ui/core';

type TButtonType = {
  theme: Theme;
  color?: string;
  background?: string;
  bordercolor?: string;
  icon?: React.ReactNode;
  border?: string;
  disabled?: boolean;
};

type TIconType = {
  theme: Theme;
  background?: string;
  iconBackground?: string;
};

export const Button = styled('button')(
  ({ theme, color, background, bordercolor, disabled }: TButtonType) => ({
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '24px',
    fontWeight: theme.font.bold,
    color: disabled ? theme.colors.gray : color || theme.colors.primary,
    background: disabled
      ? theme.colors.lightGray
      : background || theme.colors.white,
    border: `1px solid ${
      disabled ? theme.colors.gray : bordercolor || 'transparent'
    }`,
    borderRadius: 35,
    cursor: 'pointer',
    outline: 'none',
    textTransform: 'uppercase',
    '&:disabled': {
      filter: 'opacity(.5) brightness(110%)',
    },
  }),
);

export const ButtonSchedule = styled(ButtonBase)(
  ({ theme, color, background, icon, border }: TButtonType) => ({
    width: '100%',
    display: 'grid',
    alignItems: 'center',
    margin: '5px',
    gridTemplateColumns: icon ? '2rem calc(100% - 4rem) 2rem' : 'auto',
    padding: icon ? '18px 24px' : '18px 24px',
    fontWeight: theme.font.bold,
    color: color || theme.colors.gray,
    background: background || theme.colors.lightGray,
    border: border || `1px solid ${theme.colors.success}`,
    borderRadius: '50%',
    cursor: 'pointer',
    outline: 'none',
    textTransform: 'uppercase',
    '&:disabled': {
      filter: 'brightness(110%)',
    },
  }),
);

export const Icon = styled('div')(
  ({ background, theme, iconBackground }: TIconType) => ({
    position: 'absolute',
    left: '1.2rem',
    width: '2rem',
    height: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 5,
    borderRadius: 15,
    background:
      iconBackground || (background ? 'transparent' : theme.colors.primary),
    '& img': {
      width: '100%',
    },
  }),
);

export const ButtonContent = styled('div')(() => ({
  display: 'inline-grid',
  fontSize: 18,
}));

export const ButtonContentSchedule = styled('div')({
  display: 'inline-grid',
  maxWidth: '250px',
  minWidth: '250px',
});
