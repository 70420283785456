import React from 'react';

import { UserIdentification } from 'shared/presentation/components/templates';
import { Redirect, useActiveCustomer } from 'shared/presentation/contexts';

const SignContracts: React.FC = () => {
  const { customer } = useActiveCustomer();

  if (!customer) {
    return <UserIdentification />;
  }

  return <Redirect to="/contracts" params={{}} />;
};

export default SignContracts;
