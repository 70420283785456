import moment from 'moment';

export const HOUR_12 = moment('12:00', 'hh:mm');
export const HOUR_19 = moment('19:00', 'hh:mm');

export const DAY_PERIOD_VALUES: {
  [key: string]: { from: number; to: number };
} = {
  morning: {
    from: 0,
    to: 12,
  },
  afternoon: {
    from: 12,
    to: 19,
  },
  night: {
    from: 19,
    to: 0,
  },
};
