import checkinModule from 'modules/checkin/presentation/checkin.module';
import contractsModule from 'modules/contracts/presentation/contracts.module';
import schedulesModule from 'modules/schedules/presentation/schedules.module';
import sharedModule from 'shared/presentation/shared.module';

import { ModuleAggregator } from './aggregator';

type TModuleName = 'shared' | 'checkin' | 'contracts' | 'schedules';

const appModules = new ModuleAggregator<TModuleName>()
  .use('shared', sharedModule)
  .use('checkin', checkinModule)
  .use('contracts', contractsModule)
  .use('schedules', schedulesModule)
  .build();

export default appModules;
