import { createContext, useContext } from 'react';

import { isEmpty } from 'lodash';

import { IUnit } from 'shared/domain/entities';

interface IUnitContextData {
  unit: IUnit | null;
}

export const UnitContext = createContext<IUnitContextData>(
  {} as IUnitContextData,
);

export const useUnit = () => {
  const context = useContext(UnitContext);

  if (isEmpty(context)) {
    throw new Error('useUnit should be called within a UnitContext');
  }

  return context;
};
